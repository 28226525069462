import React, { useState, useEffect, useRef } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import ButtonStyled from "./ButtonStyled";
import Switch from "react-switch";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import "./ModalFilter.css";

const ModalFilter = ({
  openModal,
  modalActions,
  closeModal,
  info,
  loading,
}) => {
  const menuRef = useRef(null);
  const [formInputs, setFormInputs] = useState({});
  const [formCheckbox, setFormCheckbox] = useState({});
  const [change, setChange] = useState(false);
  const [selectInputFalseValues, setSelectInputFalseValues] = useState([])
  const colourStyles = {
    input: () => ({ color: "white", padding: "0" }),
  };

  useEffect(() => {
    setFormInputs({});
    let newFormInputs = {};
    let newFormCheckbox = {};
    info?.parameters?.map((element) => {
      element?.inputs?.map((input) => {
        if (input.checkbox) {
          newFormCheckbox = {
            ...newFormCheckbox,
            [input.checkboxName]: input.checkboxValue,
          };
        }
        if (input.input) {
          newFormInputs = {
            ...newFormInputs,
            [input.key]: input.inputValue,
          };
        }
      });
    });

    setFormInputs(newFormInputs);
    setFormCheckbox(newFormCheckbox);
  }, []);

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const selectFalseValue = (input) => {
    const arrayInputWithFalseValues = ["select", "multiSelect", "selectAutoComplete", "selectAutoCompleteSearch", "text", "date"]
    const arrayWithFalseValues = [false, null, undefined, ""]

    const isInputFalseValue = arrayInputWithFalseValues.includes(input.input)
    const isFalseValue = arrayWithFalseValues.includes(formInputs[input.key])
    const isArryFalse = Array.isArray(formInputs[input.key]) && !formInputs[input.key].length ? true : false
    const isObjectFalse = typeof formInputs[input.key] === "object" && !Object.keys(formInputs[input.key]).length ? true : false
    const inputNotFilled = isInputFalseValue && (isFalseValue || isArryFalse || isObjectFalse)

    if (inputNotFilled) return true

    return false
  }

  const handleChangeInput = (e, input, selectComponent, isSwitch) => {
    setFormInputs({
      ...formInputs,
      [input.key]: isSwitch ? !formInputs[input.key] : selectComponent ? e : e.target.value,
    });
    if (selectInputFalseValues.includes(input.key)) {
      setSelectInputFalseValues((prev) => prev.filter((el) => el !== input.key))
    }
    setChange(true);
  }

  const saveFunction = () => {
    if (change) {
      setSelectInputFalseValues([])
      let arraykeyMissingValues = []

      info?.parameters.forEach((element) => {
        element?.inputs?.forEach((input) => {
          if (formCheckbox[input.checkboxName] && selectFalseValue(input)) {
            arraykeyMissingValues.push(input.key)
          }
        })
      })

      if (arraykeyMissingValues.length) {
        setSelectInputFalseValues(arraykeyMissingValues)
      } else {
        let newFormCheckbox = {};
        let formLabels = {};
        info?.parameters?.map((element) => {
          element?.inputs?.map((input) => {
            if (typeof input.checkbox !== "undefined") {
              if (formCheckbox[input.checkboxName]) {
                newFormCheckbox[input.key] = formInputs[input.key];
                formLabels[input.label] = formInputs[input.key];
              }
            } else {
              newFormCheckbox[input.key] = formInputs[input.key];
              formLabels[input.label] = formInputs[input.key];
            }
          });
        });
        info.onSaveFunction(newFormCheckbox, formLabels);
      }
    }
  };

  return (
    <Modal className="fade" show={openModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>Filters</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => closeModal()}
        ></Button>
      </Modal.Header>
      <Modal.Body>
      {info?.parameters?.map((element) => (
              <div key={element.title}>
                <p style={{ marginBottom: "0" }}>{element.title}</p>
                {element?.inputs?.map((input, pos) => (
                  <div key={pos}>
                    {input.input === "checkbox" &&
                      typeof checkbox === "undefined" ? (
                      <Row key={input.label} style={{ marginBottom: "1rem" }}>
                        <Col xs={7}>
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            <input
                              checked={formInputs[input.key]}
                              name={input.key}
                              onChange={(e) => {
                                setFormInputs({
                                  ...formInputs,
                                  [e.target.name]: !formInputs[e.target.name],
                                });
                                setChange(true);
                              }}
                              type="checkbox"
                              style={{ marginRight: "5px" }}
                            />
                            {input.label}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row key={input.label} style={{ marginBottom: "1rem" }}>
                        <Col xs={7}>
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            {input.checkbox && (
                              <input
                                checked={formCheckbox[input.checkboxName]}
                                name={input.checkboxName}
                                onChange={(e) => {
                                  setFormCheckbox({
                                    ...formCheckbox,
                                    [e.target.name]:
                                      !formCheckbox[e.target.name],
                                  });
                                  if (selectInputFalseValues.includes(input.key)) {
                                    setSelectInputFalseValues((prev) => prev.filter((el) => el !== input.key))
                                  }
                                  setChange(true);
                                }}
                                type="checkbox"
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            {input.label}
                          </div>
                        </Col>
                        {input.input && (
                          <Col xs={5}>
                            {input.input === "switch" && (
                              <Switch
                                uncheckedIcon={<Offsymbol />}
                                className="mr-1"
                                checkedIcon={<OnSymbol />}
                                onColor="#626ed4"
                                onChange={() => {
                                  setFormInputs({
                                    ...formInputs,
                                    [input.key]: !formInputs[input.key],
                                  });
                                  setChange(true);
                                }}
                                checked={formInputs[input.key]}
                              />
                            )}
                            {input.input === "select" && (
                              <div className="d-flex flex-column">
                                <select
                                  className="arrow-select"
                                  value={formInputs[input.key] || ""}
                                  name={input.key}
                                  onChange={(e) => handleChangeInput(e, input)}
                                >
                                  <option value="" disabled>
                                    Select Option
                                  </option>
                                  {input?.options?.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {selectInputFalseValues.includes(input.key) && (
                                  <p className="text-danger">fill the input or disable the checkbox</p>
                                )}
                              </div>
                            )}

                            {input.input === "multiSelect" && (
                              <div className="d-flex flex-column">
                                <Select
                                  placeholder="Select Option(s)"
                                  isMulti
                                  name="multi_select"
                                  options={input.options}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(e) => handleChangeInput(e, input, true)}
                                  defaultValue={input.inputValue}
                                  styles={colourStyles}
                                />
                                {selectInputFalseValues.includes(input.key) && (
                                  <p className="text-danger">fill the input or disable the checkbox</p>
                                )}
                              </div>
                            )}

                            {input.input === "selectAutoComplete" && (
                              <div className="d-flex flex-column">
                                <Select
                                  placeholder="Select Option"
                                  options={input.options}
                                  className="basic-single"
                                  classNamePrefix="my-select"
                                  onChange={(e) => handleChangeInput(e, input, true)}
                                  defaultValue={input.inputValue}
                                  styles={colourStyles}
                                />
                                {selectInputFalseValues.includes(input.key) && (
                                  <p className="text-danger">fill the input or disable the checkbox</p>
                                )}
                              </div>
                            )}
                            
                            {input.input === "asyncSelect" && (
                              <div className="d-flex flex-column">
                                <AsyncSelect 
                                  onChange={(e) => handleChangeInput(e, input, true)}
                                  className="basic-single"
                                  classNamePrefix="my-select"
                                  placeholder={input?.placeholder || "Search Option"}
                                  value={formInputs[input.key]}
                                  name={input.key}
                                  loadOptions={(inputValue, callback) => input.loadOptions(inputValue, callback)}
                                  isSearchable={true}
                                  defaultOptions
                                  cacheOptions
                                />
                                {selectInputFalseValues.includes(input.key) && (
                                  <p className="text-danger">fill the input or disable the checkbox</p>
                                )}
                              </div>
                            )}

                            {input.input === "selectAutoCompleteSearch" && (
                              <div className="d-flex flex-column">
                                <Select
                                  placeholder="Search Option"
                                  options={input.options}
                                  className="basic-single"
                                  classNamePrefix="my-select"
                                  onInputChange={(e)=>{
                                    input?.setOptions(e)
                                  }}
                                  onChange={(e) => handleChangeInput(e, input, true)}
                                  defaultValue={input.inputValue}
                                  styles={colourStyles}
                                />
                                {selectInputFalseValues.includes(input.key) && (
                                  <p className="text-danger">fill the input or disable the checkbox</p>
                                )}
                              </div>
                            )}

                            {input.input !== "switch" &&
                              input.input !== "select" &&
                              input.input !== "multiSelect" &&
                              input.input !== "selectAutoComplete" && 
                              input.input !== "asyncSelect" && 
                              input.input !== "selectAutoCompleteSearch" && (
                                <div className="d-flex flex-column">
                                  <input
                                    type={input.input}
                                    name={input.key}
                                    onChange={(e) => handleChangeInput(e, input)}
                                    defaultValue={formInputs[input.key] || ""}
                                    className={
                                      input.input === "date" ||
                                        input.input === "text"
                                        ? "form-control"
                                        : ""
                                    }
                                  />
                                  {selectInputFalseValues.includes(input.key) && (
                                    <p className="text-danger">fill the input or disable the checkbox</p>
                                  )}
                                </div>
                              )}
                          </Col>
                        )}
                      </Row>
                    )}
                  </div>
                ))}
              </div>
            ))}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Save"
          onClickFunction={() => saveFunction()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalFilter;
