import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Label, Table } from "reactstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonHover from "../../../components/utils/ButtonHover";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
const ModalAddState = ({
  openModal,
  modalActions,
  closeModal,
  info,
  loading,
  list,
  setList,
  listUsers,
  form,
  setForm,
}) => {
  const [multiSelectUsers, setMultiSelectUsers] = useState([]);

  const [formUser, setFormUser] = useState({
    penalty: "0",
    wrapuptime: "1",
    paused: false,
    ringinuse: false,
  });
  const [listMembers, setListMembers] = useState(list);
  const [add, setAdd] = useState(false);

  const onClickDelete = (value) => {
    const newList = list.filter((element) => element.value !== value);
    console.log(newList);
    console.log(value);

    setList(newList);
  };

  const onClickEdit = (id) => {
    const newFindMember = list.find((element) => element.user_id === id);
    if (newFindMember) {
      setFormUser(newFindMember);
      setAdd("edit");
    }
  };

  const fuzzySearch = (options) => {
    return (value) => {
      if (!value.length) {
        return options;
      }

      let newArrayOptions = options.filter((element) =>
        element.value.includes(value)
      );

      return newArrayOptions;
    };
  };

  const getMemberMultiSelect = () => {
    const MultiSelectUsers = [];

    listUsers.map((element) => {
      let findUser = list.find(
        (elementTwo) => elementTwo.value === element.value
      );
      if (!findUser)
        MultiSelectUsers.push({
          name: `${element.name}`,
          value: element.value,
        });
    });

    setMultiSelectUsers(MultiSelectUsers);
  };

  useEffect(() => {
    let newList = [];
    list.map((element) => {
      const user = listUsers.find(
        (elementTwo) => elementTwo.value === element.value
      );
      if (user) {
        newList.push({
          name: `${user.name}`,
          value: user.value,
        });
      }
    });
    getMemberMultiSelect();

    return setListMembers(newList);
  }, [list, listUsers]);

  const handleSubmit = (e) => {
    /*  if (add === "edit") {
         let newListMembers = []
         list.map(element => element.pk === formUser.pk ? newListMembers.push(formUser) : newListMembers.push(element))
         setData(false)
         return setList(newListMembers)
       } */

    const newMembers = [];
    const exitsUser = list.find(
      (elementTwo) => elementTwo.value === form.value
    );

    const exitsUserInfo = listUsers.find(
      (elementTwo) => elementTwo.value === form.value
    );

    if (!exitsUser)
      newMembers.push({
        value: form.value,
        name: exitsUserInfo.name,
      });
    setList([...list, ...newMembers]);
    setForm([]);
    setAdd(false);
  };

  const handleChangeCheck = (e) => {
    setForm(e);
  };

  const onChange = (e) => {
    setFormUser({ ...formUser, [e.target.name]: e.target.value });
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {add ? (
            <>
              {add !== "edit" && (
                <div className="col-12  mb-3">
                  <Label
                    htmlFor="wrapuptime"
                    className="custom-pointer m-0  col-12 p-0"
                  >
                    {" "}
                    State:{" "}
                  </Label>
                  <div className="col-12 col-lg-6 p-0">
                    <SelectSearch
                      placeholder="Start Typing..."
                      search={true}
                      onChange={(e) => {
                        setForm({ value: e });
                      }}
                      options={multiSelectUsers}
                      // className="form-control"
                      // filterOptions={fuzzySearch}
                      value={form.value}
                      name="language"
                    />

                    {/*  <Multiselect
                options={multiSelectUsers}
                onSelect={handleChangeCheck}
                onRemove={handleChangeCheck}
                displayValue="name"
                placeholder="Start Typing..."

              /> */}
                  </div>
                </div>
              )}

              {/*  <div className="col-12 ">
            <Label htmlFor="penalty" className="custom-pointer m-0 mt-4 p-0" > Penalty: </Label>
            <select
              value={formUser.penalty}
              onChange={onChange}
              id="penalty"
              type="number"
              name="penalty"
              className="form-control col-12 col-lg-6"
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
          <div className="col-12 ">
            <Label htmlFor="wrapuptime" className="custom-pointer m-0 mt-4 p-0" > Wrap Up Time: </Label>
            <input
              value={formUser.wrapuptime}
              onChange={onChange}
              id="wrapuptime"
              type="number"
              name="wrapuptime"
              className="form-control col-12 col-lg-6"
            />
          </div>
          <div className="col-12 mt-2 mt-lg-0">
            <Label htmlFor="wrapuptime" className="custom-pointer m-0 mt-4 col-12 p-0" > Ring in Use: </Label>
            <Switch
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() => {
                setFormUser({ ...formUser, ringinuse: !formUser.ringinuse });
              }}
              checked={formUser.ringinuse}
            />
          </div>
          <div className="col-12 mt-2 mt-lg-0">
            <Label htmlFor="wrapuptime" className="custom-pointer m-0 mt-4 col-12 p-0" > Paused: </Label>
            <Switch
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() => {
                setFormUser({ ...formUser, paused: !formUser.paused });
              }}
              checked={formUser.paused}
            />
          </div>
 */}
              <div className="col-12 text-right">
                <ButtonStyled
                  text={"Save"}
                  clasName={"ml-2"}
                  onClickFunction={handleSubmit}
                  style={{ fontWeight: "500", fontSize: "16px" }}
                />
              </div>
            </>
          ) : (
            <Row>
              <Col>
                <ButtonStyled
                  text={"Add State"}
                  clasName={"mb-3"}
                  style={{ fontWeight: "500", fontSize: "16px" }}
                  onClickFunction={() => {
                    setAdd(true);
                    setFormUser({
                      penalty: "0",
                      wrapuptime: "1",
                      paused: false,
                      ringinuse: false,
                    });
                  }}
                />
              </Col>
            </Row>
          )}

          <Col className="d-flex col-12">
            {listMembers && listMembers.length !== 0 && (
              <Table responsive>
                <thead className="thead-light">
                  <tr>
                    <th>State</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listMembers.map((element, i) => {
                    return (
                      <tr key={i}>
                        <th>{element.name}</th>
                        <th className="d-flex">
                          <ButtonHover
                            style={{
                              margin: "0",
                              marginTop: "0.5rem",
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              onClickDelete(element.value);
                            }}
                          >
                            <DeleteIcon />
                          </ButtonHover>
                          {/*  <ButtonHover
                        style={{
                          margin: "0",
                          marginTop: "0.5rem",
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          onClickEdit(element.id);
                        }}
                      >

                        <EditIcon />
                      </ButtonHover> */}
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          loading={loading}
          text={"Save"}
          onClickFunction={() => modalActions()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddState;
