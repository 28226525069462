import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import DownloadIcon from "@mui/icons-material/Download";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalFilter from "../../jsx/utils/ModalFilter";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import getValuesInObject from "../../jsx/utils/getValuesInObject";

const PayoutHistory = () => {
  const agentId = JSON.parse(localStorage.getItem("userDetails")).agent_id;
  const filterRef = useRef();
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [allResult, setAllResult] = useState(null);

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form, formLabels) => {
    let filtersActive = getValuesInObject(formLabels);
    setLoadingFilter(true);
    setFirstLoad(false);

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter = getQueryString(paginationTemp) + filterModal + `&agent_id=${agentId}`;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: [
        {
          title: "Date",
          inputs: [
            {
              label: "Agency Created On or After",
              input: "date",
              inputValue: form.added_after || "",
              checkbox: true,
              checkboxValue: form.added_after ? true : false,
              checkboxName: "checkbox_after",
              key: "added_after",
            },
            {
              label: "Agency Created On or Before",
              input: "date",
              inputValue: form.added_before || "",
              checkbox: true,
              checkboxValue: form.added_before ? true : false,
              checkboxName: "checkbox_before",
              key: "added_before",
            },
          ],
        },
      ],
    });

    petition(parameter, false, false, filtersActive);

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = filterModal;
  };

  const activeDownloadReport = (e) => {
    let payout_id = e.pk;
    petitionGet("payoutPdfDownloadAgent", { payout_id })
      .then(({ data: result }) => {
        let link = document.createElement("a");
        link.download = `${e.agentName}.pdf`;
        link.href = `data:application/pdf;base64,${result.pdf}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const petition = (
    parameter,
    noLoading,
    paginationTemp,
    filtersToolip
  ) => {
    if (!noLoading) setLoading(true);
    petitionGet("payouts-agent", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.date = element.payout_date
            .substring(0, 10)
            .replaceAll("-", "/");

          element.agentName =
            element.agent.first_name + " " + element.agent.last_name;
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "AGENT", key: "agentName" },
            { title: "AMOUNT", key: "amount" },
            { title: "PAYOUT DATE", key: "date" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Download Report",
                icon: <DownloadIcon />,
                handleClick: activeDownloadReport,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
        });
        setLoading(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + (filterRef.current || "") + `&agent_id=${agentId}`;
    petition(parameter, true, paginationTemp, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp) + (filterRef.current || "") + `&agent_id=${agentId}`;
    petition(parameter, false, paginationTemp, objectFilter.current);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  useEffect(() => {
    if (firstLoad) {
      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => onHidde(),
        parameters: [
          {
            title: "Date",
            inputs: [
              {
                label: "Agency Created On or After",
                input: "date",
                inputValue: "",
                checkbox: true,
                checkboxValue: false,
                checkboxName: "checkbox_after",
                key: "added_after",
              },
              {
                label: "Agency Created On or Before",
                input: "date",
                inputValue: "",
                checkbox: true,
                checkboxValue: false,
                checkboxName: "checkbox_before",
                key: "added_before",
              },
            ],
          },
        ],
      });
    }
  }, [firstLoad]);

  useEffect(() => {
    petition(
      getQueryString(pagination.current) + `&agent_id=${agentId}`,
      false,
      pagination.current,
      false
    );
  }, []);

  return (
    <>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          data={dataTable}
          noClass={true}
          allResult={allResult}
          pagination={itemsPerPage.current}
        />
      )}
    </>
  );
}

export default PayoutHistory
