import React from "react";
import { Modal, Table } from "react-bootstrap";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const AddMAppings = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
}) => {
  const update = () => {
    modalActions(form);
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>
          {form.pk ? "Edit Mappings" : "Create Mappings"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>FIELD NAME</th>
              <th>CSV FIELD NAME</th>
            </tr>
          </thead>
          <tbody>
            {}
            <tr>
              <td>7</td>
              <td>test</td>
              <td>
                {" "}
                <input
                  className="form-control"
                //   onChange={(e) => {
                //     setForm({ ...form, name: e.target.value });
                //   }}
                //   value={form.name}
                placeholder="CSV Name Field"
                ></input>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={!form.name}
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddMAppings;
