// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go2791332690{
    color: #dc3545 !important;
    font-size: 18px
}

.step-form-horizontal ol .go3842760039 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.step-form-horizontal ol .go433304200 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.go433304200:before {
    background:#dc3545 !important ;
    width: 100% !important;
}

.buttonWizzard{
    background: #ccc !important;
    border-width: 0px !important;
    color: #fff !important;
    font-size: 0.875rem !important;
    padding: 0.625rem 1rem !important;
    margin-right: 5px !important;
}

.buttonWizzard:active{
    background: #ababab !important;
}

.react-select__menu {
    z-index: 9999;
}

.react-select__menu {
    position: fixed; /* O position: absolute; */
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
}

.react-select__menu {
    max-height: 900px; /* Ajusta el tamaño según tus necesidades */
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/pages/ProccessPayout/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,gCAAgC;IAChC,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;IAChC,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;IACtB,8BAA8B;IAC9B,iCAAiC;IACjC,4BAA4B;AAChC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe,EAAE,0BAA0B;IAC3C,oBAAoB;IACpB,qBAAqB;IACrB,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB,EAAE,2CAA2C;EAChE","sourcesContent":[".go2791332690{\n    color: #dc3545 !important;\n    font-size: 18px\n}\n\n.step-form-horizontal ol .go3842760039 span{\n    border-color: #dc3545 !important;\n    background: #dc3545 !important;\n    color: white !important;\n}\n\n.step-form-horizontal ol .go433304200 span{\n    border-color: #dc3545 !important;\n    background: #dc3545 !important;\n    color: white !important;\n}\n\n.go433304200:before {\n    background:#dc3545 !important ;\n    width: 100% !important;\n}\n\n.buttonWizzard{\n    background: #ccc !important;\n    border-width: 0px !important;\n    color: #fff !important;\n    font-size: 0.875rem !important;\n    padding: 0.625rem 1rem !important;\n    margin-right: 5px !important;\n}\n\n.buttonWizzard:active{\n    background: #ababab !important;\n}\n\n.react-select__menu {\n    z-index: 9999;\n}\n\n.react-select__menu {\n    position: fixed; /* O position: absolute; */\n    top: auto !important;\n    left: auto !important;\n    right: auto !important;\n    bottom: auto !important;\n}\n\n.react-select__menu {\n    max-height: 900px; /* Ajusta el tamaño según tus necesidades */\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
