import React, { useContext, useEffect } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
import { logout } from "../store/actions/AuthActions";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveTokenInLocalStorage, checkAutoLogin } from "../services/AuthService";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

//Auto Logout
import petitionGet from "../services/petitionGet";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LoginAgency from "./pages/LoginAgency";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error401 from "./pages/Error401";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import DashboardTest from "./DashboardTest/DashboardTest"

import Dashboard from "./components/Home/Home";
import Agents from "../pages/Agents/Agents";
import Agency from "../pages/Agency/Agency";
import Products from "../pages/Products/Products";
import States from "../pages/States/States";
import Carries from "../pages/Carries/Carries";
import ImportCommissions from "../pages/ImportCommissions/ImportCommissions";
import ImportExport from "../pages/ImportExport/ImportExport";
import Contracts from "../pages/Contracts/Contracts";
import Rates from "../pages/Rates/Rates";
import Settings from "../pages/Settings/Settings";
import Transactions from "../pages/Transactions/Transactions";
import PayoutHistory from "../pages/PayoutHistory/PayoutHistory";
import ProccessPayout from "../pages/ProccessPayout/ProccessPayout";
import ReportAgents from "../pages/ReportAgents/ReportAgents";
import Policies from "../pages/Policies/Policies";
import ImportJobs from "../pages/ImportJobs/ImportJobs";

const Markup = (props) => {
  const dispatch = useDispatch();

  const onLogout = (selector) => {
    console.log(selector)
    dispatch(logout(props.history, false, "111111111111"));
  };

  petitionGet("authLogout")
    .then(({ data: result }) => {})
    .catch((error) => {
      console.log(error);
      onLogout("authLogout");
    });

  useEffect(() => {
    const autoRefresh = () => {
      const token = JSON.parse(
        localStorage.getItem("userDetails")
      ).access_token;

      petitionGet("refresh", token)
        .then(({ data: result }) => {
          let dataLocalStorage = JSON.parse(
            localStorage.getItem("userDetails")
          );

          let sub_agency_id = dataLocalStorage?.sub_agency_id

          result.result.api_id = dataLocalStorage.api_id;

          if (sub_agency_id) {
            result.result.sub_agency_id = true
          }

          saveTokenInLocalStorage(result.result);
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          // onLogout("refresh");
        });
    };
    
    setInterval(autoRefresh, 60000);
  }, []);


  // if(window.location.pathname === "/login-agent" && !locStorage.loginUser){
  //   console.log("++++++++++++++")
  //   checkAutoLogin(dispatch, props.history, true);
  // }

  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Dashboard },

    /// pages
    { url: "page-register", component: Registration },
    { url: "dashboard-test", component: DashboardTest },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-login-agency", component: LoginAgency }, 
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-401", component: Error401 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "agents", component: Agents },
    { url: "sub-agencies", component: Agency },
    { url: "carries", component: Carries },
    { url: "products", component: Products },
    { url: "states", component: States },
    { url: "import-commissions", component: ImportCommissions },
    { url: "import-jobs", component: ImportJobs },
    { url: "import-export", component: ImportExport },
    { url: "contracts", component: Contracts },
    { url: "rates", component: Rates },
    { url: "settings", component: Settings },
    { url: "Transactions", component: Transactions },
    { url: "payout-history", component: PayoutHistory },
    { url: "proccess-payouts", component: ProccessPayout },
    { url: "report-agents", component: ReportAgents },

    { url: "policies", component: Policies },
    ///Your Phone System
    { url: "home", component: Dashboard },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            // style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(Markup));
