import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import petitionGet from "../../../services/petitionGet";
import json from "../../../config.json";
import axios from "axios";
export default function Step4({ form }) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;


const downloadPdf = async () => {
  try {
    const response = await axios.get(
      urlBase +
        `paylink360/${api_id}/reports/agent/pdf?start_date=${form.start_date}&end_date=${form.end_date}&agent_id=${form.agent_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      }
    );

    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }

    const { pdf: pdfBase64, filename } = response.data;
    const pdfBlob = await fetch(`data:application/pdf;base64,${pdfBase64}`).then((res) =>
      res.blob()
    );

    const url = window.URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};


  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ButtonStyled
        text="Download PDF"
        onClickFunction={() => {
          downloadPdf();
          // download()
        }}
      />
    </div>
  );
}
