const INIT_STATE = {
  didPriceLegacy: "",
  webPhoneOption: "",
  app_pk: "",
  numberConnect: null,
};

const ITPVoice = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "DID_PRICE":
      return {
        ...state,
        didPrice: action.payload,
      };
    case "DID_PRICE_LEGACY":
      return {
        ...state,
        didPriceLegacy: action.payload,
      };
    case "WEB_PHONE_OPTION":
      return {
        ...state,
        webPhoneOption: action.payload,
      };
    case "APP_PK":
      return {
        ...state,
        app_pk: action.payload,
      };
    case "NUMBER_CONNECT":
      console.log("saga");
      return {
        ...state,
        numberConnect: action.payload,
      };

    default:
      return state;
  }
};

export default ITPVoice;
