import React, { Fragment, useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import { TabContent, TabPane } from "reactstrap";
import ButtonStyled from "../../jsx/utils/ButtonStyled";
import petitionGet from "../../services/petitionGet";

import "./styles.css";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import petitionPost from "../../services/petitionPost";

const ReportAgents = () => {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const [step, setStep] = useState(0);
  const [info, setInfo] = useState(true);
  const [form, setForm] = useState(true);
  const [file, setFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [agents, setAgents] = useState([]);

  // const steps = [
  //   { name: "Personal Info", component: <h1>Hola 1</h1> },
  //   { name: "Company Info", component: <h1>Hola 2</h1> },
  //   { name: "Preview Values", component: <h1>Hola 3</h1> },
  //   { name: "Email Setup", component: <h1>Hola 4</h1> },
  // ];

  const modalActions = () => {
    console.log("test");
  };

  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = () => {
    petitionGet("agents", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.first_name + " " + element.last_name;
        });
        setAgents(result.result);
      })
      .catch((error) => {});
  };

  const stepController = () => {

    switch (step) {
      case 0:
        console.log(form.agent_id);
        if (form.agent_id && form.agent_id != "null") {
          return false;
        } else {
          return true;
        }
        break;
      case 1:
        if (form.start_date && form.end_date ) {
          return false;
        } else {
          return true;
        }
        break;
      default:
        break;
    }
  };

  const stepActions = () => {
    setStep(step + 1);
    // switch (step) {
    //   case 1:
    //     sendReview();
    //     break;
    //   case 2:
    //     sendImport();
    //     break;
    //   default:
    //     if (step === 3) {
    //       modalActions(form);
    //       setStep(0);
    //     } else {
    //       setStep(step + 1);
    //     }
    //     break;
    // }
  };

  const sendReview = () => {
    const formData = new FormData();
    let payload = {
      template_id: form.template_id,
    };
    formData.append("file", file);
    formData.append("payload", JSON.stringify(payload));
    petitionPost("import-templates-preview", formData)
      .then(({ data: result }) => {
        let titles = [];
        for (let key in result.result[0]) {
          titles.push({ title: key, key: key });
        }

        setPreviewData({
          title: titles,
          content: [...result.result],
        });
        setStep(step + 1);
      })
      .catch((error) => console.log(error));
  };

  const sendImport = () => {
    const formData = new FormData();
    let payload = {
      template_id: form.template_id,
    };
    formData.append("file", file);
    formData.append("payload", JSON.stringify(payload));
    petitionPost("import", formData)
      .then(({ data: result }) => {
        setStep(step + 1);
      })
      .catch((error) => console.log(error));
  };

  const resetForm = () => {
    setStep(0);
    setInfo(true);
    setForm(true);
  };

  return (
    <Fragment>
      <Stepper
        steps={[
          { label: "Agents" },
          { label: "Dates" },
          { label: "PDF" },
          // { label: "Calculate Result" },
          // { label: "Confirm Payouts" },
        ]}
        activeStep={step}
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <TabContent activeTab={step.toString()}>
            <TabPane tabId="0" className="p-3">
              <Step1 agents={agents} form={form} setForm={setForm} />
            </TabPane>
            <TabPane tabId="1" className="p-3">
              {/* <Step2 form={form} setForm={setForm} /> */}
              <Step2 setForm={setForm} form={form} />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Step3 setForm={setForm} form={form} />
            </TabPane>
            {/* <TabPane tabId="3" className="p-3">
              <Step4 resetForm={resetForm} />
            </TabPane>
            <TabPane tabId="4" className="p-3">
              <Step5 resetForm={resetForm} />
            </TabPane> */}
          </TabContent>
          <div
            style={{
              with: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* {step != 0 && step != 3 && ( */}
            <button
              className="buttonWizzard"
              onClick={() => {
                if (step != 0) {
                  setStep(step - 1);
                }
              }}
            >
              Back
            </button>
            {/* )} */}
            {step < 2 && (
              <ButtonStyled
                text={"Next"}
                onClickFunction={() => {
                  stepActions();
                }}
                disabled={stepController()}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReportAgents;
