// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-autocomplete {
  position: relative;
  display: inline-block;
  width: 50%;
}

.dropdown-autocomplete__input {
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  font-size: 14px;
}

.dropdown-autocomplete__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
}

.dropdown-autocomplete__item {
  padding: 6px 10px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-autocomplete__item:hover {
  background-color: #f5f5f5;
}

.dropdown-autocomplete__item.active {
  background-color: #337ab7;
  color: #fff;
}

.dropdown-autocomplete.show .dropdown-autocomplete__list {
  display: block;
}

ul.options-container.dropdown-autocomplete__options {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ReportAgents/Step1/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,YAAY;EACZ,aAAa;EACb,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;EAChB,0BAA0B;EAC1B,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".dropdown-autocomplete {\n  position: relative;\n  display: inline-block;\n  width: 50%;\n}\n\n.dropdown-autocomplete__input {\n  padding: 6px 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  width: 200px;\n  font-size: 14px;\n}\n\n.dropdown-autocomplete__list {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: 999;\n  display: none;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  border: 1px solid #ccc;\n  border-top: none;\n  border-radius: 0 0 4px 4px;\n  background-color: #fff;\n  max-height: 150px;\n  overflow-y: auto;\n}\n\n.dropdown-autocomplete__item {\n  padding: 6px 10px;\n  font-size: 14px;\n  cursor: pointer;\n}\n\n.dropdown-autocomplete__item:hover {\n  background-color: #f5f5f5;\n}\n\n.dropdown-autocomplete__item.active {\n  background-color: #337ab7;\n  color: #fff;\n}\n\n.dropdown-autocomplete.show .dropdown-autocomplete__list {\n  display: block;\n}\n\nul.options-container.dropdown-autocomplete__options {\n  position: absolute;\n  width: 100%;\n  background: white;\n  border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
