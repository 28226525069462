import React from "react"
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

export default function Step4({ resetForm }) {
  return (
    <div style={{ display: "flex", flexDirection: "column",alignItems:"center" }}>
      <h2>Import Job Created</h2>
      <ButtonStyled
        text="View Jobs"
        onClickFunction={() => {
          resetForm();
        }}
      />
    </div>
  );
}
