import React, { useState, useEffect, useRef } from "react";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import Loading from "../../jsx/utils/Loading";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import EditIcon from "@material-ui/icons/Edit";
import getQueryString from "../../jsx/utils/getQueryString";

const Overrides = () => {
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddModal = () => {
    setModalAdd(true);
  };

  const activeEditModal = (e) => {
    setInfo(e);
    setModalAdd(true);
  };

  const formatPercentage = (value) => {
    let strNumber = (value * 100).toString();
    const regex = /^\d+(?:\.\d{0,2})?$/;

    if (!regex.test(strNumber)) {
      if (strNumber.includes(".")) {
        return `${parseFloat(strNumber).toFixed(2)}%`;
      } else {
        return `${strNumber}%`;
      }
    } else {
      return `${parseFloat(strNumber).toFixed(2)}%`;
    }
  };

  const closeModal = () => {
    setInfo(null);
    setModalAdd(false);
    setModalDelete(false);
    setErrorMessage("");
  };

  const reloadData = (deleted) => {
    let parameter = "";
    closeModal();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp);
      petition(parameter, paginationTemp);
      pagination.current = paginationTemp;
    } else {
      parameter = getQueryString(pagination.current);
      petition(parameter, pagination.current);
    }
  };

  const addFunction = (form) => {
    setLoadingModal(true);

    let data = {
      ...form,
      percentage_amount: Number(parseFloat(form.percentage_amount) / 100),
      agents: [],
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    form.agentsTemp.forEach((el) => {
      data.agents = [...data.agents, el.pk];
    });
    if (!info) {
      petitionPost("overrides", data)
        .then(({ data: result }) => {
          setLoadingModal(false);
          reloadData();
        })
        .catch((error) => {
          if (error?.response && error?.response?.status === 400) {
            setErrorMessage(error?.response?.data?.error?.error_detailed);
            setLoadingModal(false);
          } else {
            console.log(error);
          }
        });
    } else {
      petitionPatch("overrides", { data, override_id: info.pk })
        .then(({ data: result }) => {
          setLoadingModal(false);
          reloadData();
        })
        .catch((error) => {
          if (error?.response && error?.response?.status === 400) {
            setErrorMessage(error?.response?.data?.error?.error_detailed);
            setLoadingModal(false);
          } else {
            console.log(error);
          }
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("overrides", info.pk)
      .then(({ data: result }) => {
        setLoadingModal(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => console.log(error));
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const petition = async (parameter, paginationTemp) => {
    setLoading(true);

    const completeParameter = `${parameter || ""}${
      subAgencyQuery
        ? parameter
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;

    await petitionGet("overrides", { parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.percentage_amount = formatPercentage(
            element.percentage_amount
          );
          element.agentName = !element.agent
            ? "Agent Not Exist"
            : `${element.agent.first_name} ${element.agent.last_name}`;

          element.agents.forEach((el) => {
            el.label = el.first_name + " " + el.last_name;
          });
        });

        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "agent", key: "agentName" },
            { title: "Override Amount", key: "percentage_amount" },
          ],
          content: [...result.result],
          actions: sub_agency_id ? null : {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeEditModal,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          addButton: sub_agency_id ? null : {
            label: "Add Override",
            handleClick: activeAddModal,
          },
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
        });

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalAdd && (
        <ModalAdd
          modal={modalAdd}
          closeModal={closeModal}
          modalFunction={addFunction}
          loading={loadingModal}
          errorMessage={errorMessage}
          edit={info}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={closeModal}
          loading={loadingModal}
        />
      )}

      {dataTable && (
        <CustomTable
          data={dataTable}
          allResult={allResult}
          pagination={itemsPerPage.current}
        />
      )}
    </>
  );
};

export default Overrides;
