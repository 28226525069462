import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import petitionGet from "../../../services/petitionGet";
import Loading from "../../utils/Loading";
import useScreenSize from "../../hooks/useScreenSize";
import CustomLineChart from "./Graphics/CustomLineChart";
import TopTenAgents from "./Graphics/TopTenAgents";
import PremiumsProcessed from "./Graphics/PremiumsProcessed";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CustomTable from "../../utils/CustomTable/CustomTable";
import "./home.css"

const DashboardHome = () => {
  const [loading, setLoading] = useState(true);
  const [labelXNewPolicies, setLabelXNewPolicies] = useState([]);
  const [labelYNewPolicies, setLabelYNewPolicies] = useState([]);
  const [labelXLapsed, setLabelXLapsed] = useState([]);
  const [labelYLapsed, setLabelYLapsed] = useState([]);
  const [labelXCanceled, setLabelXCanceled] = useState([]);
  const [labelYCanceled, setLabelYCanceled] = useState([]);
  const [categoriesTopAgents, setCategoriesTopAgents] = useState([]);
  const [peopleTopAgents, setPeopleTopAgents] = useState([]);
  const [month, setMonth] = useState([]);
  const [valueMonth, setValueMonth] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const { width, height } = useScreenSize();
  const [tableData, setTableData] = useState([]);
  
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""


const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const getMonthName = (dateStr) => {
  const year = parseInt(dateStr.split('-')[0]);
  const month = parseInt(dateStr.split('-')[1]) - 1; // JavaScript's getMonth() starts from 0 (January) to 11 (December)
  const date = new Date(year, month, 1);
  return monthNames[date.getMonth()];
}

  const getDataGraphic = (parameter) => {
    setLoading(true);

    const completeParameter = `${parameter || ""}${
      subAgencyQuery
        ? parameter
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;
    petitionGet("dashboard", { parameter: completeParameter })
      .then(({ data: result }) => {

        const newTableData = Object.keys(result.result.policies.monthly_totals).map(month => {
          return {
            month: getMonthName(month),
            policies: result.result.policies.monthly_totals[month],
            businessDays: result.result.policies.weekdays_per_month[month],
            APPWD: result.result.policies.avg_policies_per_weekday[month],
            AVP: `$${result.result.policies.avp_per_month[month].toLocaleString('en-US', { minimumFractionDigits: 2 })}`

          };
        });

        setTableData(newTableData);  // Set the new table data here


        let dates = Object.keys(result.result.policies.new_policies).map(
          (dateStr) => new Date(dateStr)
        );
        let values = Object.values(result.result.policies.new_policies);

        let PoliciesCount = result.result.top_agents.map(
          (agent) => agent.policies_count
        );
        let PoliciesTotal = result.result.top_agents.map(
          (agent) => agent.policies_total
        );

        let categoriesAgents = [
          {
            name: "Policies Count",
            backgroundColor: "rgb(28, 97, 155)",
            borderColor: "rgb(28, 97, 155)",
            value: PoliciesCount,
          },
          {
            name: "Policies Total",
            backgroundColor: "rgb(49, 153, 241)",
            borderColor: "rgba(28, 97, 155, 0.9)",
            value: PoliciesTotal,
          },
        ];

        let people = result.result.top_agents.map(
          (agent) => agent.agent__first_name + " " + agent.agent__last_name
        );

        let months = result.result.premiun_processed.map(
          (element) => element.month
        );
        let valueMonths = result.result.premiun_processed.map(
          (element) => element.monthly_total
        );

        let datesLapsed = Object.keys(result.result.lapsed_policies).map(
          (dateStr) => new Date(dateStr)
        );
        let valuesLapsed = Object.values(result.result.lapsed_policies);

        let datesCanceled = Object.keys(result.result.canceled_polices).map(
          (dateStr) => new Date(dateStr)
        );
        let valuesCanceled = Object.values(result.result.canceled_polices);

        setLabelXNewPolicies(dates);
        setLabelYNewPolicies(values);

        setCategoriesTopAgents(categoriesAgents);
        setPeopleTopAgents(people);

        setMonth(months);
        setValueMonth(valueMonths);

        setLabelXLapsed(datesLapsed);
        setLabelYLapsed(valuesLapsed);

        setLabelXCanceled(datesCanceled);
        setLabelYCanceled(valuesCanceled);

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const getInitialDates = () => {
    const today = new Date();

    const monthToday = today.getMonth() + 1;
    const dayToday = today.getDate();
    const yearToday = today.getFullYear();

    const firstMonthYear = 1;
    const firstDayYear = 1;
    const currentYear = today.getFullYear();

    const start =
      (firstMonthYear < 10 ? `0${firstMonthYear}` : firstMonthYear) +
      "/" +
      (firstDayYear < 10 ? `0${firstDayYear}` : firstDayYear) +
      "/" +
      currentYear;
    const end =
      (monthToday < 10 ? `0${monthToday}` : monthToday) +
      "/" +
      (dayToday < 10 ? `0${dayToday}` : dayToday) +
      "/" +
      yearToday;

    const parameter = `?added_after=${currentYear}-${
      firstMonthYear < 10 ? `0${firstMonthYear}` : firstMonthYear
    }-${
      firstDayYear < 10 ? `0${firstDayYear}` : firstDayYear
    }&added_before=${yearToday}-${
      monthToday < 10 ? `0${monthToday}` : monthToday
    }-${dayToday < 10 ? `0${dayToday}` : dayToday}`;

    return {
      start,
      end,
      parameter,
    };
  };

  const onchangeDate = (date) => {
    let dateSplit = date.replaceAll(" ", "");
    let rangeDates = dateSplit.split("-");
    let starDateInput = rangeDates[0];
    let endDateInput = rangeDates[1];

    let startDate =
      rangeDates[0].split("/")[2] +
      "-" +
      rangeDates[0].split("/")[0] +
      "-" +
      rangeDates[0].split("/")[1];

    let endDate =
      rangeDates[1].split("/")[2] +
      "-" +
      rangeDates[1].split("/")[0] +
      "-" +
      rangeDates[1].split("/")[1];

    let parameter = `?added_after=${startDate}&added_before=${endDate}`;

    setStartDate(starDateInput);
    setEndDate(endDateInput);

    getDataGraphic(parameter);
  };

  useEffect(() => {
    let { start, end, parameter } = getInitialDates();

    setStartDate(start);
    setEndDate(end);
    getDataGraphic(parameter);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <h1>Dashboards</h1>
            <Col xs={3}>
              <label style={{ marginLeft: "20px" }}>
                Start Date - End Date
              </label>
              <DateRangePicker
                initialSettings={{
                  startDate: startDate,
                  endDate: EndDate,
                  alwaysShowCalendars: true,
                }}
                onApply={(e) => onchangeDate(e.target.value)}
              >
                <input
                  type="text"
                  defaultValue="Select Dates"
                  className="form-control input-daterange-timepicker"
                />
              </DateRangePicker>
            </Col>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="">
          <div>
            <h2>New Policies</h2>
            <div
              style={{
                width: width > 2000 ? "70%" : (width > 1800 ? "60%" : "60%"),
                height: width > 2000 ? "70%" : (width > 1800 ? "60%" : "30%"),
                float: "left"
              }}
            >
              <CustomLineChart
                labelX={labelXNewPolicies}
                labelY={labelYNewPolicies}
                legend="New Policies"
              />
            </div>
          </div>
          <div 
              style={{
                width: width > 2000 ? "30%" : (width > 1800 ? "40%" : (width > 1200 ? "40%" : "100%")),
                height: width > 2000 ? "30%" : (width > 1800 ? "30%" : "30%"),
                float: "right",
                marginTop: "60px",
                marginRight: "0", // Add this line
                paddingLeft: "30px"
              }}
      
          >

           
            <div
            // style={{
            //   width: width > 1800 ? "30%" : "40%",
            //   height: width > 1800 ? "30%" : "40%",
            // }}
            >

                  <table className="chartTable">
                    <thead>
                    <tr>
                      <th title="Month of the Year">Month</th>
                      <th title="Total Number of Policies">Policies</th>
                      <th title="Total Number of Workdays">Work Days</th>
                      <th title="Average Policies Per Workday">APPWD</th>
                      <th title="Annualized Value of Premiums">AVP</th>
                    </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.month}</td>
                          <td>{row.policies}</td>
                          <td>{row.businessDays}</td>
                          <td>{row.APPWD}</td>
                          <td>{row.AVP}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              
            </div>
          </div> 
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>Lapsed Policies</h2>
          <div
            style={{
              width: width > 1800 ? "60%" : "70%",
              height: width > 1800 ? "60%" : "70%",
            }}
          >
            <CustomLineChart
              labelX={labelXLapsed}
              labelY={labelYLapsed}
              legend="Lapsed Policies"
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>Canceled Policies</h2>
          <div
            style={{
              width: width > 1800 ? "60%" : "70%",
              height: width > 1800 ? "60%" : "70%",
            }}
          >
            <CustomLineChart
              labelX={labelXCanceled}
              labelY={labelYCanceled}
              legend="Canceled Policies"
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>Top 10 Agents</h2>
          <div
            style={{
              width: width > 1800 ? "60%" : "70%",
              height: width > 1800 ? "60%" : "70%",
            }}
          >
            <TopTenAgents
              categories={categoriesTopAgents}
              people={peopleTopAgents}
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>Premiums Processed</h2>
          <div
            style={{
              width: width > 1800 ? "30%" : "40%",
              height: width > 1800 ? "30%" : "40%",
            }}
          >
            <PremiumsProcessed labels={month} value={valueMonth} />{" "}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default DashboardHome;
