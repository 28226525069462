import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import petitionPatch from "../../services/petitionPatch";
import petitionDelete from "../../services/petitionDelete";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalAddGroups from "./ModalUtility/ModalAddGroups";
import ModalDeleteGroups from "./ModalUtility/ModalDeleteGroups";
import ModalFilter from "../../jsx/utils/ModalFilter";
import ModalDuplicate from "./ModalUtility/ModalDuplicate";
import ModalImport from "./ModalUtility/ModalImport";
import Loading from "../../jsx/utils/Loading";
import getQueryString from "../../jsx/utils/getQueryString";
import CommissionRate from "./CommissionRate/CommissionRate";
import { formatHourof24at12 } from "../../utils/GetChatDate";
import { sendToastError } from "../../jsx/utils/toastAlert";
import "./styles.css";

export default function Rates() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [infoGroups, setInfoGroups] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDeleteGroups, setModalDeleteGroups] = useState(false);
  const [modalAddGroups, setModalAddGroups] = useState(false);
  const [modalDuplicate, setModalDuplicate] = useState(false);
  const [loadingAddLead, setLoadingAddLead] = useState(false);
  const [loadingDelete, setLoadingDeleteLead] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [products, setProducts] = useState([]);
  const [states, setStates] = useState([]);
  const [commisionRates, setCommisionRates] = useState(null);
  const [formGroups, setFormGroups] = useState({ name: "", description: "", pay_once: false, pay_one_month_advanced: false });
  const [loadingEditGroups, setLoadingEditGroups] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [modalImportRates, setModalImportRates] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [form, setForm] = useState({
    pk: null,
    name: "",
    payment_frequency: "",
    created_at: "",
    updated_at: "",
  });

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form) => {
    setLoadingFilter(true);
    let parameter = getQueryString(form);
    petition(parameter);
  };

  const dataFilterModal = {
    onSaveFunction: (form) => onSaveFunction(form),
    onHidde: () => onHidde(),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Rate Created On or After",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_after",
            key: "added_after",
          },
          {
            label: "Rate Created On or Before",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_before",
            key: "added_before",
          },
        ],
      },
    ],
  };

  const activeEditModalGroup = (e) => {
    let data = {
      ...e,
      description: e.description || "",
    };

    setFormGroups(data);
    setInfoGroups(data);
    setModalAddGroups(true);
  };

  const activeEditRead = (e) => {
    setFormGroups(e);
    setInfoGroups(e);
    setCommisionRates(e);
  };

  const activeDeleteModalGroup = (e) => {
    setInfoGroups(e);
    setModalDeleteGroups(true);
  };

  const activeDuplicateModalGroup = (e) => {
    setInfoGroups(e);
    setModalDuplicate(true);
  };

  const activeImportModal = () => {
    setModalImportRates(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalAdd(false);
    setModalDelete(false);
    setModalImportRates(false);
    setInfo(null);
    setModalDuplicate(false);
    resetForm();
    setModalAddGroups(false);
    setModalDeleteGroups(false);
    setInfoGroups(null);
    setCommisionRates(null);

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp);
      petition(parameter, paginationTemp);
      pagination.current = paginationTemp;
    } else {
      parameter = getQueryString(pagination.current);
      petition(parameter, pagination.current);
    }
  };

  const petition = async (parameter, paginationTemp, noLoading) => {
    if (!noLoading) setLoading(true);

    const completeParameter = `${parameter || ""}${
      subAgencyQuery
        ? parameter
          ? `&${subAgencyQuery}`
          : `?${subAgencyQuery}`
        : ""
    }`;

    await petitionGet("rates-group", { id, parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = element.created_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 15)
            .replaceAll("-", "/");
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "NAME", key: "name" },
            { title: "DESCRIPTION", key: "description" },
            // { title: "CREATED ON", key: "created_at" },
            // { title: "UPDATED ON", key: "updated_at" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeEditModalGroup,
              },
              {
                name: "Edit Product Rates",
                icon: <EditIcon />,
                handleClick: activeEditRead,
              },
              {
                name: "Duplicate",
                icon: <FileCopyIcon />,
                handleClick: activeDuplicateModalGroup,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModalGroup,
              },
            ],
          },
          noColumnNumber: true,
          addButton: {
            dropdown: {
              name: "Add Rate Group",
              options: [
                {
                  name: "Add Rate Group",
                  handleClick: () => setModalAddGroups(true),
                },
                {
                  name: "Import Rate Groups",
                  handleClick: activeImportModal,
                },
              ],
            },
          },
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
          iconAction: [
            {
              name: "Export",
              style: { marginLeft: "10px" },
              icon: <SystemUpdateAltIcon className="icons-contacts" />,
              handleClick: exportFunction,
            },
          ],
          // showMorefilters: () => setFilterModal(true),
        });
        setLoading(false);
        setLoadingEditGroups(false);
        setLoadingDeleteLead(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const addDataGroups = () => {
    setLoadingEditGroups(true);
    let data = {
      name: formGroups.name,
      description: formGroups.description,
      pay_once: formGroups.pay_once,
      pay_one_month_advanced: !formGroups.pay_once ? false : formGroups.pay_one_month_advanced,
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    if (infoGroups) {
      setLoadingEditGroups(true);
      petitionPatch("commission-rate-groups", {
        rate_id: infoGroups.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("commission-rate-groups", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const addData = () => {
    let data = {
      name: form.name,
      commission_rate: form.commission_rate,
      product_id: parseInt(form.product_id),
      state_id: parseInt(form.state_id),
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    if (info) {
      petitionPatch("commission-rates", {
        rate_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("commission-rates", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFunctionGroups = () => {
    setLoadingDeleteLead(true);
    petitionDelete("rates-groups", infoGroups.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteFunction = () => {
    petitionDelete("rates", infoGroups.pk)
      .then(({ data: { result } }) => {
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const duplicateFunction = () => {
    setLoadingDuplicate(true);
    petitionGet("duplicate-rates", { rate_id: infoGroups?.pk })
      .then(({ data: { result } }) => {
        setLoadingDuplicate(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const importFunction = () => {
    setLoadingImport(true);
    const formData = new FormData();
    formData.append("file", file);
    petitionPost("import-rates-groups", formData)
      .then(({ data: result }) => {
        setLoadingImport(false);
        reloadData();
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setLoadingImport(false);
          sendToastError();
        }

        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.error);
          setLoadingImport(false);
        }

        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      payment_frequency: "",
      created_at: "",
      updated_at: "",
    });
    setFormGroups({ name: "", description: "" , pay_once: false, pay_one_month_advanced: false });
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const exportFunction = (element) => {
    setLoadingIcon(element.icon);
    petitionGet("export-rates-groups")
      .then(({ data: result }) => {
        let link = document.createElement("a");
        link.download = "ratesGroups.json";
        link.href = `data:application/json;base64,${result.result}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setLoadingIcon(false);
      })
      .catch((error) => console.log(error));
  };

  //CommisionRates
  const itemsPerPageCommisionRates = useRef("10");
  const paginationCommisionRates = useRef({
    offset: 0,
    limit: 10,
  });
  const [loadingCommisionRate, setLoadingCommisionRate] = useState(false);
  const [dataTableCommisionRates, setDataTableCommisionRates] = useState(null);
  const [modalAddCommisionRates, setModalAddCommisionRates] = useState(false);
  const [loadingAddCommisionRates, setLoadingAddCommisionRates] =
    useState(false);
  const [modalDeleteCommisionRates, setModalDeleteCommisionRates] =
    useState(false);
  const [infoCommisionRates, setInfoCommisionRates] = useState(null);
  const [loadingDeleteCommisionRates, setLoadingDeleteCommisionRates] =
    useState(false);
  const [rateRules, setRateRules] = useState(null);
  const [allResultCommisionRates, setAllResultCommisionRates] = useState(null);

  const activeModalAdd = () => {
    setModalAddCommisionRates(true);
  };

  const activeModalEdit = (e) => {
    setModalAddCommisionRates(true);
    setInfoCommisionRates(e);
  };

  const activeModalDelete = (e) => {
    setInfoCommisionRates(e);
    setModalDeleteCommisionRates(true);
  };

  const activeEditCommisionRateGroup = (e) => {
    setRateRules(e);
  };

  const closeModal = () => {
    setModalAddCommisionRates(false);
    setModalDeleteCommisionRates(false);
    setInfoCommisionRates(null);
    setRateRules(null);
  };

  const back = (deleted) => {
    let parameter = "";
    closeModal();

    if (deleted) {
      let paginationTemp = {
        limit: paginationCommisionRates.current.limit,
        offset:
          paginationCommisionRates.current.offset -
          paginationCommisionRates.current.limit,
      };
      parameter = getQueryString(paginationTemp);
      petitionComissionRate(parameter, paginationTemp);
      paginationCommisionRates.current = paginationTemp;
    } else {
      parameter = getQueryString(paginationCommisionRates.current);
      petitionComissionRate(parameter, paginationCommisionRates.current);
    }
  };

  const addCommisionRate = (form, showError) => {
    setLoadingAddCommisionRates(true);

    let data = {
      commission_rate: Number(parseFloat(form.commission_rate) / 100),
      products: form.products,
      states: form.states,
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    const handleApiError = (error) => {
      setLoadingAddCommisionRates(false);
      if (error.response) {
        console.log("11111111111111",error)
        showError(error.response.data.error);
      } else {
        console.log(error);
      }
    };

    if (infoCommisionRates) {
      petitionPatch("commission-rates", {
        group_id: commisionRates.pk,
        rate_id: infoCommisionRates.pk,
        data,
      })
        .then(({ data: result }) => {
          setLoadingAddCommisionRates(false);
          back();
        })
        .catch(handleApiError);
    } else {
      petitionPost("commission-rates", { data, group_id: commisionRates.pk })
        .then(({ data: result }) => {
          setLoadingAddCommisionRates(false);
          back();
        })
        .catch(handleApiError);
    }
  };

  const deleteCommisionRate = () => {
    setLoadingDeleteCommisionRates(true);
    petitionDelete("commission-rates", {
      group_id: commisionRates.pk,
      rate_id: infoCommisionRates.pk,
    })
      .then(({ data: result }) => {
        setLoadingDeleteCommisionRates(false);
        if (
          allResultCommisionRates.result.length === 1 &&
          parseInt(allResultCommisionRates.offset) !== 0
        ) {
          back(true);
        } else {
          back();
        }
      })
      .catch((error) => console.log(error));
  };

  const formatDate = (date) => {
    let year = date.split(" ")[0].split("-")[0];
    let month = date.split(" ")[0].split("-")[1];
    let day = date.split(" ")[0].split("-")[2];

    let hour = formatHourof24at12(date);

    return year + "/" + month + "/" + day + " " + hour;
  };

  const formatPercentage = (value) => {
    let strNumber = (value * 100).toString();
    const regex = /^\d+(?:\.\d{0,2})?$/;

    if (!regex.test(strNumber)) {
      if (strNumber.includes(".")) {
        return `${parseFloat(strNumber).toFixed(2)}%`;
      } else {
        return `${strNumber}%`;
      }
    } else {
      return `${parseFloat(strNumber).toFixed(2)}%`;
    }
  };

  const getDataFromArray = (array) => {
    if (array.length > 7) {
      let reducedArray = array.slice(0, 7).map((element) => element.name);
      return reducedArray.join(", ") + "...";
    } else {
      let newArray = array.map((state) => state.name);
      return newArray.join(",");
    }
  };

  const petitionComissionRate = async (
    parameter,
    paginationTemp,
    noLoading
  ) => {
    if (!noLoading) setLoadingCommisionRate(true);
    let productsTemp = [];
    let stateaTemp = [];

    await petitionGet("products", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });

        productsTemp = result.result;
        setProducts(result.result);
      })
      .catch((error) => {});
    await petitionGet("states", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        stateaTemp = result.result;
        setStates(result.result);
      })
      .catch((error) => {});
    await petitionGet("rates", { id, group_id: commisionRates.pk, parameter })
      .then(({ data: result }) => {
        setAllResultCommisionRates(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = formatDate(element.created_at);
          element.updated_at = formatDate(element.updated_at);
          element.productName = productsTemp.find(
            (e) => e.pk === element.product_id
          )?.name;
          element.stateName = stateaTemp.find(
            (e) => e.pk === element.state_id
          )?.name;
          element.commission_rate = formatPercentage(element.commission_rate);
          element.statesName = getDataFromArray(element.states);
          element.productsName = getDataFromArray(element.products);
          element.states.forEach((el, i) => {
            el.value = el.pk;
            el.label = el.name;
          });
        });
        setDataTableCommisionRates({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "PRODUCT", key: "productsName" },
            { title: "STATES", key: "statesName" },
            { title: "Default Rate", key: "commission_rate" },
            // { title: "CREATED ON", key: "created_at" },
            // { title: "UPDATED ON", key: "updated_at" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: (e) => activeModalEdit(e),
              },
              {
                name: "Rate Rules",
                icon: <EditIcon />,
                handleClick: (e) => activeEditCommisionRateGroup(e),
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: (e) => activeModalDelete(e),
              },
            ],
          },
          noColumnNumber: true,
          addButton: {
            label: "Add Rate",
            handleClick: activeModalAdd,
          },
          itemsPerPage: (e) => activeItemsPerPageComissionRate(e),
          changePage: (page) => changePageComissionRate(page),
          // showMorefilters: () => setFilterModal(true),
        });
        setLoadingCommisionRate(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const changePageComissionRate = (page) => {
    let paginationTemp = {
      limit: paginationCommisionRates.current.limit,
      offset:
        page === 1 ? 0 : (page - 1) * paginationCommisionRates.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petitionComissionRate(parameter, paginationTemp.offset, true);
    paginationCommisionRates.current = paginationTemp;
  };

  const activeItemsPerPageComissionRate = (e) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(e),
    };

    let parameter = getQueryString(paginationTemp);
    petitionComissionRate(parameter, paginationTemp);
    itemsPerPageCommisionRates.current = e;
    paginationCommisionRates.current = paginationTemp;
  };

  return (
    <>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
          }}
          form={form}
          setForm={setForm}
          loading={loadingAddLead}
          products={products}
          states={states}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
            setInfo(null);
          }}
          loading={loadingDelete}
        />
      )}

      {modalDuplicate && (
        <ModalDuplicate
          openModal={modalDuplicate}
          modalActions={duplicateFunction}
          closeModal={() => {
            resetForm();
            setModalDuplicate(false);
            setInfo(null);
          }}
          loading={loadingDuplicate}
        />
      )}

      {modalAddGroups && (
        <ModalAddGroups
          openModal={modalAddGroups}
          modalActions={addDataGroups}
          closeModal={() => {
            resetForm();
            setModalAddGroups(false);
          }}
          form={formGroups}
          setForm={setFormGroups}
          loading={loadingEditGroups}
        />
      )}

      {modalDeleteGroups && (
        <ModalDeleteGroups
          openModal={modalDeleteGroups}
          modalActions={deleteFunctionGroups}
          closeModal={() => {
            resetForm();
            setModalDeleteGroups(false);
          }}
          loading={loadingDelete}
        />
      )}

      {modalImportRates && (
        <ModalImport
          modal={modalImportRates}
          closeModal={() => {
            setModalImportRates(false);
          }}
          modalFunction={importFunction}
          loading={loadingImport}
          file={file}
          setFile={setFile}
          errorMessage={errorMessage}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {!commisionRates && (
            <>
              {dataTable && (
                <CustomTable
                  data={dataTable}
                  noClass={true}
                  allResult={allResult}
                  noOverflow={true}
                  pagination={itemsPerPage.current}
                  loadingIcon={loadingIcon}
                />
              )}
            </>
          )}

          {commisionRates && (
            <CommissionRate
              details={commisionRates}
              backFunction={reloadData}
              products={products}
              states={states}
              loading={loadingCommisionRate}
              dataTable={dataTableCommisionRates}
              modalAdd={modalAddCommisionRates}
              loadingAdd={loadingAddCommisionRates}
              modalDelete={modalDeleteCommisionRates}
              info={infoCommisionRates}
              loadingDelete={loadingDeleteCommisionRates}
              rateRules={rateRules}
              petition={petitionComissionRate}
              back={back}
              addCommisionRate={addCommisionRate}
              deleteCommisionRate={deleteCommisionRate}
              closeModal={closeModal}
              allResult={allResultCommisionRates}
              getQueryString={getQueryString}
              pagination={paginationCommisionRates.current}
              itemsPerPage={itemsPerPageCommisionRates.current}
            />
          )}
        </>
      )}
    </>
  );
}
