import React, { useState, useEffect } from "react";
import petitionGet from "../../../services/petitionGet";
import Loading from "../../../jsx/utils/Loading";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import NavTabCustom from "../../../jsx/utils/NavTabCustom";
import ErrorsTable from "./Tabs/ErrorsTable";

const ModalView = ({ modal, closeModal, info }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const petition = () => {
    setLoading(true);
    petitionGet("view-error-jobs", { job_id: info?.id })
      .then(({ data: result }) => {
        let accepted = [];
        let failed = [];

        if (result?.result?.accepted?.accepted && Array.isArray(result?.result?.failed?.accepted)) {
          accepted = result?.result?.accepted?.accepted || [];
        }

        if (result?.result?.failed?.failed && Array.isArray(result?.result?.failed?.failed)) {
          failed = result?.result?.failed?.failed;
        }

        setOptions([
          {
            title: "Unable To Import",
            component: <ErrorsTable data={failed} />,
          },
          {
            title: "Without Issues",
            component: <ErrorsTable data={accepted} />,
          },
        ]);

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <Modal show={modal} className="fade bd-example-modal-lg" size="lg">
      <Modal.Header>
        <Modal.Title>View Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? <Loading /> : <NavTabCustom options={options} />}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled text="Close" onClickFunction={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalView;
