import React from 'react'
import { Spinner } from "react-bootstrap";
export default function Loading() {
    return (
        <div style={{ textAlign: "center" }}>
            <Spinner
                animation="grow"
                style={{
                    margin: "10vh auto",
                    height: "10vh",
                    width: "10vh",
                }}
            />
        </div>
    )
}
