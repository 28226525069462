import React from "react";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";

import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  agencies,
}) => {
  const update = () => {
    modalActions(form);
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit Agent" : "Create Agent"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>First Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, first_name: e.target.value })}
              value={form.first_name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Last Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, last_name: e.target.value })}
              value={form.last_name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Email:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              value={form.email}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Agency:</label>
          <div className="col-8">
            <select
              className="arrow-select"
              value={form.agency_id}
              onChange={(e) => {
                setForm({
                  ...form,
                  agency_id: e.target.value,
                });
              }}
            >
              <option value={null}>Select option</option>
              {agencies.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Commissions In Advanced:</label>
          <div className="col-8">
            <Switch
              disabled={form.pk ? false : true}
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() =>
                setForm({
                  ...form,
                  receive_upfront_commission: !form.receive_upfront_commission,
                })
              }
              height={24}
              width={48}
              checked={form.receive_upfront_commission}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.first_name ||
            !form.last_name ||
            !form.email ||
            !form.agency_id
          }
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
