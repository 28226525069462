import React from "react";
import { Modal, Button } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalDelete = ({
  openModal,
  modalActions,
  closeModal,
  loading,
  reset,
  info,
}) => {
  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{!reset ? "Delete" : "Reset Password"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reset && !info.email
          ? "Please set an Email Address for the Agent prior to resetting the password"
          : "Are You Sure?"}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          loading={loading}
          text={!reset ? "Delete" : "Reset"}
          onClickFunction={() => modalActions()}
          disabled={reset && !info.email}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
