import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";

Chart.register(
  LinearScale,
  PointElement,
  LineController,
  TimeScale,
  Title,
  Tooltip,
  LineElement,
  Filler
);

const CustomLineChart = ({ labelX, labelY, legend }) => {
  const chartData = {
    labels: labelX,
    datasets: [
      {
        fill: "start",
        label: legend,
        data: labelY,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Set the background color for the fill
        pointRadius: 0,
        pointHoverRadius: 5,
        tension: 0.1, // Set tension to a value greater than 0
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          parser: "yyyy-MM-dd",
          displayFormats: {
            day: "MMM d",
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 12,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        mode: "nearest",
        intersect: false,
      },
    },
  };
  

  // Use the data and options objects directly as props for the Line component
  return <Line data={chartData} options={options} />;
};

export default CustomLineChart;