import React, { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import { searchRatesGroupService } from "../../services/searchRatesGroupService";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import MenuITP from "../../jsx/utils/MenuITP/MenuITP";
import AgentsDetails from "./AgencyDetails";
import getValuesInObject from "../../jsx/utils/getValuesInObject";
import { sendToastSuccess, sendToastError } from "../../jsx/utils/toastAlert";
import { formatHourof24at12 } from "../../utils/GetChatDate";

export default function Agents() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const myRef = useRef(null);
  const filterText = useRef("");
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [ratesGroups, setRatesGroups] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [agents, setAgents] = useState([]);
  const [agentsDefault, setAgentsDefault] = useState([]);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [allRatesGroup, setAllRatesGroup] = useState([]);
  const [reset, setReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [form, setForm] = useState({
    pk: null,
    name: null,
    rate_group_id: null,
    email: null,
    password: null,
  });

  const onHidde = () => {
    console.log("hidde");
  };

  const searchAgents = (e) => {
    searchRatesGroupService(allRatesGroup, setAllRatesGroup, e);
  };

  const onSaveFunction = (form, formLabels) => {
    setLoadingFilter(true);
    let filtersActive = getValuesInObject(formLabels);
    setFirstLoad(false);

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter =
      getQueryString(paginationTemp) + (filterText.current || "") + filterModal;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: [
        {
          title: "Field",
          inputs: [
            {
              label: "Rate Group",
              input: "selectAutoCompleteSearch",
              inputValue: form.rate_group_id || {},
              checkbox: true,
              checkboxValue: form.rate_group_id ? true : false,
              options: ratesGroups,
              setOptions: searchAgents,
              checkboxName: "checkbox_rate_group_id",
              key: "rate_group_id",
            },
          ],
        },
      ],
    });

    if (!filterText.current) {
      petition(parameter, false, false, false, false, filtersActive);
    } else {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      let value = params.get("name");
      petition(parameter, false, true, value, false, filtersActive);
    }

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    setFilterModal(false);
  };

  const activeAgentDetails = (e) => {
    setAgentDetails(e);
    setForm({
      pk: e.pk,
      name: e.name,
      rate_group_id: e.rate_group_id,
      rateGroupName: e.rateGroupName,
      email: e?.email || "",
      password: e?.password || "",
    });
    setModalAdd(true);
    setInfo(e);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeResetModal = (e) => {
    setReset(true);
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    setErrorMessage("");
    setReset(false);
    resetForm();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter =
        getQueryString(paginationTemp) +
        (filterText.current || "") +
        (filterModal.current || "");

      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[1] : false,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) +
        (filterText.current || "") +
        (filterModal.current || "");

      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[1] : false,
        pagination.current,
        objectFilter.current
      );
    }
  };

  const formatData = (element) => {
    let date = element.slice(0, 10).replaceAll("-", "/");
    let month = date.split("/")[1];
    let day = date.split("/")[2];
    let year = date.split("/")[0];
    let hour = formatHourof24at12(element);

    return month + "/" + day + "/" + year + " " + hour;
  };

  const handleClickRowSubAgency = (e) => {
    localStorage.setItem("subAgencyDetails", JSON.stringify({ ...e }))
    window.location.href = window.location.origin + "/home"
  }

  const petition = async (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    paginationTemp,
    filtersToolip
  ) => {
    if (!noLoading) setLoading(true);
    let ratesGroupTemp = [];
    await petitionGet("rates-group")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        ratesGroupTemp = result.result;
        setRatesGroups(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("sub-agencies", { id, parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = formatData(element.created_at);
          element.updated_at = formatData(element.updated_at);
          element.label = element.name;
          element.value = element.pk;
          element.rateGroupName = element.rate_group_id
            ? ratesGroupTemp.find((rate) => rate.pk === element.rate_group_id)
                .name
            : "Unassigned";
        });

        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "Agency Name", key: "name" },
            { title: "Rate Group", key: "rateGroupName" },
            { title: "Balance", key: "balance" },
            // { title: "CREATED ON", key: "created_at" },
            // { title: "UPDATED ON", key: "updated_at" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeAgentDetails,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          search: {
            label: "Search by Agency Name",
            filterBackend: true,
            onChange: (e) => filterTextBox(e.target.value),
            defaultValue: valueFilter || "",
          },
          addButton: {
            label: "Add Agency",
            handleClick: () => {
              resetForm();
              setModalAdd(true);
              setInfo(false);
            },
          },
          clickRow: handleClickRowSubAgency,
          // showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => {
            if (activeFilterTexBox) {
              activeItemsPerPage(e, true, null);
            } else {
              activeItemsPerPage(e);
            }
          },
          changePage: (page) => {
            if (activeFilterTexBox) {
              changePage(page, true, null);
            } else {
              changePage(page, false, false);
            }
          },
        });

        if (result.result.length > 0) {
          setAgents(result.result);
        }

        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
        // setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const addData = () => {
    setLoadingModal(true);
    let dataSend = {}

    if (info) {
      dataSend = {
        name: form.name,
        rate_group_id: form.rate_group_id,
      };

      if (form.email && info.email !== form.email) {
        dataSend.email = form.email
      }

      if (form.password) {
        dataSend.password = form.password
      }
    } else {
      dataSend = {
        name: form.name,
        rate_group_id: form.rate_group_id,
        email: form.email,
        password: form.password,
      };
    }

    if (info) {
      petitionPatch("sub-agencies", {
        subAgencyId: info.pk,
        data: dataSend,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setErrorMessage(error.response.data.error.error_message);
            setLoadingModal(false);
          } else {
            console.log(error);
          }
        });
    } else {
      petitionPost("sub-agencies", dataSend)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setErrorMessage(error.response.data.error.error_message);
            setLoadingModal(false);
          } else {
            console.log(error);
          }
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("sub-agencies", info.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(pagination.current.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: null,
      rate_group_id: null,
      email: null,
      password: null,
    });
  };

  const changePage = (page, activeFilterTexBox) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    if (activeFilterTexBox) {
      let parameter = getQueryString(paginationTemp) + `${filterText.current}`;

      petition(
        parameter,
        true,
        true,
        filterText.current.split("=")[1],
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterText.current || "");
      petition(
        parameter,
        true,
        false,
        filterText.current.split("=")[1],
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    }
  };

  const filterTextBox = (value) => {
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };

    if (value !== "") {
      let parameter = `${getQueryString(paginationTemp)}&name=${value}`;

      petition(
        parameter,
        true,
        true,
        value,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;

      if (value) {
        filterText.current = `&name=${value}`;
      } else {
        filterText.current = "";
      }
    } else {
      let parameter = getQueryString(paginationTemp);

      petition(
        parameter,
        true,
        false,
        value,
        paginationTemp,
        objectFilter.current
      );
      filterText.current = "";
      pagination.current = paginationTemp;
    }
  };

  const getAllRatesGroup = () => {
    petitionGet("rates-group", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });
        setAllRatesGroup(result.result);
        setRatesGroups(result.result);
      })
      .catch((error) => console.log(error));
  };

  const allPetitions = () => {
    Promise.all([
      getAllRatesGroup(),
      petition(
        getQueryString(pagination.current),
        false,
        false,
        false,
        pagination.current
      ),
    ]);
  };

  const activeItemsPerPage = (data, activeFilterTexBox) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    if (activeFilterTexBox) {
      let parameter = getQueryString(paginationTemp) + `${filterText.current}`;

      petition(
        parameter,
        false,
        true,
        filterText.current.split("=")[1],
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterText.current || "");
      petition(
        parameter,
        false,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    }
  };

  useEffect(() => {
    allPetitions();
  }, []);

  useEffect(() => {
    if (allRatesGroup && firstLoad) {
      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => onHidde(),
        parameters: [
          {
            title: "Field",
            inputs: [
              {
                label: "Rate Group",
                input: "selectAutoCompleteSearch",
                inputValue: form.rate_group_id || {},
                checkbox: true,
                checkboxValue: form.rate_group_id ? true : false,
                options: allRatesGroup,
                setOptions: searchAgents,
                checkboxName: "checkbox_rate_group_id",
                key: "rate_group_id",
              },
            ],
          },
        ],
      });
    }
  }, [allRatesGroup, firstLoad]);

  return (
    <>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
            setErrorMessage("");
          }}
          form={form}
          setForm={setForm}
          loading={loadingModal}
          isValidEmail={isValidEmail}
          setIsValidEmail={setIsValidEmail}
          validateEmail={validateEmail}
          errorMessage={errorMessage}
          ratesGroups={ratesGroups}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
          }}
          loading={loadingModal}
          reset={false}
          info={info}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <>
              <CustomTable
                data={dataTable}
                noClass={true}
                allResult={allResult}
                pagination={itemsPerPage.current}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
