// import React, { useEffect } from "react";
// import { Pagination } from "react-bootstrap";

// // size =  sm lg null => null es el default

// export default function PaginationITP({ size, pages,active,setActive }) {

//   useEffect(() => {}, []);

//   const items = () => {
//     let items = [];
//       if(active > 1){
//         items.push(
//           <Pagination.Item  key={"back"} onClick={()=>active>1?setActive(active-1):setActive(1)}>
//              {"<"}
//           </Pagination.Item>
//         );
//       } else {
//         items.push("");
//       }

//     items.push(
//       <Pagination.Item key={"one"} active={active === 1} onClick={()=>setActive(1)}>
//         {1}
//       </Pagination.Item>
//     );
//     for (let number = 2; number <= pages - 1; number++) {
//       if (number + 2 >= active && number - 2 <= active) {
//         items.push(
//           <Pagination.Item key={number} active={number === active} onClick={()=>setActive(number)}>
//             {number}
//           </Pagination.Item>
//         );
//       }
//     }
//     items.push(
//       <Pagination.Item key={"pages"} active={active === pages} onClick={()=>setActive(pages)}>
//         {pages}
//       </Pagination.Item>
//     );

//     if(active < pages){
//       items.push(
//         <Pagination.Item  key={"next"} onClick={() => active < pages ? setActive(active+1) : {}}>
//           {">"}
//         </Pagination.Item>
//       );
//     } else {
//       items.push("");
//     }

//     return items
//   };

//   return (
//       <Pagination size={size}>{items()}</Pagination>
//   );
// }


import React, { useEffect } from "react";
import { Pagination } from "react-bootstrap";

export default function PaginationITP({ size, pages, active, setActive }) {
  useEffect(() => {}, []);

  const items = () => {
    let items = [];

    let startPage;
    let endPage;

    if (pages <= 3) {
      // Si hay 3 o menos páginas, mostrar todas las páginas
      startPage = 1;
      endPage = pages;
    } else if (active === 1) {
      // Si la página activa es la primera página
      startPage = 1;
      endPage = 3;
    } else if (active === pages) {
      // Si la página activa es la última página
      startPage = pages - 2;
      endPage = pages;
    } else {
      // En el resto de casos
      startPage = active - 1;
      endPage = active + 1;
    }

    if (active > 1) {
      items.push(
        <Pagination.Item
          key={"back"}
          onClick={() => setActive(active > 1 ? active - 1 : 1)}
        >
          {"<"}
        </Pagination.Item>
      );
    } else {
      items.push("");
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => setActive(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (active < pages) {
      items.push(
        <Pagination.Item
          key={"next"}
          onClick={() => setActive(active < pages ? active + 1 : {})}
        >
          {">"}
        </Pagination.Item>
      );
    } else {
      items.push("");
    }

    return items;
  };

  return <Pagination size={size}>{items()}</Pagination>;
}

