import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import Select from "react-select";
import petitionGet from "../../../services/petitionGet";
import Loading from "../../../jsx/utils/Loading";
import Button from "react-bootstrap/Button";

const ErrorModal = ({ isOpen, toggle, message }) => {
  console.log("222222222222222",message)
  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalAddCommisionRate = ({
  modal,
  closeModal,
  addFunction,
  products,
  info,
  loading,
  edit,
  states,
}) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const allStatesOption = { value: "all", label: "All states" };
  const [selectAll, setSelectAll] = useState(false);
  
  const [selectedStates, setSelectedStates] = useState([]);
  const transformedStates = useMemo(() => {
    const allStatesOption = { value: "all", label: "All states" };
    return [allStatesOption, ...states.map((state) => ({
      value: state.pk,
      label: state.name,
    }))];
  }, [states]);

  const transformedProducts = products.map((product) => ({
    value: product.pk,
    label: product.name,
  }))

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };
  const [form, setForm] = useState({
    rate_group_id: info.pk,
    commission_rate: edit
      ? parseFloat(edit.commission_rate.replaceAll("%", ""))
      : "",
    states: edit ? edit.states.map((state) => state.pk) : [],
    products: edit ? edit.products.map((product) => product.pk) : [],
  });

  const handleChange = (e) => {
    const { value } = e.target;
    value.replaceAll(",", ".");
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setForm({ ...form, commission_rate: value });
    }
  };
  
  useEffect(() => {
    if (edit) {
      const defaultStates = form.states.map((statePk) => {
        const stateObj = states.find((state) => state.pk === statePk);
        return { value: stateObj.pk, label: stateObj.name };
      });
      setSelectedStates(defaultStates);
    }
  }, [edit, form.states, states]);

  return (
    <>
      <Modal className="fade" show={modal}>
        <Modal.Header>
          <Modal.Title>
            {info ? "Edit Commission Rate" : "Add Commission Rate"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Default Rate %: </label>
            <div className="col-8">
              <input
                className="form-control"
                onChange={handleChange}
                value={form.commission_rate}
                type="number"
                style={{ paddingRight: "4px" }}
              />
            </div>
          </div>
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Products:</label>
            <div className="col-8">
              <Select
                onChange={(e) => {
                  setForm({ ...form, products: e.map((el) => el.value) });
                }}
                className="basic-single"
                placeholder="Select Option"
                classNamePrefix="select"
                defaultValue={
                  edit
                    ? form.products.map((productPk) => {
                        const stateObj = products.find(
                          (product) => product.pk === productPk
                        );
                        return { value: stateObj.pk, label: stateObj.name };
                      })
                    : []
                }
                isSearchable={true}
                name="products"
                options={transformedProducts}
                isMulti
              />
            </div>
          </div>
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>States:</label>
            <div className="col-8">
            <Select
                onChange={(selectedOptions) => {
                  if (selectedOptions.some((option) => option.value === "all")) {
                    const allStates = transformedStates.filter((state) => state.value !== "all");
                    setSelectedStates(allStates);
                    setForm({ ...form, states: allStates.map(state => state.value) });
                  } else {
                    setSelectedStates(selectedOptions);
                    setForm({ ...form, states: selectedOptions.map(option => option.value) });
                  }
                }}
                className="basic-single"
                placeholder="Select Option"
                classNamePrefix="select"
                defaultValue={
                  edit
                    ? form.states.map((statePk) => {
                        const stateObj = states.find(
                          (state) => state.pk === statePk
                        );
                        return { value: stateObj.pk, label: stateObj.name };
                      })
                    : []
                }
                isSearchable={true}
                name="state_id"
                options={transformedStates}
                isMulti
                value={selectedStates}
              />

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonStyled
            text="Cancel"
            onClickFunction={closeModal}
            disabled={loading}
          />
          <ButtonStyled
            text={edit ? "Edit" : "Add"}
            onClickFunction={() => {
              addFunction(form, (errorMsg) => {
                if(errorMsg.error_message){
                  setErrorMessage(errorMsg.error_message);
                }else{
                  setErrorMessage(errorMsg);
                }
                setShowErrorModal(true);
              });
            }}
            loading={loading}
            disabled={
              !form.commission_rate ||
              !form.products?.length > 0 ||
              !form.states?.length > 0
            }
          />
        </Modal.Footer>
      </Modal>
      <ErrorModal
        isOpen={showErrorModal}
        toggle={handleCloseErrorModal}
        message={errorMessage}
      />
    </>
  );
};

export default ModalAddCommisionRate;
