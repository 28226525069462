// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
  }
  
  .circle {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circle.active {
    background-color: #dc3545;
  }
  
  .circle.completed {
    background-color: #bd2130;
  }
  
  .circle.disabled {
    background-color: #e0e0e0;
  }
  
  .circle-label {
    color: white;
    font-size: 12px;
    font-weight: bold;
  }
  
  .step-label {
    position: absolute;
    bottom: -20px;
    /* left: -50%; */
    width: 100px;
    text-align: center;
    font-size: 10px;
  }
  
  .line {
    height: 2px;
    position: relative;
    top: -1px;
    z-index: 0;
    background-color: #e0e0e0;
}

.line.active {
    background-color: #dc3545;
    z-index: 1;
    width: 100%;
}

.line.completed {
    background-color: #bd2130;
}
  
  .line.disabled {
    background-color: #e0e0e0;
  }`, "",{"version":3,"sources":["webpack://./src/components/Stepper/Stepper.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;EAEE;IACE,yBAAyB;EAC3B","sourcesContent":[".stepper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    margin-bottom: 50px;\n  }\n  \n  .circle {\n    position: relative;\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    background-color: #e0e0e0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .circle.active {\n    background-color: #dc3545;\n  }\n  \n  .circle.completed {\n    background-color: #bd2130;\n  }\n  \n  .circle.disabled {\n    background-color: #e0e0e0;\n  }\n  \n  .circle-label {\n    color: white;\n    font-size: 12px;\n    font-weight: bold;\n  }\n  \n  .step-label {\n    position: absolute;\n    bottom: -20px;\n    /* left: -50%; */\n    width: 100px;\n    text-align: center;\n    font-size: 10px;\n  }\n  \n  .line {\n    height: 2px;\n    position: relative;\n    top: -1px;\n    z-index: 0;\n    background-color: #e0e0e0;\n}\n\n.line.active {\n    background-color: #dc3545;\n    z-index: 1;\n    width: 100%;\n}\n\n.line.completed {\n    background-color: #bd2130;\n}\n  \n  .line.disabled {\n    background-color: #e0e0e0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
