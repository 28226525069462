import React from "react";
import { Modal, Button } from "react-bootstrap";
import ButtonStyled from "../../../../jsx/utils/ButtonStyled";

const ConfirmModal = ({
    openModal
}) => {
  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>confirm body</Modal.Body>
      <Modal.Footer>
        {/* <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        /> */}
        <ButtonStyled
        //   loading={loading}
          text={"Delete"}
        //   onClickFunction={() => modalActions()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
