import React, { Fragment, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import petitionGet from "../../../services/petitionGet";
import petitionPatch from "../../../services/petitionPatch";
import Loading from "../../../jsx/utils/Loading";
import {
  sendToastSuccess,
  sendToastError,
} from "../../../jsx/utils/toastAlert";
import { saveTokenInLocalStorage } from "../../../services/AuthService";

const ProfileSettings = () => {
  const locStorage = JSON.parse(localStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [change, setChange] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    ach_routing_number: "",
    ach_account_number: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setChange(true);
  };

  const petition = () => {
    setLoading(true);
    petitionGet("agent-details")
      .then(({ data: result }) => {
        setForm({
          first_name: result.result.first_name,
          last_name: result.result.last_name,
          ach_routing_number: result.result.ach_routing_number,
          ach_account_number: result.result.ach_account_number,
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const save = () => {
    setLoadingSave(true);
    petitionPatch("agent-details", { data: form })
      .then(({ data: result }) => {
        let dataLocalStorage = {
          ...locStorage,
          first_name: form.first_name,
          last_name: form.last_name,
          firstname: form.first_name,
          lastname: form.last_name,
        };
        saveTokenInLocalStorage(dataLocalStorage);
        setLoadingSave(false);
        sendToastSuccess();
      })
      .catch((error) => {
        console.log(error);
        setLoadingSave(false);
        sendToastError();
      });
  };

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <Row style={{ marginBottom: "2rem", marginTop: "2rem" }}>
        <Col>
          <label htmlFor="first_name">First Name</label>
          <input
            className="form-control"
            name="first_name"
            onChange={handleChange}
            value={form.first_name}
          />
        </Col>
        <Col>
          <label htmlFor="last_name">Last Name</label>
          <input
            className="form-control"
            name="last_name"
            onChange={handleChange}
            value={form.last_name}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="ach_routing_number">ACH Routing Number</label>
          <input
            className="form-control"
            name="ach_routing_number"
            onChange={handleChange}
            value={form.ach_routing_number}
            type="number"
          />
        </Col>
        <Col>
          <label htmlFor="ach_account_number">ACH Account Number</label>
          <input
            className="form-control"
            name="ach_account_number"
            onChange={handleChange}
            value={form.ach_account_number}
            type="number"
          />
        </Col>
      </Row>
      <div style={{ marginTop: "4rem" }} className="d-flex flex-row-reverse">
        <ButtonStyled
          text="Save"
          onClickFunction={save}
          loading={loadingSave}
          disable={
            (!form.first_name &&
              !form.last_name &&
              !form.ach_routing_number &&
              !form.ach_account_number) ||
            !change
          }
        />
      </div>
    </Fragment>
  );
};

export default ProfileSettings;
