import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginAgencyAction
} from "../../store/actions/AuthActions";

// image
import logo from "../../images/logo-full.png";
import loginbg from "../../images/pic1.png";
import logoBlack from "../../images/logoBlack.png";
import petitionGet from "../../services/petitionGet";

import config from "../../config.json";
import { sendToastError } from "../../utils/toastAlert";
import { Spinner } from "react-bootstrap";
import json from "../../config.json"

function LoginAgency(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const params = new URL(document.location).searchParams;
  const token = params.get("token");
  const api_id = params.get("v2");
  const redirect = params.get("redirect")

  const host = json.paylink.prod
    ? json.paylink.prod.split("//")[1]
    : json.paylink.dev.split("//")[1];

    const linkAgentLogin = window.location.host !== host
      ? `http://${window.location.host}/login-agent?redirect=true`
      : json.prod
      ? `${json.paylink.prod}/login-agent?redirect=true`
      : `${json.paylink.dev}/login-agent?redirect=true`;

  const dispatch = useDispatch();

  const redirectAgentLoginPage = () => {
    window.location.href = linkAgentLogin
  }

  const redirectLoginPage = () => {
    props.history.push("/login?redirect=true")
  }

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));
    dispatch(loginAgencyAction(email, password, props.history, setLoading));
  }

  useEffect(() => {
    if (token && !redirect) {
      localStorage.setItem("userDetails", JSON.stringify({ access_token: token }));

      petitionGet("refresh")
        .then((result) => {
          result.data.result.loginAgent = false;
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...result.data.result, access_token: token, api_id })
          );
          window.location.href = "/home";
        })
        .catch((err) => {
          window.location.href = `https://portal${
            config.prod ? "" : ".dev"
          }.itp360.com/login?redirect=https://app${
            config.prod ? "" : ".dev"
          }.voice360.app`;
          sendToastError();
        });
      /*  .finally(() => setLoading(false)); */
    } 
    // else {
    //   window.location.href = `https://portal${
    //     config.prod ? "" : ".dev"
    //   }.itp360.com/login?redirect=https://app${
    //     config.prod ? "" : ".dev"
    //   }.voice360.app`;
    // }
  }, []);

  return (
    !token ? (
      <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logoBlack} alt="" style={{ width: "200px" }} />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
        </div>
        {/* <div
          className="aside-image"
          style={{backgroundImage: "url(" + loginbg + ")"}}
        ></div> */}
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  {props.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props.successMessage}
                    </div>
                  )}
                  <form onSubmit={onLogin} className="form-validate">
                    <h3 className="text-center mb-4 text-black">
                      Sign in your Agency
                    </h3>
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Type Your Email Address"
                        />
                      </div>
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>Password</strong>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        placeholder="Type Your Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {errors.password && (
                        <div className="text-danger fs-12">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="basic_checkbox_1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="basic_checkbox_1"
                          >
                            Remember my preference
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div className="text-center form-group mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {loading && (
                          <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        Sign In
                      </button>
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Agents, please log in here:{" "}
                      <Link className="text-primary" onClick={redirectAgentLoginPage} to="#">
                        Login
                      </Link>
                    </p>
                  </div>
                  <div className="new-account mt-3">
                    <p>
                      Admin portal, please log in here:{" "}
                      <Link className="text-primary" onClick={redirectLoginPage} to="#">
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    ) : (
      <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logoBlack} alt="" style={{ width: "200px" }} />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
        </div>
        {/* <div
        className="aside-image"
        style={{backgroundImage: "url(" + loginbg + ")"}}
      ></div> */}
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  {props.errorMessage && (
                    <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                      {props.errorMessage}
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                      {props.successMessage}
                    </div>
                  )}
                  <h3 className="text-center mb-4 text-black">
                    Sit tight, we are logging you in...
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(LoginAgency);
