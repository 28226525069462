import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { searchAgentsService } from "../../../services/searchAgentsService";
import { searchCarriersService } from "../../../services/searchCarriersService";
import { searchPoliciesService } from "../../../services/searchPoliciesService";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import AsyncSelect from "react-select/async";

const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  form,
  setForm,
  loading,
}) => {
  const [valueAgent, setValueAgent] = useState(null)
  const [valuCarrier, setValuCarrier] = useState(null)
  const [valuePolicies, setValuePolicies] = useState(null)
  const [valueTransactionTypes, setValueTransactionTypes] = useState(null)

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"));

  const transactionTypes = [
    { label: "Select Option", value: "", isDisabled: true },
    { label: "Commission", value: "commission" },
    { label: "Chargeback", value: "chargeback" },
  ];

  const update = () => {
    modalActions(form);
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit transaction" : "Create transaction"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Amount:</label>
          <div className="col-8">
            <input
              type={"number"}
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, amount: e.target.value });
              }}
              value={form.amount ? form.amount : ""}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Transaction type:</label>
          <div className="col-8">
            <Select
              onChange={(e) => {
                setForm({ ...form, transaction_type: e.value })
                setValueTransactionTypes(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueTransactionTypes}
              isSearchable={true}
              name="transaction_type"
              options={transactionTypes}
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Transaction Date:</label>
          <div className="col-8">
            <input
              className="arrow-select"
              value={form.transaction_date ? form.transaction_date.slice(0, 10): ""}
              onChange={(e) => {
                setForm({
                  ...form,
                  transaction_date: e.target.value + " " + "18:26:22.632282+00:00",
                });
              }}
              type="date"
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Agent:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                setForm({ ...form, agent_id: e.value })
                setValueAgent(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueAgent}
              isSearchable={true}
              name="agent_id"
              loadOptions={(inputValue, callback) => searchAgentsService(inputValue, callback)}
              defaultOptions
              cacheOptions
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Carrier:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                setForm({ ...form, carrier_id: e.value })
                setValuCarrier(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valuCarrier}
              isSearchable={true}
              name="carrier_id"
              loadOptions={(inputValue, callback) => searchCarriersService(inputValue, callback)}
              defaultOptions
              cacheOptions
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Policies:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                setForm({ ...form, policy_id: e.value })
                setValuePolicies(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valuePolicies}
              isSearchable={true}
              name="policy_id"
              loadOptions={(inputValue, callback) => searchPoliciesService(inputValue, callback)}
              defaultOptions
              cacheOptions
            />
          </div>
        </div>
        {subAgencyItem && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Sub Agency</label>
            <div className="col-8">
              <select
                defaultValue={subAgencyItem.name}
                className="arrow-select"
                name="sub_agency_id"
              >
                <option disabled value={subAgencyItem.name}>
                  {subAgencyItem.name}
                </option>
              </select>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.carrier_id ||
            !form.agent_id ||
            !form.transaction_type ||
            !form.amount ||
            !form.policy_id
          }
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
