// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartTable {
    margin-right: 0 !important;
    padding: 50px; /* applies padding around the entire table */
    width: 100%;
  }
  
  .chartTable th, .chartTable td {
    padding-left: 10px; /* applies left padding to each cell */
    padding-right: 10px; /* applies right padding to each cell */
  }`, "",{"version":3,"sources":["webpack://./src/jsx/components/Home/home.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa,EAAE,4CAA4C;IAC3D,WAAW;EACb;;EAEA;IACE,kBAAkB,EAAE,sCAAsC;IAC1D,mBAAmB,EAAE,uCAAuC;EAC9D","sourcesContent":[".chartTable {\n    margin-right: 0 !important;\n    padding: 50px; /* applies padding around the entire table */\n    width: 100%;\n  }\n  \n  .chartTable th, .chartTable td {\n    padding-left: 10px; /* applies left padding to each cell */\n    padding-right: 10px; /* applies right padding to each cell */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
