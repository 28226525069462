import React from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalDuplicate = ({
  openModal,
  modalActions,
  closeModal,
  loading,
}) => {
  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Duplicate</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure ?</Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal()}
          disabled={loading}
        />
        <ButtonStyled
          loading={loading}
          text={"Duplicate"}
          onClickFunction={() => modalActions()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDuplicate;
