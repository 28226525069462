import axios from "axios";

import json from "../config.json";
export default function petitionPost(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails")).sub_agency_id;
  var url;
  var data;

  if (token) {
    switch (key) {
      case "agencies":
        url = urlBase + "paylink360/" + api_id + "/agencies";
        data = value;
        break;
      case "agents":
          url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/agents": urlBase + "paylink360/" + api_id + "/agents";  
        data = value;
        break;
      case "carriers":
        url = sub_agency_id ?  urlBase + "paylink360/" + api_id + "/sub-agencies/carriers" : urlBase + "paylink360/" + api_id + "/carriers";
        data = value;
        break;
      case "states":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/states" : urlBase + "paylink360/" + api_id + "/states";
        data = value;
        break;
      case "products":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/products" : urlBase + "paylink360/" + api_id + "/products";
        data = value;
        break;
      case "import-templates-preview":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/import/commissions/preview" : urlBase + "paylink360/" + api_id + "/import/commissions/preview";
        data = value;
        break;
      case "import":
        url =  sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/import/commissions" :  urlBase + "paylink360/" + api_id + "/import/commissions";
        data = value;
        break;
      case "import-templates":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/sub-agencies/import-templates" : urlBase + "paylink360/" + api_id + "/import-templates";
        data = value;
        break;
      case "contracts":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/contracts": urlBase + "paylink360/" + api_id + "/contracts";
        data = value;
        break;
      case "appointments":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/appointments" : urlBase + "paylink360/" + api_id + "/appointments";
        data = value;
        break;
      case "commission-rates":
        url =sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rate-groups/" + value.group_id + "/commission-rates":urlBase + "paylink360/" + api_id + "/commission-rate-groups/" + value.group_id + "/commission-rates";
        data = value.data;
        break;
      case "commission-rate-groups":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rate-groups" : urlBase + "paylink360/" + api_id + "/commission-rate-groups";
        data = value;
        break;
      case "rate-rules":
        url =  sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rates/" + value.rate_group_id + "/rules" : urlBase + "paylink360/" + api_id + "/commission-rates/" + value.rate_group_id + "/rules";
        data = value.data;
        break;
      case "transactions":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/transactions" : urlBase + "paylink360/" + api_id + "/transactions";;
        data = value;
        break;
      case "calculate-payouts":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/calculate-payouts" :  urlBase + "paylink360/" + api_id + "/calculate-payouts";
        data = value;
        break;
      case "create-payouts":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/create-payouts" : urlBase + "paylink360/" + api_id + "/create-payouts";
        data = value;
        break;
      case "policies":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/policies` :  urlBase + `paylink360/${api_id}/policies`;
        data = value;
        break;
      case "overrides":
        url =  sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/overrides` : urlBase + `paylink360/${api_id}/overrides`;
        data = value;
        break;
      case "api-keys":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/api_key` : urlBase + `paylink360/${api_id}/api_key`;
        data = value;
        break;
      case "import-rates-groups":
        url = urlBase + `paylink360/${api_id}/imports/rate-groups`;
        data = value;
        break;
      case "import-products":
        url = urlBase + `paylink360/${api_id}/imports/products`;
        data = value;
        break;
      case "sub-agencies":
        url = urlBase + `paylink360/${api_id}/sub-agencies`;
        data = value;
        break;
      default:
        return "error";
    }
    var config = {
      timeout: 120000,
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.post(url, data, config);
  } else {
    window.location.href = "./profile";
  }
}
