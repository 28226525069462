import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import CustomVerticalTable from "../../../jsx/utils/CustomVerticalTable/CustomVerticalTable";
import Loading from "../../../jsx/utils/Loading";

const ModalSend = ({
  openModal,
  modalActions,
  closeModal,
  info,
  loadingButton,
  loading,
  warningMessage,
  payoutCount,
  filters,
}) => {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (info) {
      setDataTable({
        onlyKey: true,
        key: "agentName",
        content: info,
      });
    }
  }, [info]);

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Send Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {!info || !info?.length ? (
              <>
                {!filters ? (
                  <p>No payouts have been generated</p>
                ) : (
                  <p>No payouts found with filters applied</p>
                )}
              </>
            ) : (
              <>
                {warningMessage && (
                  <p style={{ fontSize: "14px" }} className="text-danger">
                    {warningMessage}
                  </p>
                )}
                <p>
                  You are about to send a total of {payoutCount} emails to the
                  following Agents:
                </p>
                {dataTable && <CustomVerticalTable dataTable={dataTable} />}
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          onClickFunction={() => closeModal()}
          disabled={loadingButton}
        />
        <ButtonStyled
          loading={loadingButton}
          text="Send"
          onClickFunction={() => modalActions()}
          disabled={!info?.length}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSend;
