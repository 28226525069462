import React, { Fragment, useState } from "react";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import petitionPatch from "../../../services/petitionPatch";
import { sendToastSuccess, sendToastError  } from "../../../jsx/utils/toastAlert"

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    setError(false)
  };

  const save = () => {
    if(form.password !== form.confirm_password){
      setError(true)
      return;
    }

    setLoading(true)

    let data = {
      password: form.password,
    }

    petitionPatch("agent-details", { data })
      .then(({ data: result }) => {
        setLoading(false)
        sendToastSuccess()
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        sendToastError()
      })
  }

  return (
    <Fragment>
      <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <label htmlFor="password">Password</label>
        <div
          style={{ width: "50%" }}
          className="input-group transparent-append mb-2"
        >
          <span className="input-group-text">
            {" "}
            <i className="fa fa-lock" />{" "}
          </span>

          <input
            type={`${showPassword ? "text" : "password"}`}
            className={`form-control ${error && "form-control-error"} `}
            name="password"
            onChange={handleChange}
            value={form.password}
          />

          <div
            className="input-group-text show-pass"
            onClick={() => setShowPassword(!showPassword)}
          >
            {" "}
            {showPassword === true ? (
              <i className="fa fa-eye" />
            ) : (
              <i className="fa fa-eye-slash" />
            )}
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="confirm_password">Confirm Password</label>
        <div
          style={{ width: "50%" }}
          className="input-group transparent-append mb-2"
        >
          <span className="input-group-text">
            {" "}
            <i className="fa fa-lock" />{" "}
          </span>

          <input
            type={`${showPassword ? "text" : "password"}`}
            className={`form-control ${error && "form-control-error"} `}
            name="confirm_password"
            onChange={handleChange}
            value={form.confirm_password}
          />

          <div
            className="input-group-text show-pass"
            onClick={() => setShowPassword(!showPassword)}
          >
            {" "}
            {showPassword === true ? (
              <i className="fa fa-eye" />
            ) : (
              <i className="fa fa-eye-slash" />
            )}
          </div>
        </div>
        {error && <p className="text-danger">Passwords do not match</p>}
      </div>
      <div style={{ marginTop: "4rem" }} className="d-flex flex-row-reverse">
        <ButtonStyled
          text="Save"
          onClickFunction={save}
          loading={loading}
        />
      </div>
    </Fragment>
  );
};

export default ChangePassword;
