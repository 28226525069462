import { useEffect, useState, useReducer, Fragment } from "react";
import MenuTabItem from "./MenuTabItem/MenuTabItem";

import "./styles.css";
export default function MenuTabs({ item }) {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => { }, []);

  const activeFunction = (data) => {
    let temporal = item;
    item.forEach((element) => {
      if (element === data) {
        element.active = true;
      } else {
        element.active = false;
      }
    });
    forceUpdate();
  };

  return (
    <div className="menu-tab">
      <div className="menu-tab-left">
        {item.map((element, i) => (
          <>
           {!element.hidden && (
            <>
              <MenuTabItem
                key={i}
                data={element}
                onClick={() => {
                  activeFunction(element);
                }}
              />
            </>
           )}
          </>
        ))}
      </div>
      <div className="menu-tab-right">
        {item.map((element, i) => (
          <Fragment key={i}>
            {element.active && (
              <div className="menu-tab-right-title">{element.title}</div>
            )}
            {element.active && element.component}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
