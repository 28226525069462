import axios from "axios";
import json from "../../config.json"

export default function ForgetPasswordPetition(val) {
  const urlBase = json.prod ? json.forgotPassword.prod : json.forgotPassword.dev;
  const url = urlBase + "paylink360/agents/reset-pw";

  const data = {
    email: val,
  };

  return axios.post(url, data);
}
