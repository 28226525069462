import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const TopTenAgents = ({ categories, people }) => {

  const data = {
    labels: people,
    datasets: categories.map((category) => ({
      label: category.name,
      data: category.value,
      backgroundColor: category.backgroundColor,
      borderColor: category.borderColor,
      borderWidth: 1,
      barThickness: people.length > 1 ? "flex" : 50
    })),
  };



  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            if (value >= 1000000) {
              return value / 1000000 + "M";
            }
            if (value >= 1000) {
              return value / 1000 + "K";
            }
            return value;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default TopTenAgents;
