import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Switch from "react-switch";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../Agency.css";

import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import { useRef } from "react";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  isValidEmail,
  setIsValidEmail,
  validateEmail,
  errorMessage,
  ratesGroups,
}) => {
  const inputRef = useRef(null)

  const [agency, setAgency] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const update = () => {
    modalActions(form);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleChange = (e) => {
    setForm({ ...form, email: e.target.value });
    if (!isValidEmail && e.target.value !== "") {
      setIsValidEmail(validateEmail(e.target.value));
    } else if (!isValidEmail && e.target.value === "") {
      setIsValidEmail(true);
    }
  };

  useEffect(() => {
    if (form.rate_group_id) {
      setAgency({ label: form.rateGroupName, value: form.rate_group_id });
    }
  }, []);

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit Sub Agency" : "Create Sub Agency"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Sub Agency Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              value={form.name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Rate Group:</label>
          <div className="col-8">
            <Select
              onChange={(e) => {
                setForm({ ...form, rate_group_id: e.value });
                setAgency(e);
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={agency}
              isSearchable={true}
              name="agent_id"
              options={ratesGroups}
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Username:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              value={form.email}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Password</label>
          <div className="col-8">
            <div
              className={`container-password-input ${
                inputFocused ? "focused-input" : ""
              }`}
              onClick={handleDivClick}
            >
              <input
                ref={inputRef}
                type={!showPassword ? "password" : "text"}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={form.password}
                className="password-input"
              ></input>

              {!showPassword ? (
                <RemoveRedEyeIcon onClick={toggleShowPassword} className="password-icon" />
              ) : (
                <VisibilityOffIcon onClick={toggleShowPassword} className="password-icon" />
              )}
            </div>
          </div>
        </div>

        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.name ||
            !form.rate_group_id ||
            !form.email ||
            (!form.pk && !form.password)
          }
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
