import axios from "axios";
import swal from "sweetalert";
import {loginConfirmedAction, logout} from "../store/actions/AuthActions";
import jwt_decode from "jwt-decode";
import config from "../config.json";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function loginAgency(email, password, loginAgent) {
  const postData = {
    username: email,
    password: password,
  };
  const url = (config.prod ? config.urlBase.prod : config.urlBase.dev) + "crm/auth/login"

  const urlAgency = (config.prod ? config.urlBase.prod : config.urlBase.dev) + "paylink360/users/auth/login"

  const urlBaseAgent = config.prod ? config.agent.prod : config.agent.dev;
  const urlAgent = urlBaseAgent + "paylink360/login"
  return axios.post(urlAgency, postData);
}

export function login(email, password, loginAgent) {
  const postData = {
    username: email,
    password: password,
  };
  const url = (config.prod ? config.urlBase.prod : config.urlBase.dev) + "crm/auth/login"
  const urlBaseAgent = config.prod ? config.agent.prod : config.agent.dev;
  const urlAgent = urlBaseAgent + "paylink360/login"
  return axios.post(!loginAgent ? url : urlAgent, postData);
}


export function formatError(errorResponse) {
  if (errorResponse.message === "Invalid credentials") {
    swal("Oops", "Invalid credentials", "error");
  }
  // switch (errorResponse.message) {
  //   case "":
  //     swal("Oops", "Email already exists", "error");
  //     break;
  //   case "EMAIL_NOT_FOUND":
  //     swal("Oops", "Email not found", "error", { button: "Try Again!" });
  //     break;
  //   case "INVALID_PASSWORD":
  //     swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
  //     break;
  //   case "USER_DISABLED":
  //     return "User Disabled";

  //   default:
  //     return "";
  // }
}

export function saveTokenInLocalStorage(tokenDetails) {
  let decodedToken = jwt_decode(tokenDetails.access_token);

  decodedToken.expireDate = new Date(new Date().getTime() + decodedToken.exp);
  decodedToken.token = tokenDetails.access_token;
  decodedToken.access_token = tokenDetails.access_token;

  localStorage.setItem(
    "userDetails",
    JSON.stringify({...decodedToken, ...tokenDetails})
  );
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history, false, "33333333333"));
  }, timer);
}

export function checkAutoLogin(dispatch, history, loginAgent) {
  const tokenDetailsString = localStorage.getItem("userDetails");

  let tokenDetails = "";
  if (!tokenDetailsString || loginAgent) {
    dispatch(logout(history, loginAgent, "4444444444444"));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout(history, false, "555555555555"));
    return;
  }

  tokenDetails.access_token = tokenDetails?.access_token;
  tokenDetails = {
    ...tokenDetails,
    ...tokenDetails.user_contact,
    account: tokenDetails.account,
  };
  dispatch(loginConfirmedAction(tokenDetails));
  /* 
  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history); */
}
