// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-label {
  position: relative;
}

.container-label-description {
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  top: -9rem;
  width: 20rem;
  box-shadow: 0px 0px 24px #00000029;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 0.5rem;
}

.container-label:hover > .container-label-description {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Policies/ModalUtility/ModalAdd.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,YAAY;EACZ,kCAAkC;EAClC,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container-label {\n  position: relative;\n}\n\n.container-label-description {\n  position: absolute;\n  visibility: hidden;\n  background-color: #fff;\n  top: -9rem;\n  width: 20rem;\n  box-shadow: 0px 0px 24px #00000029;\n  border: 1px solid rgba(0, 0, 0, 0.4);\n  padding: 0.5rem;\n}\n\n.container-label:hover > .container-label-description {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
