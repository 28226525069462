import React, { useState } from "react";
import MenuTabs from "../../jsx/components/MenuTabs/MenuTabs";
import PageTitle from "../../jsx/layouts/PageTitle";
import Products from "../Products/Products";
import Carries from "../Carries/Carries";
import Rates from "../Rates/Rates";
import GeneralSettings from "../GeneralSettings/GeneralSettings";
import ApiKeys from "../ApiKeys/ApiKeys";
import ApiIcon from "@mui/icons-material/Api";
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import Overrides from "../Overrides/Overrides";

export default function CRMSettings() {
  const isAgencyAccess = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id

  const [item, setItem] = useState([
    {
      icon: (
        <i className="fas fa-cogs" style={{ fontSize: "30px" }}></i>
      ),
      title: "General Settings",
      subtitle: "Add Agency and Logo",
      active: false,
      component: <GeneralSettings />,
    },
    {
      icon: (
        <i className="fas fa-truck" style={{ fontSize: "30px" }}></i>
      ),
      title: "Carriers",
      subtitle: "Edit Carriers",
      active: false,
      component: <Carries />,
      hidden: isAgencyAccess,
    },
    {
      icon: (
        <i className="fas fa-cube" style={{ fontSize: "30px" }}></i>
      ),
      title: "Products",
      subtitle: "Edit Products",
      active: false,
      component: <Products />,
      hidden: isAgencyAccess,
    },
    {
      icon: (
        <i className="fas fa-money-bill" style={{ fontSize: "30px" }}></i>
      ),
      title: "Rates",
      subtitle: "Edit Rate Groups and Rates",
      active: false,
      component: <Rates />,
      hidden: isAgencyAccess,
    },
    {
      icon: <ApiIcon style={{ fontSize: "30px" }} />,
      title: "API Keys",
      subtitle: "Add And Delete API Keys",
      active: false,
      component: <ApiKeys />,
      hidden: isAgencyAccess,
    },
    {
      icon: <AirlineStopsIcon style={{ fontSize: "30px" }} />,
      title: "Overrides",
      subtitle: "Add and Delete Overrides",
      active: false,
      component: <Overrides />,
    },
  ]);
  return (
    <>
      <PageTitle activeMenu="Settings"/>
      <MenuTabs item={item} />
    </>
  );
}
