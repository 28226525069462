import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalFilter from "../../jsx/utils/ModalFilter";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import getValuesInObject from "../../jsx/utils/getValuesInObject";

export default function Agencies() {
  const agentId = JSON?.parse(localStorage?.getItem("userDetails")).agent_id;
  const filterRef = useRef();
  const filterText = useRef();
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [states, setStates] = useState([]);
  const [products, setProducts] = useState([]);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form, formLabels) => {
    setLoadingFilter(true);
    let filtersActive = getValuesInObject(formLabels);
    setFirstLoad(false);
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter =
      getQueryString(paginationTemp) + (filterText.current || "") + filterModal + `&agent_id=${agentId}`;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: [
        {
          title: "Fields",
          inputs: [
            {
              label: "State",
              input: "selectAutoComplete",
              inputValue: form.state_id || {},
              checkbox: true,
              checkboxValue: form.state_id ? true : false,
              checkboxName: "checkbox_state",
              options: states,
              key: "state_id",
            },
            {
              label: "Product",
              input: "selectAutoComplete",
              inputValue: form.product_id || {},
              checkbox: true,
              checkboxValue: form.product_id ? true : false,
              checkboxName: "checkbox_product_id",
              options: products,
              key: "product_id",
            },
            {
              label: "Effective Date on or After",
              input: "date",
              inputValue: form.effective_after || "",
              checkbox: true,
              checkboxValue: form.effective_after ? true : false,
              checkboxName: "checkbox_after",
              key: "effective_after",
            },
            {
              label: "Effective Date on or Before",
              input: "date",
              inputValue: form.effective_before || "",
              checkbox: true,
              checkboxValue: form.effective_before ? true : false,
              checkboxName: "checkbox_before",
              key: "effective_before",
            },
          ],
        },
      ],
    });
    if (!filterText.current) {
      petition(parameter, false, false, false, false, filtersActive);
    } else {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      let value = params.get("first_name");
      petition(parameter, false, true, value, false, filtersActive);
    }

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = filterModal;
  };

  const petition = async (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    paginationTemp,
    filtersToolip
  ) => {
    if (!noLoading) setLoading(true);
    let productsTemp = [];
    let statesTemp = [];

    await petitionGet("states-agent")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        statesTemp = result.result;
        setStates(result.result);
      })
      .catch((error) => {});
    await petitionGet("products-agent")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        productsTemp = result.result;
        setProducts(result.result);
      })
      .catch((error) => {});
    await petitionGet("policies-agent", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = element.created_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.completeName = element.first_name + " " + element.last_name;
          element.stateName = statesTemp.find(
            (e) => e.pk === element.state_id
          ).name;
          element.stateValue = statesTemp.find(
            (e) => e.pk === element.state_id
          ).value;
          // element.agentName = agentsTemp.find(
          //   (e) => e.pk === element.agent_id
          // ).label;
          // element.agentValue = agentsTemp.find(
          //   (e) => e.pk === element.agent_id
          // ).value;
          element.productName =
            productsTemp.find((e) => e.pk === element.product_id)?.label ||
            "Not Exist";
          element.effective_date = element.effective_date.slice(0, 10);
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "NAME", key: "completeName" },
            { title: "PRODUCT", key: "productName" },
            { title: "POLICY NUMBER", key: "policy_number"},
            { title: "PREMIUM AMOUNT", key: "premium_amount" },
            // { title: "AGENT", key: "agentName" },
            { title: "STATE", key: "stateName" },
            { title: "EFFECTIVE DATE", key: "effective_date" },
          ],
          content: [...result.result],
          noColumnNumber: true,
          activeFilters: filtersToolip,
          search: {
            label: "Search by Policy Holders Name, Policy Number",
            filterBackend: true,
            onChange: (e) => filterTextBox(e.target.value),
            defaultValue: valueFilter || null,
          },
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => {
            if (activeFilterTexBox) {
              activeItemsPerPage(e, true, valueFilter);
            } else {
              activeItemsPerPage(e);
            }
          },
          changePage: (page) => {
            if (activeFilterTexBox) {
              changePage(page, true, valueFilter);
            } else {
              changePage(page);
            }
          },
        });
        setLoading(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const changePage = (page, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&policy_number=${valueFilter}&first_name=${valueFilter}${filterRef.current}` + `&agent_id=${agentId}`;
      petition(
        parameter,
        true,
        true,
        valueFilter,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "") + `&agent_id=${agentId}`;
      petition(
        parameter,
        true,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;
    }
  };

  const filterTextBox = (value) => {
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };

    if (value !== "") {
      let parameter = `${getQueryString(
        paginationTemp
      )}&policy_number=${value}&first_name=${value}${filterRef.current || ""}` + `&agent_id=${agentId}`;
      petition(
        parameter,
        true,
        true,
        value,
        paginationTemp,
        objectFilter.current
      );
      pagination.current = paginationTemp;

      if (value) {
        filterText.current = `&policy_number=${value}&first_name=${value}`;
      } else {
        filterText.current = "";
      }
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "") + `&agent_id=${agentId}`;
      petition(
        parameter,
        true,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      filterText.current = "";
      pagination.current = paginationTemp;
    }
  };

  const activeItemsPerPage = (data, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&policy_number=${valueFilter}&first_name=${valueFilter}${filterRef.current}` + `&agent_id=${agentId}`;

      petition(
        parameter,
        false,
        true,
        valueFilter,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "") + `&agent_id=${agentId}`;
      petition(
        parameter,
        false,
        false,
        false,
        paginationTemp,
        objectFilter.current
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    }
  };

  useEffect(() => {
    petition(
      getQueryString(pagination.current) + `&agent_id=${agentId}`,
      false,
      false,
      false,
      pagination.current
    );
  }, []);

  useEffect(() => {
    if (states && products && firstLoad) {
      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => onHidde(),
        parameters: [
          {
            title: "Date",
            inputs: [
              {
                label: "State",
                input: "selectAutoComplete",
                inputValue: {},
                checkbox: true,
                checkboxValue: false,
                checkboxName: "checkbox_state",
                options: states,
                key: "state_id",
              },
              {
                label: "Product",
                input: "selectAutoComplete",
                inputValue: {},
                checkbox: true,
                checkboxValue: false,
                checkboxName: "checkbox_product_id",
                options: products,
                key: "product_id",
              },
              {
                label: "Effective Date on or After",
                input: "date",
                inputValue: "",
                checkbox: true,
                checkboxValue: false,
                checkboxName: "checkbox_after",
                key: "effective_after",
              },
              {
                label: "Effective Date on or Before",
                input: "date",
                inputValue: "",
                checkbox: true,
                checkboxValue: false,
                checkboxName: "checkbox_before",
                key: "effective_before",
              },
            ],
          },
        ],
      });
    }
  }, [states, products, firstLoad]);

  return (
    <>
      <br></br>
      <br></br>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          data={dataTable}
          noClass={true}
          allResult={allResult}
          pagination={itemsPerPage.current}
        />
      )}
    </>
  );
}
