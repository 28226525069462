// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle::after {
  display: none !important;
}

.select-all {
  color: #dc3545;
  text-decoration: none;
}

.select-all:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/jsx/utils/CustomTable/CustomTable.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".dropdown-toggle::after {\n  display: none !important;\n}\n\n.select-all {\n  color: #dc3545;\n  text-decoration: none;\n}\n\n.select-all:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
