import React from "react";
import "./MenuITP.css";

const MenuITP = ({ show, onHidden, children, myRef, style, className }) => {
  return (
    <div
      ref={myRef}
      // className={`position-menu-fixed ${show ? "position-menu-open" : ""}`}
      // className={`${show ? `${className} position-menu-fixed` : ""}`}
      className={show ? "menu-open position-menu-fixed" : ""}
      style={
        style
          ? style
          : {
              backgroundColor: "transparent",
              width: "25%",
            }
      }
    >
      {children}
    </div>
  );
};

export default MenuITP;
