import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import petitionGet from "../../../services/petitionGet";

const ModalAdd = ({
  modal,
  closeModal,
  modalFunction,
  loading,
  agents,
  errorMessage,
  edit,
}) => {
  const [valueAgent, setValueAgent] = useState(
    edit ? { label: edit.agentName, value: edit.agent_id } : null
  );
  const [valueAgentsTemp, setValueAgentsTemp] = useState(
    edit ?edit.agents : null
  );
  const [form, setForm] = useState({
    agent_id: edit ? edit.agent_id : "",
    agentsTemp:edit ? edit.agents : [],
    percentage_amount: edit
      ? `${parseFloat(edit.percentage_amount.replaceAll("%", ""))}`
      : "",
  });

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const handleChange = (e) => {
    const { value } = e.target;
    value.replaceAll(",", ".");
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setForm({ ...form, [e.target.name]: value });
    }
  };

  const loadOptions = (inputValue, callback) => {
    const querySubAgency = subAgencyQuery ? `${inputValue ? "&" : "?"}${subAgencyQuery}` : ""
    const inputQuery = inputValue ? `?query=${inputValue}` : "?offset=0&limit=10"
    const completeParameter = inputQuery + querySubAgency

    petitionGet("agents", { parameter: completeParameter })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.first_name + " " + element.last_name;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>{edit ? "Edit Override" : "Add Override"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label htmlFor="agent_id">Agent:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                setForm({ ...form, agent_id: e.value });
                setValueAgent(e);
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueAgent}
              isSearchable={true}
              name="agent_id"
              defaultOptions
              cacheOptions
              loadOptions={loadOptions}
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label htmlFor="pay_overrides_to">Pay only if agents is/are:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                console.log(e)
                setForm({ ...form, agentsTemp: e });
                setValueAgentsTemp(e);
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueAgentsTemp}
              isSearchable={true}
              name="pay_overrides_to"
              isMulti
              defaultOptions
              cacheOptions
              loadOptions={loadOptions}
            />
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label htmlFor="percentage_amount">Override Amount:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={handleChange}
              value={form.percentage_amount}
              name="percentage_amount"
              type="number"
            />
          </div>
        </div>
        {subAgencyItem && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Sub Agency</label>
            <div className="col-8">
              <select
                defaultValue={subAgencyItem.name}
                className="arrow-select"
                name="sub_agency_id"
              >
                <option disabled value={subAgencyItem.name}>
                  {subAgencyItem.name}
                </option>
              </select>
            </div>
          </div>
        )}
        {errorMessage && <p className="text-danger">{errorMessage}</p>}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          disabled={loading}
          onClickFunction={closeModal}
        />
        <ButtonStyled
          text={!edit ? "Add" : "Edit"}
          loading={loading}
          onClickFunction={() => modalFunction(form)}
          disabled={!form.agent_id || !form.percentage_amount}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
