import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import { searchAgentsService } from "../../services/searchAgentsService";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import getValuesInObject from "../../jsx/utils/getValuesInObject";
import { searchProductService } from "../../services/searchProductService";

export default function Agencies() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const modalFiler = useRef();
  const filterText = useRef("");
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({ offset: 0, limit: 10 });

  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [states, setStates] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);

  const [form, setForm] = useState({
    pk: null,
    name: "",
    created_at: "",
    updated_at: "",
    state_id: null,
    premium_amount: null,
    product_id: null,
    agent_id: null,
  });

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    setLoadingFilter(true);

    const paginationTemp = { offset: 0, limit: pagination.current.limit };
    const filtersActive = getValuesInObject(formLabels);
    const filterModal = getQueryString(form).replaceAll("?", "&");
    const parameter = getQueryString(paginationTemp) + filterText.current + filterModal;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => {},
      parameters: [
        {
          title: "Fields",
          inputs: [
            {
              label: "Agent",
              input: "asyncSelect",
              inputValue: form.agent_id || null,
              checkbox: true,
              checkboxValue: form.agent_id ? true : false,
              checkboxName: "checkbox_agents",
              loadOptions: searchAgentsService,
              key: "agent_id",
            },
            {
              label: "State",
              input: "selectAutoComplete",
              inputValue: form.state_id || {},
              checkbox: true,
              checkboxValue: form.state_id ? true : false,
              checkboxName: "checkbox_state",
              options: states,
              key: "state_id",
            },
            {
              label: "Product",
              input: "asyncSelect",
              inputValue: form.product_id || null,
              checkbox: true,
              checkboxValue: form.product_id ? true : false,
              checkboxName: "checkbox_product_id",
              loadOptions: searchProductService,
              key: "product_id",
            },
            {
              label: "Effective Date on or After",
              input: "date",
              inputValue: form.effective_after || "",
              checkbox: true,
              checkboxValue: form.effective_after ? true : false,
              checkboxName: "checkbox_after",
              key: "effective_after",
            },
            {
              label: "Effective Date on or Before",
              input: "date",
              inputValue: form.effective_before || "",
              checkbox: true,
              checkboxValue: form.effective_before ? true : false,
              checkboxName: "checkbox_before",
              key: "effective_before",
            },
          ],
        },
      ],
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          policy_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    }

    if (!filterText.current) {
      await petition(parameter, false, false, filtersActive);
    } else {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      let value = params.get("first_name");
      await petition(parameter, false, value, filtersActive);
    }

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    modalFiler.current = filterModal;
  };

  const activeEditModal = (e) => {
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    const paginationTemp = {
      offset: !deleted ? pagination.current.offset : pagination.current.offset - pagination.current.limit,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) + filterText.current
    const textBoxValue = filterText.current ? filterText.current.split("=")[2] : false

    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    resetForm();
    petition(parameter, false, textBoxValue, objectFilter.current);
  };

  const petition = async (parameter, noLoading, valueFilter, filtersToolip) => {
    if (!noLoading) setLoading(true);
    let statesTemp = [];

    const completeParameter = `${parameter || ""}${ subAgencyQuery ? parameter ? `&${subAgencyQuery}` : `?${subAgencyQuery}` : ""}`;

    await petitionGet("states", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        statesTemp = result.result;
        setStates(result.result);
      })
      .catch((error) => {});

    await petitionGet("policies", { id, parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);

        result.result.forEach((element, i) => {
          element.completeName = element.first_name + " " + element.last_name;
          element.stateName = statesTemp.find((e) => e.pk === element.state_id).name;
          element.stateValue = statesTemp.find((e) => e.pk === element.state_id).value;
          element.agentName = element?.agent?.first_name + " " + element?.agent?.last_name
          element.agentValue = element?.agent?.pk
          element.productName = element?.product?.name || "Not Exist";
          element.effective_date = element.effective_date.slice(0, 10);
        });

        setDataTable({
          title: [
            { title: "NAME", key: "completeName" },
            { title: "PRODUCT", key: "productName" },
            { title: "POLICY NUMBER", key: "policy_number" },
            { title: "PREMIUM AMOUNT", key: "premium_amount" },
            { title: "AGENT", key: "agentName" },
            { title: "STATE", key: "stateName" },
            { title: "EFFECTIVE DATE", key: "effective_date" },
          ],
          content: [...result.result],
          actions: sub_agency_id ? null : {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: (e) => {
                  activeEditModal(e);
                },
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          search: {
            label: "Search by Policy Holders Name, Policy Number",
            filterBackend: true,
            onChange: (e) => filterTextBox(e.target.value),
            defaultValue: valueFilter || null,
          },
          addButton: sub_agency_id ? null : {
            label: "Add Policies",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: activeItemsPerPage,
          changePage: changePage,
        });

        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    setLoadingModal(true);
    let data = {
      policy_number: form.policy_number,
      premium_amount: form.premium_amount,
      date_of_birth: form.date_of_birth,
      agent_id: parseInt(form.agent_id),
      product_id: form.product_id,
      state_id: form.state_id,
      first_name: form.first_name,
      last_name: form.last_name,
      effective_date: form.effective_date,
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    if (info?.pk) {
      data.paid_once = form.paid_once
    }

    if (info?.pk) {
      petitionPatch("policies", {
        policies_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("policies", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("policies", info.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      created_at: "",
      updated_at: "",
    });
  };

  const changePage = (page) => {
    const paginationTemp = {
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
      limit: pagination.current.limit,
    };

    let value = ""

    if (filterText.current) {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      value = params.get("first_name");
    }

    const parameter = getQueryString(paginationTemp) + filterText.current + modalFiler.current
    petition(parameter, true, value, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const filterTextBox = (value) => {
    const paginationTemp = {
      offset: 0,
      limit: parseInt(itemsPerPage.current),
    };

    const searchFilter = value ? `&policy_number=${value}&first_name=${value}` : ""
    const parameter = getQueryString(paginationTemp) + searchFilter + modalFiler.current
    petition(parameter, true, value, objectFilter.current);
    pagination.current = paginationTemp;
    filterText.current = searchFilter;
  };

  const activeItemsPerPage = (data) => {
    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let value = ""

    if (filterText.current) {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      value = params.get("first_name");
    }

    const parameter = getQueryString(paginationTemp) + filterText.current + modalFiler.current
    petition(parameter, true, value, objectFilter.current);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        const filters = result?.result?.paylink_filters?.policy_filters;
        accounFilters.current = result?.result?.paylink_filters || {};

        if (filters) {
          const form = filters;
          const formLabels = form.formLabels;
          delete form.formLabels;

          onSaveFunction(form, formLabels, true);
        } else {
          petition(getQueryString(pagination.current));
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (states) {
      setDataFilterModal({
        onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
        onHidde: () => {},
        parameters: [
          {
            title: "Date",
            inputs: [
              {
                label: "Agent",
                inputValue: !dataFilterModal ? null : dataFilterModal.parameters[0].inputs[0].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal ? false  : dataFilterModal.parameters[0].inputs[0].checkboxValue,
                checkboxName: "checkbox_agents",
                key: "agent_id",
                input: "asyncSelect",
                loadOptions: searchAgentsService,
              },
              {
                label: "State",
                input: "selectAutoComplete",
                inputValue: !dataFilterModal
                  ? null
                  : dataFilterModal.parameters[0].inputs[1].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[1].checkboxValue,
                checkboxName: "checkbox_state",
                options: states,
                key: "state_id",
              },
              {
                label: "Product",
                inputValue: !dataFilterModal ? null : dataFilterModal.parameters[0].inputs[2].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal ? false : dataFilterModal.parameters[0].inputs[2].checkboxValue,
                checkboxName: "checkbox_product_id",
                key: "product_id",
                input: "asyncSelect",
                loadOptions: searchProductService,
              },
              {
                label: "Effective Date on or After",
                input: "date",
                inputValue: !dataFilterModal
                  ? ""
                  : dataFilterModal.parameters[0].inputs[3].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[3].checkboxValue,
                checkboxName: "checkbox_after",
                key: "effective_after",
              },
              {
                label: "Effective Date on or Before",
                input: "date",
                inputValue: !dataFilterModal
                  ? ""
                  : dataFilterModal.parameters[0].inputs[4].inputValue,
                checkbox: true,
                checkboxValue: !dataFilterModal
                  ? false
                  : dataFilterModal.parameters[0].inputs[4].checkboxValue,
                checkboxName: "checkbox_before",
                key: "effective_before",
              },
            ],
          },
        ],
      });
    }
  }, [states]);

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      <br></br>
      <br></br>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
          }}
          form={form}
          setForm={setForm}
          states={states}
          agents={[]}
          products={[]}
          allStates={states}
          loading={loadingModal}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
          }}
          loading={loadingModal}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              pagination={itemsPerPage.current}
            />
          )}
        </>
      )}
    </>
  );
}
