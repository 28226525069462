/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { searchAgentsService } from "../../services/searchAgentsService";
import { searchCarriersService } from "../../services/searchCarriersService";
import { searchPoliciesService } from "../../services/searchPoliciesService";
import axios from "axios";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import getValuesInObject from "../../jsx/utils/getValuesInObject";
import Loading from "../../jsx/utils/Loading";
import ModalDetails from "./ModalUtility/ModalDetails";
import BulkDelete from "./ModalUtility/BulkDelete";
import json from "../../config.json"

export default function Agencies() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const objectFilter = useRef({});
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({ offset: 0, limit: 10 });
  const modalFilter = useRef("")

  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectAll, setSelectAll] = useState(false)
  const [bulkDelete, setBulkDelete] = useState(false)

  const [form, setForm] = useState({
    pk: null,
    name: "",
    created_at: "",
    updated_at: "",
  });

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const transactionTypes = [
    { label: "commission", value: "commission" },
    { label: "payout", value: "payout" },
    { label: "chargeback", value: "chargeback" },
  ];

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    const filtersActive = getValuesInObject(formLabels);
    setLoadingFilter(true);

    const paginationTemp = {
      offset: 0,
      limit: pagination.current.limit,
    };

    const filterModal = getQueryString(form).replaceAll("?", "&");
    const parameter = getQueryString(paginationTemp) + filterModal;
    modalFilter.current = filterModal

    let parameters = [
      {
        title: "Date",
        inputs: [
          {
            label: "Premium",
            input: "text",
            inputValue: form.commissionable_amount || "",
            checkbox: true,
            checkboxValue: form.commissionable_amount ? true : false,
            checkboxName: "checkbox_commissionable_amount",
            key: "commissionable_amount",
          },
          {
            label: "Amount",
            input: "text",
            inputValue: form.amount || "",
            checkbox: true,
            checkboxValue: form.amount ? true : false,
            checkboxName: "checkbox_amount",
            key: "amount",
          },
          {
            label: "Carrier",
            input: "asyncSelect",
            inputValue: form.carrier_id || null,
            checkbox: true,
            checkboxValue: form.carrier_id ? true : false,
            checkboxName: "checkbox_carrier",
            loadOptions: searchCarriersService,
            key: "carrier_id",
          },
          {
            label: "Agent",
            input: "asyncSelect",
            inputValue: form.agent_id || null,
            checkbox: true,
            checkboxValue: form.agent_id ? true : false,
            checkboxName: "checkbox_agents",
            loadOptions: searchAgentsService,
            key: "agent_id",
          },
          {
            label: "Policie",
            input: "asyncSelect",
            inputValue: form.policy_id || null,
            checkbox: true,
            checkboxValue: form.policy_id ? true : false,
            checkboxName: "checkbox_policy",
            loadOptions: searchPoliciesService,
            key: "policy_id",
          },
          {
            label: "Transaction type",
            input: "selectAutoComplete",
            inputValue: form.transaction_type || {},
            checkbox: true,
            checkboxValue: form.transaction_type ? true : false,
            checkboxName: "checkbox_transaction_type",
            options: transactionTypes,
            key: "transaction_type",
          },
          {
            label: "Transaction Date on or After",
            input: "date",
            inputValue: form.transaction_after || "",
            checkbox: true,
            checkboxValue: form.transaction_after ? true : false,
            checkboxName: "checkbox_after",
            key: "transaction_after",
          },
          {
            label: "Transaction Date or Before",
            input: "date",
            inputValue: form.transaction_before || "",
            checkbox: true,
            checkboxValue: form.transaction_before ? true : false,
            checkboxName: "checkbox_before",
            key: "transaction_before",
          },
        ],
      },
    ];

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => onHidde(),
      parameters: parameters,
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          transaction_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    }

    await petition(parameter, false, filtersActive);

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    setFilterModal(false);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    setBulkDelete(false)
    resetForm();

    const paginationTemp = {
      offset: !deleted ? pagination.current.offset : pagination.current.offset - pagination.current.limit,
      limit: pagination.current.limit,
    }
    
    const parameter =  getQueryString(paginationTemp) + modalFilter.current;
    petition(parameter, false, objectFilter.current);
    if (deleted) pagination.current = paginationTemp;
  };

  const activeModalDetails = (e) => {
    setInfo(e);
    setModalDetails(true);
  };

  const addData = () => {
    setLoadingModal(true);
    let data = {
      agent_id: form.agent_id,
      policy_id: form.policy_id,
      transaction_type: form.transaction_type,
      amount: form.amount,
      carrier_id: form.carrier_id,
      transaction_date: form.transaction_date,
    };

    if (subAgencyItem) {
      data.sub_agency_id = subAgencyItem.pk
    }

    if (info) {
      petitionPatch("transactions", {
        transaction_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("transactions", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("transactions", info?.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.error);
          setLoadingModal(false);
        } else {
          console.log(error);
        }
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      created_at: "",
      updated_at: "",
    });
  };

  const changePage = (page) => {
    const paginationTemp = {
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) +  modalFilter.current;
    petition(parameter, true, objectFilter.current);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    const parameter = getQueryString(paginationTemp) + modalFilter.current
    petition(parameter, false, objectFilter.current);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const bulkDeleteTransactions = async () => {
    setLoadingModal(true)

    try {

      const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
      const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
      const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  
      let data = {}
  
      if (selectAll) {
        data = {}
      } else {
        data = {
          transactions: selectedTransactions,
        };
      }
  
      const headers = {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      };

      const filterModalQuery = modalFilter.current
      const query = `${subAgencyQuery ? `?${subAgencyQuery}` : ""}${filterModalQuery ? subAgencyQuery ? filterModalQuery : `?${filterModalQuery.slice(1)}` : ""}${selectAll && !filterModalQuery ? `${(subAgencyQuery || filterModalQuery) ? "&" : "?"}delete_all=true`: ""}`
      const url = `${urlBase}paylink360/${api_id}/bulk/transactions${query}`;
  
      await axios.delete(url, { headers, data })

      const newPaginationValue = {
        offset: 0,
        limit: 10,
      }

      const parameter = getQueryString(newPaginationValue) + filterModalQuery

      setLoadingModal(false)
      setBulkDelete(false)
      setSelectAll(false)
      setSelectedTransactions([])

      petition(parameter, false, objectFilter.current);
      pagination.current = newPaginationValue
    } catch (error) {
      console.log(error)
    }
  }

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        const filters = result?.result?.paylink_filters?.transaction_filters;
        accounFilters.current = result?.result?.paylink_filters || {};

        if (filters) {
          const form = filters;
          const formLabels = form.formLabels;
          delete form.formLabels;

          onSaveFunction(form, formLabels, true);
        } else {
          let parameters = [
            {
              title: "Date",
              inputs: [
                {
                  label: "Premium",
                  input: "text",
                  inputValue: "",
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_commissionable_amount",
                  key: "commissionable_amount",
                },
                {
                  label: "Amount",
                  input: "text",
                  inputValue: "",
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_amount",
                  key: "amount",
                },
                {
                  label: "Carrier",
                  input: "asyncSelect",
                  inputValue: null,
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_carrier",
                  loadOptions: searchCarriersService,
                  key: "carrier_id",
                },
                {
                  label: "Agent",
                  input: "asyncSelect",
                  inputValue: null,
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_agents",
                  loadOptions: searchAgentsService,
                  key: "agent_id",
                },
                {
                  label: "Policie",
                  input: "asyncSelect",
                  inputValue: null,
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_policy",
                  loadOptions: searchPoliciesService,
                  key: "policy_id",
                },
                {
                  label: "Transaction type",
                  input: "selectAutoComplete",
                  inputValue: {},
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_transaction_type",
                  options: transactionTypes,
                  key: "transaction_type",
                },
                {
                  label: "Transaction Date on or After",
                  input: "date",
                  inputValue: "",
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_after",
                  key: "transaction_after",
                },
                {
                  label: "Transaction Date or Before",
                  input: "date",
                  inputValue: "",
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_before",
                  key: "transaction_before",
                },
              ],
            },
          ];
      
          setDataFilterModal({
            onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
            onHidde: () => onHidde(),
            parameters: parameters,
          });
          petition(getQueryString(pagination.current), false);
        }
      })
      .catch((error) => console.log(error));
  };

  const petition = async (parameter, noLoading, filtersToolip) => {
    if (!noLoading) setLoading(true);

    const completeParameter = `${parameter || ""}${subAgencyQuery ? parameter ? `&${subAgencyQuery}` : `?${subAgencyQuery}` : ""}`;

    await petitionGet("transactions", { id, parameter: completeParameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.created_at = element.created_at.substring(0, 15).replaceAll("-", "/");
          element.updated_at = element.updated_at.substring(0, 15).replaceAll("-", "/");
          element.policeNumber = element?.policy?.policy_number;
          element.carrierName = element?.carrier?.name;
          element.agentName = `${element?.agent?.first_name || ""} ${element?.agent?.last_name || ""}`;
          element.agency_commission = element?.agency_commission || 0;
          element.amount = element?.amount || 0;
          element.commissionable_amount = element?.commissionable_amount || 0;
          element.sub_agency_commission = element?.sub_agency_commission || 0;
          element.transaction_date = !element?.transaction_date ? "" : element?.transaction_date.slice(0, 10).replaceAll("-", "/");
        });

        setDataTable({
          title: [
            { title: "TRANSACTION DATE", key: "transaction_date" },
            { title: "CARRIER", key: "carrierName" },
            { title: "AGENT", key: "agentName" },
            { title: "POLICY NUMBER", key: "policeNumber", link: true },
            { title: "TRANSACTION TYPE", key: "transaction_type" },
            { title: "COMMISSION AGENT", key: "amount" },
            { title: "PREMIUM", key: "commissionable_amount" },
            { title: "AGENCY COMMISSION", key: "agency_commission" },
            { title: "SUB AGENCY COMMISSION", key: "sub_agency_commission" },
          ],
          content: [...result.result],
          actions: sub_agency_id ? null : {
            title: "Actions",
            content: [
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          clickItem: activeModalDetails,
          noColumnNumber: true,
          activeFilters: filtersToolip,
          addButton: sub_agency_id ? null : {
            label: "Add Transaction",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: activeItemsPerPage,
          changePage: changePage,
        });
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      <br></br>
      <br></br>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
          }}
          form={form}
          setForm={setForm}
          loading={loadingModal}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
            setErrorMessage(null);
            setInfo(null);
          }}
          loading={loadingModal}
          info={info}
          errorMessage={errorMessage}
        />
      )}

      {modalDetails && (
        <ModalDetails
          modal={modalDetails}
          info={info}
          closeModal={() => {
            setInfo(null);
            setModalDetails(false);
          }}
        />
      )}

      {bulkDelete && (
        <BulkDelete 
          openModal={bulkDelete}
          modalActions={bulkDeleteTransactions}
          closeModal={() => setBulkDelete(false)}
          noShowActionButton={!selectedTransactions.length}
          loading={loadingModal}
          selectedTransactions={selectAll ? allResult.item_count : selectedTransactions.length}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              styleCardTable={{ overflow: "auto" }}
              pagination={itemsPerPage.current}
              columnCheckboxs={{
                name: "Actions: ",
                actions: [
                  {
                    name: "Delete Transactions",
                    icon: <DeleteForeverIcon />,
                    handleClick: () =>  setBulkDelete(true),
                  }
                ]
              }}
              selectedRows={selectedTransactions}
              setSelectedRows={setSelectedTransactions}
              selectAllRows={selectAll}
              setSelectAllRows={setSelectAll}
              module="Transaction(s)"
            />
          )}
        </>
      )}
    </>
  );
}
