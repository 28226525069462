import React from "react";
import "./BulkActions.css";

const BulkActions = ({ name, actions }) => (
  <div className="itp-container-bulk-actions">
    <div className="itp-bulk-actions">
      <label className="itp-label-bulk-actions">{name}</label>
      {actions.map((element, i) => (
        <div
          className={`itp-icon-bulk-action ${
            i === actions.length - 1 ? "itp-last-icon-bulk-actions" : ""
          }`}
          key={i}
          onClick={() => element.handleClick()}
        >
          <div className="icons-hover itp-icon-hover-background">
            {element.icon}
          </div>
          <div className="itp-bulk-icon-hover">
            <span className="itp-bulk-icon-name">{element.name}</span>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default BulkActions;
