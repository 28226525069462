import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { logout } from "../../store/actions/AuthActions";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  checkAutoLogin,
  saveTokenInLocalStorage,
} from "../../services/AuthService";
import { ThemeContext } from "../../context/ThemeContext";
import NavAgent from "./NavAgent";
import Footer from "../layouts/Footer";
import ScrollToTop from "../layouts/ScrollToTop";
import petitionGet from "../../services/petitionGet";
import Registration from "../pages/Registration";
import Login from "../pages/Login";
import LoginAgency from "../pages/LoginAgency";
import ForgotPassword from "../pages/ForgotPassword";
import LockScreen from "../pages/LockScreen";
import Error400 from "../pages/Error400";
import Error401 from "../pages/Error401";
import Error403 from "../pages/Error403";
import Error404 from "../pages/Error404";
import Error500 from "../pages/Error500";
import Error503 from "../pages/Error503";
import PayoutHistory from "../../PagesAgents/PayoutHistory/PayoutHistory";
import Policies from "../../PagesAgents/Policies/Policies";
import Profile from "../../PagesAgents/Profile/Profile";
import UndefinedPath from "./undefinedPath";
import "../index.css";
import "../chart.css";
import "../step.css";

const IndexAgent = (props) => {
  const dispatch = useDispatch();
  const locStorage = JSON.parse(localStorage.getItem("userDetails"));

  const onLogout = () => {
    dispatch(logout(props.history));
  };

  petitionGet("authLogoutAgent")
    .then(({ data: result }) => {})
    .catch((error) => {
      console.log(error);
      onLogout();
    });

  useEffect(() => {
    const autoRefresh = () => {
      const token = JSON.parse(
        localStorage.getItem("userDetails")
      ).access_token;

      petitionGet("refresh-agent", token)
        .then(({ data: result }) => {
          let dataLocalStorage = JSON.parse(
            localStorage.getItem("userDetails")
          );
          result.result.api_id = dataLocalStorage.api_id;
          result.result.loginAgent = dataLocalStorage?.loginAgent || true;
          result.result.agent_id = dataLocalStorage?.agent_id;
          result.result.firstname = result.result?.first_name;
          result.result.lastname = result.result?.last_name;
          delete result.result.pk
          saveTokenInLocalStorage(result.result);
        })
        .catch((error) => {
          onLogout();
          console.log(error);
        });
    };

    setInterval(autoRefresh, 60000);
  }, []);

  if (window.location.pathname === "/login-agent" && !locStorage.loginAgent) {
    checkAutoLogin(dispatch, props.history, true);
  }

  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    { url: "", component: Policies },
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-login-agency", component: LoginAgency },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-401", component: Error401 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "payout-history", component: PayoutHistory },
    { url: "policies", component: Policies },
    { url: "profile", component: Profile },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <NavAgent />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              <Route component={UndefinedPath} />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(IndexAgent));
