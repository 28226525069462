import React, { useEffect } from "react";
import CustomTable from "../../../jsx/utils/CustomTable/CustomTable";
import Loading from "../../../jsx/utils/Loading";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import ModalAddCommisionRate from "../ModalUtility/ModalAddCommisionRate";
import ModalDelete from "../ModalUtility/ModalDelete";
import CommissionRateRules from "./CommissionRateRules";
import "../styles.css";

const CommissionRate = ({
  details,
  backFunction,
  products,
  states,
  loading,
  dataTable,
  modalAdd,
  loadingAdd,
  modalDelete,
  info,
  loadingDelete,
  rateRules,
  petition,
  back,
  addCommisionRate,
  deleteCommisionRate,
  closeModal,
  allResult,
  getQueryString,
  pagination,
  itemsPerPage,
}) => {
  useEffect(() => {
    let paginationTemp = {
      offset: pagination?.offset,
      limit: pagination?.limit,
    };
    petition(getQueryString(paginationTemp), paginationTemp);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalAdd && (
        <ModalAddCommisionRate
          modal={modalAdd}
          closeModal={closeModal}
          addFunction={addCommisionRate}
          products={products}
          info={details}
          edit={info}
          loading={loadingAdd}
          states={states}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          closeModal={closeModal}
          modalActions={deleteCommisionRate}
          loading={loadingDelete}
        />
      )}

      {rateRules ? (
        <CommissionRateRules
          back={back}
          rateRules={rateRules}
          details={details}
        />
      ) : (
        <>
          <div
            style={{ gap: "10px", marginLeft: "24px", marginTop: "10px" }}
            className="d-flex align-items-center"
          >
            <ButtonStyled text="Back" onClickFunction={backFunction} />
            <h4>{details.name}</h4>
          </div>

          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              text="Rates"
              allResult={allResult}
              noOverflow={true}
              pagination={itemsPerPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default CommissionRate;
