// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-select__single-value {
  position: relative;
  right: 0;
  left: 0;
  top: 0.7rem !important;
}

.my-select__value-container {
  height: 3rem;
}

.my-select__input-container.css-1smwn34-Input{
  color: black !important;
}
`, "",{"version":3,"sources":["webpack://./src/jsx/utils/ModalFilter.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".my-select__single-value {\n  position: relative;\n  right: 0;\n  left: 0;\n  top: 0.7rem !important;\n}\n\n.my-select__value-container {\n  height: 3rem;\n}\n\n.my-select__input-container.css-1smwn34-Input{\n  color: black !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
