import React from "react";
import { Modal, Table } from "react-bootstrap";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  fields,
}) => {
  const update = () => {
    modalActions(form);
  };

  const putValue = (element) => {
    let val = "";
    form.field_mappings?.map((el, i) => {
      if (el.model_field === element) {
        val = el.csv_field;
      }
    });
    return val;
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>
          {form.pk ? "Edit Template" : "Create Template"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              value={form.name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Description:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, description: e.target.value });
              }}
              value={form.description}
            ></input>
          </div>
        </div>
        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>FIELD NAME</th>
              <th>CSV FIELD NAME</th>
            </tr>
          </thead>
          <tbody>
            {fields?.map((element, i) => (
              <tr key={i}>
                <td>{i}</td>
                <td>{element}</td>
                <td>
                  {" "}
                  <input
                    className="form-control"
                    onChange={(e) => {
                      let elementField = form.field_mappings.find((el)=> el.model_field === element)
                      if (elementField) {
                        form.field_mappings.forEach((el, i) => {
                          if (el.model_field === element) {
                            let field_mappings = form.field_mappings;
                            field_mappings[i].csv_field = e.target.value;
                            setForm({
                              ...form,
                              field_mappings,
                            });
                          }
                        });
                      } else {
                        let field_mappings = form.field_mappings;
                        field_mappings.push({
                          csv_field: e.target.value,
                          model_field: element,
                        });

                        setForm({
                          ...form,
                          field_mappings,
                        });
                      }
                    }}
                    value={putValue(element)}
                    placeholder="CSV Name Field"
                  ></input>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={!form.name}
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() =>
            update()
          }
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
