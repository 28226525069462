import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import { sendToastError, sendToastSuccess } from "../../jsx/utils/toastAlert";
import Stepper from "../../components/Stepper/Stepper";
import ButtonStyled from "../../jsx/utils/ButtonStyled";
import petitionGet from "../../services/petitionGet";
import "./styles.css";

const ProcessPayouts = () => {
  const failedImports = []
  const [step, setStep] = useState(0);
  const [errorModal, setErrorModal] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [sendEmail, setSendEmail] = useState("?send_email=true")

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const ErrorModal = ({ isOpen, toggle, message, failedImports }) => {
    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Error</ModalHeader>
        <ModalBody>
        {message?.error_message != null && message.error_message != '' ? <p>{message.error_message}</p> : null}

        {message?.error_detailed != null && message.error_detailed != '' ? <p>{message.error_detailed}</p> : null}
          {failedImports && failedImports.length > 0 && (
            <>
              <p>
                The Records below were unable to be imported. No records will be
                imported from this file until all errors are resolved.
              </p>
              <div style={{ maxHeight: "400px", overflow: "auto" }}>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {Object.keys(failedImports[0]).map((key, index) => (
                        <th key={index}>{formatColumnName(key)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {failedImports.map((failedRow, index) => (
                      <tr key={index}>
                        {Object.values(failedRow).map((value, index) => (
                          <td key={index}>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  };

  const formatColumnName = (columnName) => {
    return columnName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const onChangeCheck = () => {
    if(sendEmail === "?send_email=false"){
      setSendEmail("?send_email=true")
    } else {
      setSendEmail("?send_email=false")
    }
  }

  const stepActions = () => {
    setStep(step + 1);
  };

  const createPayouts = () => {
    setLoadingCreate(true);

    const parameter = `${sendEmail}${subAgencyQuery ? `&${subAgencyQuery}` : ""}`

    petitionGet("create-payouts", { parameter })
      .then(({ data: result }) => {
        setLoadingCreate(false);
        sendToastSuccess();
        stepActions();
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          let errorText =
            "An Error in the server has occurred, please try again.";
          sendToastError(errorText);
          setLoadingCreate(false);
        } else if (error.response && error.response.status === 400) {
          let errorText = error.response.data.error;
          sendToastError(errorText);
          setLoadingCreate(false);
        } else {
          console.log(error);
        }
      });
  };

  return (
    <Fragment>
      {errorModal && (
        <ErrorModal
          isOpen={errorModal}
          toggle={() => setErrorModal(false)}
          message=""
          failedImports={failedImports}
        />
      )}
      <Stepper
        steps={[{ label: "Generate Payouts" }, { label: "Confirm Payouts" }]}
        activeStep={step}
        style={{
          justifyContent: "center",
          width: "50%",
          position: "relative",
          left: "25%",
        }}
      />
      <div style={{ height: "100vh" }} className="row">
        <div className="col-xl-12 col-xxl-12">
          <TabContent activeTab={step.toString()}>
            <TabPane tabId="0" className="p-3">
              <></>
            </TabPane>
            <TabPane tabId="1" className="p-3">
              <div className="d-flex justify-content-center align-items-center">
                <p style={{ textAlign: "center", fontSize: "13px" }}>
                  Payouts will now be generated. This process can take a few
                  minutes to process, you can monitor the status on the{" "}
                  <Link to="payout-history">Payout History</Link> page
                </p>
              </div>
            </TabPane>
          </TabContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {step === 0 && (
              <div className="d-flex flex-column">
                <div className="form-check custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheckBox1"
                    onChange={onChangeCheck}
                    checked={sendEmail === "?send_email=true" ? true : false}
                    required
                  />
                  <label className="form-check-label" htmlFor="customCheckBox1">
                    Email Payouts to Agents
                  </label>
                </div>
                <ButtonStyled
                  text="Generate Payouts"
                  onClickFunction={() => {
                    createPayouts();
                  }}
                  loading={loadingCreate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProcessPayouts;
