import { useState } from 'react';

export default function UserAvatar({ firstname, lastname }) {
  const [backgroundColor, setBackgroundColor] = useState('#efbae6');
  const [fontColor, setFontColor] = useState('#d653c1');

  return (
    <div
      className="rounded-circle"
      style={{
        backgroundColor: `${backgroundColor}`,
        width: '50px',
        height: '50px',
        alignItems: 'center',
        alignSelf: 'start',
        display: 'flex',
        fontSize: '1.25rem',
        fontWeight: '700',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <span
        className="w-100"
        style={{ color: `${fontColor}` }}
      >
        {firstname.toUpperCase().charAt(0) + lastname.toUpperCase().charAt(0)}
      </span>
    </div>
  );
}
