import { useState } from "react";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import ConfirmModal from "./modals/ConfirmModal";

export default function Step5() {
  const [modalConfirm, setModalConfirm] = useState(false);

  const confirm = ()=>{
    setModalConfirm(true)
  }

  return (
    <>
      <ConfirmModal openModal={modalConfirm}/>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ButtonStyled
          text="Confirm Payouts"
          onClickFunction={() => {
            confirm();
          }}
        />
      </div>
    </>
  );
}
