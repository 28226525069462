import React from "react";
import { Pie } from "react-chartjs-2";

const PremiumsProcessed = ({ labels, value }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        data: value,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PremiumsProcessed;
