export default function getDate(date, option) {
  let year = date.split(" ")[0].split("-")[0];
  let month = date.split(" ")[0].split("-")[1];
  let day = date.split(" ")[0].split("-")[2];

  let completedDate = "";

  if (!option || option === 1) {
    completedDate = day + "/" + month + "/" + year;
  }

  if (option === 2) {
    completedDate = year + "-" + month + "-" + day;
  }

  return completedDate;
}
