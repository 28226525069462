import { useEffect, useState } from "react";
import petitionGet from "../../services/petitionGet";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionPatch from "../../services/petitionPatch";

export default function Agents() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingAddLead, setLoadingAddLead] = useState(false);
  const [loadingDeleteLead, setLoadingDeleteLead] = useState(false);

  const [form, setForm] = useState({
    pk: null,
    agency_id: null,
    first_name: "",
    last_name: "",
    email: "",
    created_at: "",
    updated_at: "",
    receive_upfront_commission: true,
  });

  useEffect(() => {
    petition();
  }, []);

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form) => {
    console.log(form);
    setFilterModal(false);
  };

  const dataFilterModal = {
    onSaveFunction: (form) => onSaveFunction(form),
    onHidde: () => onHidde(),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Agent Created On or After",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_after",
            key: "contact_created_on_or_after",
          },
          {
            label: "Agent Created On or Before",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_before",
            key: "contact_created_on_or_before",
          },
        ],
      },
      {
        inputs: [
          {
            label: "Agency",
            input: "select",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_custom_field_list",
            options: agencies,
            key: "name_of_custom_field_list",
          },
        ],
      },
    ],
  };

  const activeEditModal = (e) => {
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = () => {
    setModalAdd(false);
    setInfo(null);
    resetForm();
    petition();
  };

  const petition = () => {
    setLoading(true);
    petitionGet("carriers", { id })
      .then(({ data: result }) => {
        console.log(result.result);
        let carriersRes = result.result;
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        setAgencies(result.result);
        petitionGet("states", { id })
          .then(({ data: result }) => {
            console.log(result.result);
            result.result.forEach((element) => {
              element.created_at = element.created_at
                .substring(0, 15)
                .replaceAll("-", "/");
              element.updated_at = element.updated_at
                .substring(0, 15)
                .replaceAll("-", "/");
              element.carrier = carriersRes.find(
                (e) => e.pk === element.carrier_id
              ).name;
            });
            setDataTable({
              title: [
                { title: "STATE NAME", key: "name" },
                { title: "CARRIER", key: "carrier" },
                { title: "CREATED ON", key: "created_at" },
                { title: "UPDATED ON", key: "updated_at" },
              ],
              content: [...result.result],
              actions: {
                title: "Actions",
                content: [
                  {
                    name: "Edit",
                    icon: <EditIcon />,
                    handleClick: activeEditModal,
                  },
                  {
                    name: "Delete",
                    icon: <DeleteIcon />,
                    handleClick: activeDeleteModal,
                  },
                ],
              },
              search: {
                label: "Search Agent",
              },
              addButton: {
                label: "Add agency",
                handleClick: () => {
                  setModalAdd(true);
                },
              },
              showMorefilters: () => setFilterModal(true),
            });
            setLoading(false);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    console.log(form);
    let dataSend = {
      agency_id: form.agency_id,
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      receive_upfront_commission: form.receive_upfront_commission,
    };
    if (info) {
      petitionPatch("agents", {
        agent_id: info.pk,
        data: dataSend,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("agents", dataSend)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const deleteFunction = () => {
    console.log(info);
    petitionDelete("agents", info.pk)
      .then(({ data: { result } }) => {
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      agency_id: null,
      first_name: "",
      last_name: "",
      email: "",
      created_at: "",
      updated_at: "",
      receive_upfront_commission: true,
    });
  };
  return (
    <>
      <ModalFilter
        openModal={filterModal}
        closeModal={() => {
          setFilterModal(false);
        }}
        info={dataFilterModal}
      />
      <ModalAdd
        openModal={modalAdd}
        modalActions={addData}
        closeModal={() => {
          resetForm();
          setModalAdd(false);
        }}
        form={form}
        setForm={setForm}
        loading={loadingAddLead}
        agencies={agencies}
      />
      <ModalDelete
        openModal={modalDelete}
        modalActions={deleteFunction}
        closeModal={() => {
          resetForm();
          setModalDelete(false);
        }}
        loading={loadingDeleteLead}
      />

      {dataTable && <CustomTable data={dataTable} noClass={true} />}
    </>
  );
}
