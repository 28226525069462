import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalAddState from "./ModalUtility/ModalAddState";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import ModalImport from "./ModalUtility/ModalImport";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import Loading from "../../jsx/utils/Loading";
import getQueryString from "../../jsx/utils/getQueryString";
import { formatHourof24at12 } from "../../utils/GetChatDate";
import { sendToastSuccess, sendToastError } from "../../jsx/utils/toastAlert";

export default function Products() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const filterText = useRef();
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAddState, setModalAddState] = useState(false);
  const [loadingAdd, setLoadingAddLead] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const [formState, setFormState] = useState([]);
  const [list, setList] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [allResult, setAllResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [modalImport, setModalImport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [file, setFile] = useState(null);
  const [errorMessageImport, setErrorMessageImport] = useState("");

  const frequency = [
    { labek: "weekly", value: 52 },
    { labek: "biweekly", value: 26 },
    { labek: "monthly", value: 12 },
  ];

  const [form, setForm] = useState({
    pk: null,
    name: "",
    created_at: "",
    updated_at: "",
    import_name: "",
  });

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form) => {
    setLoadingFilter(true);
    let parameter = getQueryString(form);
    petition(parameter);
  };

  const modalAddStatesAction = (e) => {
    setLoadingAddLead(true);
    let data = [];
    list.forEach((element) => {
      data.push(element.value);
    });
    petitionPatch("products", {
      product_id: info.pk,
      data: { states: data },
    })
      .then(({ data: result }) => {
        setLoadingAddLead(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataFilterModal = {
    onSaveFunction: (form) => onSaveFunction(form),
    onHidde: () => onHidde(),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Products Created On or After",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_after",
            key: "added_after",
          },
          {
            label: "Products Created On or Before",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_before",
            key: "added_before",
          },
        ],
      },
    ],
  };

  const activeAddStateModal = (e) => {
    e.states.forEach((element) => {
      element.value = element.pk;
    });
    setForm(e);
    setInfo(e);
    setList(e.states);
    setModalAddState(true);
  };

  const activeEditModal = (e) => {
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeImportModal = () => {
    setModalImport(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalImport(false);
    setModalAdd(false);
    setInfo(null);
    resetForm();
    setErrorMessage(null);

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp) + (filterText.current || "");
      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[1] : false,
        paginationTemp
      );
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) + (filterText.current || "");
      petition(
        parameter,
        false,
        filterText.current ? true : false,
        filterText.current ? filterText.current.split("=")[1] : false,
        pagination.current
      );
    }
  };

  const formatDate = (date) => {
    let year = date.split(" ")[0].split("-")[0];
    let month = date.split(" ")[0].split("-")[1];
    let day = date.split(" ")[0].split("-")[2];

    let hour = formatHourof24at12(date);

    return year + "/" + month + "/" + day + " " + hour;
  };

  const petition = async (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    paginationTemp
  ) => {
    if (!noLoading) setLoading(true);
    let carriersTemp = [];
    await petitionGet("carriers", { id })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        carriersTemp = result.result;
        setCarriers(result.result);
      })
      .catch((error) => {});
    await petitionGet("states", { id })
      .then(({ data: result }) => {
        // setListUsers
        result.result.forEach((element) => {
          element.value = element.pk;
        });
        setListUsers(result.result);
        petitionGet("products", { id, parameter })
          .then(({ data: result }) => {
            setAllResult(result);
            result.result.forEach((element, i) => {
              element.positionTable = (paginationTemp?.offset || 0) + i + 1;
              element.commission_rate = element.commission_rate * 100;
              element.created_at = formatDate(element.created_at);
              element.updated_at = formatDate(element.updated_at);
              element.carrierName = element.carrier.name;
            });

            setDataTable({
              title: [
                // { title: "#", key: "positionTable" },
                { title: "PRODUCT NAME", key: "name" },
                { title: "IMPORT NAME", key: "import_name" },
                { title: "CARRIER", key: "carrierName" },
                // { title: "CREATED ON", key: "created_at" },
                // { title: "UPDATED ON", key: "updated_at" },
              ],
              content: [...result.result],
              actions: {
                title: "Actions",
                content: [
                  {
                    name: "Edit",
                    icon: <EditIcon />,
                    handleClick: activeEditModal,
                  },
                  // {
                  //   name: "States",
                  //   icon: <DeleteIcon />,
                  //   handleClick: activeAddStateModal,
                  // },
                  {
                    name: "Delete",
                    icon: <DeleteIcon />,
                    handleClick: activeDeleteModal,
                  },
                ],
              },
              noColumnNumber: true,
              addButton: {
                dropdown: {
                  name: "Add Product",
                  options: [
                    {
                      name: "New Product",
                      handleClick: () => setModalAdd(true),
                    },
                    {
                      name: "Import Products",
                      handleClick: activeImportModal,
                    },
                  ],
                },
              },
              search: {
                label: "Search by Product Name",
                filterBackend: true,
                onChange: (e) => filterTextBox(e.target.value),
                defaultValue: valueFilter || null,
              },
              itemsPerPage: (e) => {
                if (activeFilterTexBox) {
                  activeItemsPerPage(e, true, valueFilter);
                } else {
                  activeItemsPerPage(e);
                }
              },
              changePage: (page) => {
                if (activeFilterTexBox) {
                  changePage(page, true, valueFilter);
                } else {
                  changePage(page, false, false);
                }
              },
              iconAction: [
                {
                  name: "Export",
                  style: { marginLeft: "10px" },
                  icon: <SystemUpdateAltIcon className="icons-contacts" />,
                  handleClick: exportFunction,
                },
              ],
            });
            setLoading(false);
            setLoadingProduct(false);
            setLoadingFilter(false);
            setFilterModal(false);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    setLoadingProduct(true);
    let data = {
      name: form.name,
      import_name: form.import_name,
      carrier_id: form.carrier_id,
    };
    if (info) {
      petitionPatch("products", {
        product_id: info.pk,
        data,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);

          if (error.response && error.response.status === 400) {
              setErrorMessage(error?.response?.data?.error?.error_detailed || "");
          }

          setLoadingProduct(false);
        });
    } else {
      petitionPost("products", data)
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);

          if (error.response && error.response.status === 400) {
              setErrorMessage(error?.response?.data?.error?.error_detailed || "");
          }

          setLoadingProduct(false);
        });
    }
  };

  const deleteFunction = () => {
    setLoadingProduct(true);
    petitionDelete("products", info.pk)
      .then(({ data: { result } }) => {
        setModalDelete(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const importFunction = () => {
    setLoadingImport(true);
    const formData = new FormData();
    formData.append("file", file);
    petitionPost("import-products", formData)
      .then(({ data: result }) => {
        setLoadingImport(false);
        reloadData();
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          setLoadingImport(false);
          sendToastError();
        }

        if (error.response && error.response.status === 400) {
          setErrorMessageImport(
            error?.response?.data?.error?.error_detailed ||
              error?.response?.data?.error?.error_message
          );
          setLoadingImport(false);
        }

        console.log(error.response);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      created_at: "",
      updated_at: "",
      import_name: "",
    });
    setList([]);
    setFormState([]);
    setInfo(null);
    setModalAddState(false);
    setModalDelete(false);
  };

  const changePage = (page, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    if (activeFilterTexBox) {
      let parameter = getQueryString(paginationTemp) + `&name=${valueFilter}`;

      petition(parameter, true, true, valueFilter, paginationTemp);
      pagination.current = paginationTemp;
    } else {
      let parameter = getQueryString(paginationTemp);
      petition(parameter, true, false, false, paginationTemp);
      pagination.current = paginationTemp;
    }
  };

  const filterTextBox = (value) => {
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };

    if (value !== "") {
      let parameter = `${getQueryString(paginationTemp)}&name=${value}`;

      petition(parameter, true, true, value, paginationTemp);
      pagination.current = paginationTemp;
      if (value) {
        filterText.current = `&name=${value}`;
      } else {
        filterText.current = "";
      }
    } else {
      let parameter = getQueryString(paginationTemp);
      petition(parameter, true, false, false, paginationTemp);
      filterText.current = "";
      pagination.current = paginationTemp;
    }
  };

  const activeItemsPerPage = (data, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    if (activeFilterTexBox) {
      let parameter = getQueryString(paginationTemp) + `&name=${valueFilter}`;

      petition(parameter, false, true, valueFilter, paginationTemp);
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } else {
      let parameter = getQueryString(paginationTemp);
      petition(parameter, false, false, false, paginationTemp);
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    }
  };

  const exportFunction = (element) => {
    setLoadingIcon(element.icon);
    petitionGet("export-products")
      .then(({ data: result }) => {
        let link = document.createElement("a");
        link.download = "products.json";
        link.href = `data:application/json;base64,${result.result}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setLoadingIcon(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return (
    <>
      {modalAddState && (
        <ModalAddState
          openModal={modalAddState}
          modalActions={modalAddStatesAction}
          closeModal={() => {
            resetForm();
            setModalAddState(false);
          }}
          loading={loadingProduct}
          form={formState}
          setForm={setFormState}
          list={list}
          setList={setList}
          listUsers={listUsers}
        />
      )}

      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
            setErrorMessage(null);
          }}
          form={form}
          setForm={setForm}
          loading={loadingProduct}
          carriers={carriers}
          errorMessage={errorMessage}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
          }}
          loading={loadingProduct}
        />
      )}

      {modalImport && (
        <ModalImport
          modal={modalImport}
          closeModal={() => {
            setModalImport(false);
          }}
          modalFunction={importFunction}
          loading={loadingImport}
          file={file}
          setFile={setFile}
          errorMessage={errorMessageImport}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              noOverflow={true}
              pagination={itemsPerPage.current}
              loadingIcon={loadingIcon}
            />
          )}
        </>
      )}
    </>
  );
}
