import React from "react";
import NavTabCustom from "../../jsx/utils/NavTabCustom";
import Agents from "./Tabs/Agents";
import Agency from "./Tabs/Agency";

const PayoutHistory = () => {
  const options = [
    {
      title: "Agents",
      component: <Agents />,
    },
    {
      title: "Agency",
      component: <Agency />,
    },
  ];

  return <NavTabCustom options={options} />;
};

export default PayoutHistory;
