import {
  formatError,
  login,
  loginAgency,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import axios from "axios";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const USER_DATA = "[user action] confirmed user";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

const json = require("../../config.json");

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(dispatch, response.data.expiresIn * 1000, history);
        dispatch(confirmedSignupAction(response.data));
        history.push("/home", {
          userstorage: null,
          api_id: response.data,
        });
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history, loginAgent, info) {
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id; 

  localStorage.removeItem("userDetails");

  if (subAgencyItem) {
    localStorage.removeItem("subAgencyDetails");
  }
  // const linkPortal = json.prod
  //   ? `${json.portal.prod}?logout=true`
  //   : `${json.portal.dev}?logout=true`;

  const host = json.paylink.prod
    ? json.paylink.prod.split("//")[1]
    : json.paylink.dev.split("//")[1];

  const linkPaylink =
    window.location.host !== host
      ? `http://${window.location.host}/${
        sub_agency_id ? "login-agency" : !loginAgent ? "login" : "login-agent"
        }${!sub_agency_id ? "?redirect=true" : ""}`
      : json.prod
      ? `${json.paylink.prod}/${
        sub_agency_id ? "login-agency" : !loginAgent ? "login" : "login-agent"
        }${!sub_agency_id ? "?redirect=true" : ""}`
      : `${json.paylink.dev}/${
        sub_agency_id ? "login-agency" : !loginAgent ? "login" : "login-agent"
        }${!sub_agency_id ? "?redirect=true" : ""}`;

  window.location.href = linkPaylink;
  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history, setLoading, loginAgent) {
  return (dispatch) => {
    setLoading(true);
    login(email, password, loginAgent)
      .then((response) => {
        const url =
          (json.prod ? json.urlBase.prod : json.urlBase.dev) + "crm/services";
        const token = response.data.result.access_token;
        const config = {
          headers: {
            Authorization: "Bearer " + token.replace(/['"]+/g, ""),
          },
        };

        let dataLocalStrage = {};
        if (!loginAgent) {
          axios
            .get(url, config)
            .then((result) => {
              let api_id = result.data.result.find(
                (element) => element.item_type === "PayLink360"
              ).api_id;
              dataLocalStrage = {
                ...response.data.result,
                api_id,
                loginAgent: false,
              };
              saveTokenInLocalStorage(dataLocalStrage);
              // runLogoutTimer(dispatch, 1000000, history);
              dispatch(loginConfirmedAction(dataLocalStrage));
              history.push("/home");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          dataLocalStrage = {
            ...response.data.result,
            agent_id: response.data.result.pk,
            loginAgent: true,
            api_id: response.data.result.account_id,
            firstname: response.data.result.first_name,
            lastname: response.data.result.last_name,
          };

          delete dataLocalStrage.pk;
          saveTokenInLocalStorage(dataLocalStrage);
          // runLogoutTimer(dispatch, 1000000, history);
          dispatch(loginConfirmedAction(dataLocalStrage));
          history.push("policies");
        }
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
        setLoading(false);
      });
  };
}

export function loginAgencyAction(email, password, history, setLoading, loginAgent) {
  return (dispatch) => {
    setLoading(true);
    loginAgency(email, password, loginAgent)
      .then((response) => {
        // const url =
        //   (json.prod ? json.urlBase.prod : json.urlBase.dev) + "crm/services";
        // const token = response.data.result.access_token;
        // const config = {
        //   headers: {
        //     Authorization: "Bearer " + token.replace(/['"]+/g, ""),
        //   },
        // };

        const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
      
        if (subAgencyItem) {
          localStorage.removeItem("subAgencyDetails");
        }

        let dataLocalStrage = {
          ...response.data.result,
          api_id: response.data.result.account_id,
          sub_agency_id: true,
        };

        saveTokenInLocalStorage(dataLocalStrage);
        dispatch(loginConfirmedAction(dataLocalStrage));
        history.push("/home")

        // if (!loginAgent) {
        //   axios
        //     .get(url, config)
        //     .then((result) => {
        //       let api_id = result.data.result.find(
        //         (element) => element.item_type === "PayLink360"
        //       ).api_id;
        //       dataLocalStrage = {
        //         ...response.data.result,
        //         api_id,
        //         loginAgent: false,
        //       };
        //       saveTokenInLocalStorage(dataLocalStrage);
        //       // runLogoutTimer(dispatch, 1000000, history);
        //       dispatch(loginConfirmedAction(dataLocalStrage));
        //       history.push("/home");
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //     });
        // } else {
        //   dataLocalStrage = {
        //     ...response.data.result,
        //     agent_id: response.data.result.pk,
        //     loginAgent: true,
        //     api_id: response.data.result.account_id,
        //     firstname: response.data.result.first_name,
        //     lastname: response.data.result.last_name,
        //   };

        //   delete dataLocalStrage.pk;
        //   saveTokenInLocalStorage(dataLocalStrage);
        //   // runLogoutTimer(dispatch, 1000000, history);
        //   dispatch(loginConfirmedAction(dataLocalStrage));
        //   history.push("policies");
        // }
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
        setLoading(false);
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}
export function setUserData(data) {
  return {
    type: USER_DATA,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
