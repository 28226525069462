// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `i.fa {
  width: 30px !important;
}

i.fas {
  width: 30px !important;
}

i.fas.active-list {
  color: #dc3545 !important;
}

.dlabnav .metismenu > li a > i {
  margin-right: 5px !important;
}

.has-arrow.disabled-list {
  background-color: transparent !important;
  color: #737b8b !important;
}

.has-arrow.disabled-list i {
  color: #737b8b !important;
}

.has-arrow.disabled-list > *:nth-child(2) {
  color: #737b8b !important;
}

.has-arrow.disabled-list::after {
  border-top: 0.3125rem solid #c8c8c8 !important;
  border-left: 0.3125rem solid #c8c8c8 !important;
  border-bottom: 0.3125rem solid transparent !important;
  border-right: 0.3125rem solid transparent !important;
  right: 3.5rem !important;
  top: 48% !important;
}

.has-arrow.active-list {
  background-color: rgba(220, 53, 69, 0.1) !important;
  color: #dc3545 !important;
  border-radius: 3.875rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/jsx/layouts/nav/sidebar.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,wCAAwC;EACxC,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8CAA8C;EAC9C,+CAA+C;EAC/C,qDAAqD;EACrD,oDAAoD;EACpD,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,mDAAmD;EACnD,yBAAyB;EACzB,kCAAkC;AACpC","sourcesContent":["i.fa {\n  width: 30px !important;\n}\n\ni.fas {\n  width: 30px !important;\n}\n\ni.fas.active-list {\n  color: #dc3545 !important;\n}\n\n.dlabnav .metismenu > li a > i {\n  margin-right: 5px !important;\n}\n\n.has-arrow.disabled-list {\n  background-color: transparent !important;\n  color: #737b8b !important;\n}\n\n.has-arrow.disabled-list i {\n  color: #737b8b !important;\n}\n\n.has-arrow.disabled-list > *:nth-child(2) {\n  color: #737b8b !important;\n}\n\n.has-arrow.disabled-list::after {\n  border-top: 0.3125rem solid #c8c8c8 !important;\n  border-left: 0.3125rem solid #c8c8c8 !important;\n  border-bottom: 0.3125rem solid transparent !important;\n  border-right: 0.3125rem solid transparent !important;\n  right: 3.5rem !important;\n  top: 48% !important;\n}\n\n.has-arrow.active-list {\n  background-color: rgba(220, 53, 69, 0.1) !important;\n  color: #dc3545 !important;\n  border-radius: 3.875rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
