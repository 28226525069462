import petitionGet from "./petitionGet";

const searchPoliciesService = (inputValue, callback) => {
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""
  const querySubAgency = subAgencyItem ? `&${subAgencyQuery}` : ""
  const inputQuery = inputValue ? `?policy_number=${inputValue}&first_name=${inputValue}` : "?offset=0&limit=10"
  const completeParameter = inputQuery + querySubAgency

  petitionGet("policies", { parameter: completeParameter })
    .then(({ data: result }) => {
      result.result.forEach((element) => {
        element.value = element.pk;
        element.label = element.policy_number;
      });

      callback(result.result);
    })
    .catch((error) => {
      console.log(error);
    });
};

export { searchPoliciesService };
