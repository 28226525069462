import axios from "axios";
import json from "../config.json";
export default function petitionGet(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails")).sub_agency_id;
  const urlBaseAgent = json.prod ? json.agent.prod : json.agent.dev;

  var url;

  if (token) {
    switch (key) {
      case "authLogout":
        url = urlBase + `crm/auth/verify`;
        break;

      case "refresh":
          url = sub_agency_id ? urlBase + "paylink360/users/auth/refresh": urlBase + "crm/auth/refresh";
        break;
      case "agents":
          url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/agents${value?.parameter || ""}`: urlBase + `paylink360/${api_id}/agents${value?.parameter || ""}`;
        break;
      case "agencies":
        url =
          urlBase + `paylink360/${api_id}/agencies${value?.parameter || ""}`;
        break;
      case "carriers":
        url =sub_agency_id ?  urlBase + `paylink360/${api_id}/sub-agencies/carriers${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/carriers${value?.parameter || ""}`;
        break;
      case "states":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/states" : urlBase + "paylink360/" + api_id + "/states";
        break;
      case "products":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/products${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/products${value?.parameter || ""}`;
        break;
      case "import-templates":
        url =  sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/import-templates${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/import-templates${value?.parameter || ""}`;
        break;
      case "import-templates-fields":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/import/commissions/fields${value?.parameter || "" }` :  urlBase + `paylink360/${api_id}/import/commissions/fields${value?.parameter || "" }`;
        break;
      case "contracts":
        url = sub_agency_id ?  urlBase + `paylink360/${api_id}/sub-agencies/contracts${value?.parameter || ""}`:urlBase + `paylink360/${api_id}/contracts${value?.parameter || ""}`;
        break;
      case "appointments":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/appointments${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/appointments${value?.parameter || ""}` ;
        break;
      case "rates":
        url =sub_agency_id ?  urlBase + `paylink360/${api_id}/sub-agencies/commission-rate-groups/${value?.group_id}/commission-rates${value?.parameter || ""}`:urlBase + `paylink360/${api_id}/commission-rate-groups/${value?.group_id}/commission-rates${value?.parameter || ""}`;
        break;
      case "rates-group":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/commission-rate-groups${value?.parameter || "" }` : urlBase + `paylink360/${api_id}/commission-rate-groups${value?.parameter || "" }`;
        break;
      case "rate-rules":
        url =  sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/commission-rates/${value.rate_group_id}/rules${value?.parameter || "" }` : urlBase + `paylink360/${api_id}/commission-rates/${value.rate_group_id}/rules${value?.parameter || "" }`;
        break;
      case "transactions":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/transactions${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/transactions${value?.parameter || ""}`;
        break;
      case "policy":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/policies/${value?.policy_id}` : urlBase + `paylink360/${api_id}/policies/${value?.policy_id}`;
        break;
      case "policies":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/policies${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/policies${value?.parameter || ""}`;
        break;

      case "states-products":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/products/${value?.product_id}/states` : urlBase + `paylink360/${api_id}/products/${value?.product_id}/states`;
        break;
      case "transactionsPDF":
        url =
          urlBase +
          `paylink360/${api_id}/transactions/report/pdf${value?.parameter || ""
          }`;
        break;
      case "AgentReportPDF":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/reports/agent/pdf${value?.parameter || ""}` :  urlBase + `paylink360/${api_id}/reports/agent/pdf${value?.parameter || ""}`;
        break;
      case "payouts":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/payouts${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/payouts${value?.parameter || ""}`;
        break;
      case "dashboard":
        url = sub_agency_id ?  urlBase + `paylink360/${api_id}/sub-agencies/dashboard${value?.parameter || ""}` :  urlBase + `paylink360/${api_id}/dashboard${value?.parameter || ""}`;
        break;
      case "payoutPdfDownload":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/reports/payouts/${value?.payout_id}/pdf${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/reports/payouts/${value?.payout_id}/pdf${value?.parameter || ""}`;
        break;
      case "create-payouts":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/create-payouts${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/create-payouts${value?.parameter || ""}`;
        break;
      case "account-details":
        url = sub_agency_id ?  urlBase + `/paylink360/${api_id}/sub-agency` :  urlBase + `paylink360/${api_id}`;
        break;
      case "overrides":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/overrides${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/overrides${value?.parameter || ""}`;
        break;
      case "duplicate-rates":
        url =
          urlBase +
          `paylink360/${api_id}/commission-rate-groups/${value?.rate_id}/duplicate`;
        break;
      case "api-keys":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/api_key${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/api_key${value?.parameter || ""}`;
        break;
      case "payouts-agent":
        url = sub_agency_id ? urlBaseAgent + `paylink360/${api_id}/sub-agencies/payouts${value?.parameter || ""}` : urlBaseAgent + `paylink360/${api_id}/payouts${value?.parameter || ""}`;
        break;
      case "payoutPdfDownloadAgent":
        url = sub_agency_id ? urlBaseAgent + `paylink360/${api_id}/sub-agencies/payouts/${value?.payout_id}/pdf` : urlBaseAgent + `paylink360/${api_id}/payouts/${value?.payout_id}/pdf`;
        break;
      case "policies-agent":
        url = sub_agency_id ? urlBaseAgent + `paylink360/${api_id}/sub-agencies/policies${value?.parameter || ""}` : urlBaseAgent + `paylink360/${api_id}/policies${value?.parameter || ""}`;
        break;
      case "agent-details":
        url = urlBaseAgent + `paylink360/${api_id}/agent`;
        break;
      case "states-agent":
        url = urlBaseAgent + `paylink360/${api_id}/states`;
        break;
      case "products-agent":
        url = urlBaseAgent + `paylink360/${api_id}/products`;
        break;
      case "account-details-agent":
        url = urlBaseAgent + `paylink360/${api_id}`;
        break;
      case "reset-password":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/agents/${value?.agent_id}/reset-pw`:urlBase + `paylink360/${api_id}/agents/${value?.agent_id}/reset-pw`;
        break;
      case "import-jobs":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/imported-commissions${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/imported-commissions${value?.parameter || ""}`;
        break;
      case "view-error-jobs":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/imported-commissions/${value?.job_id}` : urlBase + `paylink360/${api_id}/imported-commissions/${value?.job_id}`;
        break;
      case "refresh-agent":
        url = urlBaseAgent + "paylink360/auth/refresh";
        break;
      case "authLogoutAgent":
        url = urlBaseAgent + `paylink360/auth/verify`;
        break;
      case "export-products":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/exports/products` : urlBase + `paylink360/${api_id}/exports/products`;
        break;
      case "export-rates-groups":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/exports/rate-groups` : urlBase + `paylink360/${api_id}/exports/rate-groups`;
        break;
      case "send-email-payouts":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/email/payouts/send${value?.parameter || ""}` : urlBase + `paylink360/${api_id}/email/payouts/send${value?.parameter || ""}`;
        break;
      case "sub-agencies":
        url = urlBase + `paylink360/${api_id}/sub-agencies${value?.parameter || ""}`;
        break;
        
      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };
    return axios.get(url, config);
  } else {
    window.location.href = "./login";
  }
}
