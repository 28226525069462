import React from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const BulkDelete = ({
  openModal,
  modalActions,
  closeModal,
  noShowActionButton,
  loading,
  selectedTransactions,
}) => (
  <Modal className="fade" show={openModal}>
    <Modal.Header>
      <Modal.Title>Delete Transactions</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {noShowActionButton ? (
        <p>you must select at least one transaction</p>
      ) : (
        <>
          <p>
            Warning! Transactions related to a payment will not be deleted
          </p>
          <p>Are you sure to delete {selectedTransactions} transaction(s)?</p>
        </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <ButtonStyled
        text="Cancel"
        onClickFunction={closeModal}
        disabled={loading}
      />
      {!noShowActionButton && (
        <ButtonStyled
          loading={loading}
          text="Delete"
          onClickFunction={modalActions}
        />
      )}
    </Modal.Footer>
  </Modal>
);

export default BulkDelete;
