import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../context/ThemeContext";
import { connect } from "react-redux";
import "../layouts/nav/sidebar.css"
import { withRouter } from "react-router-dom";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBarAgent = (props) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {/* <li className={`${path === "home" ? "mm-active" : ""}`}>
            <Link to="home" className="ai-icon">
              <i className="fas fa-home" style={{width:"1px !important"}}></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${path === "agents" ? "mm-active" : ""}`}>
            <Link to="agents" className="ai-icon">
              <i className="fas fa-user"></i>
              <span className="nav-text">Agents</span>
            </Link>
          </li>
          <li className={`${path === "Transactions" ? "mm-active" : ""}`}>
            <Link to="Transactions" className="ai-icon">
              <i className="fa fa-receipt"></i>
              <span className="nav-text">Transactions</span>
            </Link>
          </li> */}
          <li className={`${path === "profile" ? "mm-active" : ""}`}>
            <Link to="profile" className="ai-icon">
            <i className="fas fa-address-card"></i>
              <span className="nav-text">Profile</span>
            </Link>
          </li>
          <li className={`${path === "policies" ? "mm-active" : ""}`}>
            <Link to="policies" className="ai-icon">
              <i className="fas fa-user-shield"></i>
              <span className="nav-text">Policies</span>
            </Link>
          </li>
          <li className={`${path === "payout-history" ? "mm-active" : ""}`}>
            <Link to="payout-history" className="ai-icon">
            <i className="fas fa-chart-bar"></i>
              <span className="nav-text">Payout History</span>
            </Link>
          </li>
          {/* <li className={`${path === "reports" ? "mm-active" : ""}`}>
            <Link to="reports" className="has-arrow">
              <i className="fas fa-chart-bar"></i>
              <span className="nav-text">Import</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "import-commissions" ? "mm-active" : ""
                    }`}
                  to="/import-commissions"
                >
                  {" "}
                  Commissions
                </Link>
              </li>
      
            </ul>
          </li>
          <li className={`${path === "settings" ? "mm-active" : ""}`}>
            <Link to="settings" className="ai-icon">
              <i className="fas fa-hammer"></i>
              <span className="nav-text">Settings</span>
            </Link>
          </li> */}
        </MM>

        <div className="copyright">
          <p>
            <strong>ITP</strong> © 2022 All Rights Reserved
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(SideBarAgent));
