import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { searchProductService } from "../../../services/searchProductService";
import { searchAgentsService } from "../../../services/searchAgentsService";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import "./ModalAdd.css"

const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  form,
  setForm,
  allStates,
  loading,
}) => {
  const [valueAgent, setValueAgent] = useState(
    form.agentName && form.agentValue
      ? { label: form.agentName, value: form.agentValue }
      : null
  );

  const [valueProduct, setValueProduct] = useState(
    form.productName && form.product_id
      ? { label: form.productName, value: form.product_id }
      : null
  );

  const [valueState, setValueState] = useState(
    form.stateName && form.stateValue
      ? { label: form.stateName, value: form.stateValue }
      : null
  );

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"));

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>
          {form.pk ? "Edit Policies" : "Create Policies"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>First name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, first_name: e.target.value });
              }}
              defaultValue={form?.first_name || ""}
            ></input>
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Last name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, last_name: e.target.value });
              }}
              defaultValue={form?.last_name || ""}
            ></input>
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Effective Date:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({
                  ...form,
                  effective_date:
                    e.target.value + " " + "18:26:22.632282+00:00",
                });
              }}
              defaultValue={form?.effective_date?.slice(0, 10) || ""}
              type="date"
            ></input>
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Policy number:</label>
          <div className="col-8">
            <input
              type={"number"}
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, policy_number: e.target.value });
              }}
              defaultValue={form?.policy_number || ""}
            ></input>
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Premium Amount:</label>
          <div className="col-8">
            <input
              type={"number"}
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, premium_amount: e.target.value });
              }}
              defaultValue={form?.premium_amount || ""}
            ></input>
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Agent:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                setForm({ ...form, agent_id: e.value })
                setValueAgent(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueAgent}
              isSearchable={true}
              loadOptions={(inputValue, callback) => searchAgentsService(inputValue, callback)}
              name="agent_id"
              defaultOptions
              cacheOptions
            />
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Product:</label>
          <div className="col-8">
            <AsyncSelect
              onChange={(e) => {
                setForm({ ...form, product_id: e.pk })
                setValueProduct(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueProduct}
              isSearchable={true}
              name="product_id"
              loadOptions={(inputValue, callback) => searchProductService(inputValue, callback)}
              defaultOptions
              cacheOptions
            />
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>State:</label>
          <div className="col-8">
            <Select
              onChange={(e) => {
                setForm({ ...form, state_id: e.value })
                setValueState(e)
              }}
              className="basic-single"
              placeholder="Select Option"
              classNamePrefix="select"
              value={valueState}
              isSearchable={true}
              name="state_id"
              options={allStates}
            />
          </div>
        </div>

        {subAgencyItem && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Sub Agency</label>
            <div className="col-8">
              <select
                defaultValue={subAgencyItem.name}
                className="arrow-select"
                name="sub_agency_id"
              >
                <option disabled value={subAgencyItem.name}>
                  {subAgencyItem.name}
                </option>
              </select>
            </div>
          </div>
        )}

        {form?.pk && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <div className="container-label">
              <div className="container-label-description">
                <label className="mb-0 tex-left">
                  This means the policy has already been paid at least one time 
                  or has converted to a paid-once policy. If agent is on a Rate 
                  Group with Paid Once checked this Agent will only receive 
                  commissions one time for this policy.
                </label>
              </div>
              <label>Paid Once:</label>
            </div>
            <div className="col-8">
              <input 
                className="form-check-input"
                style={{ border: "2px solid #d7dae3" }}
                type="checkbox"
                checked={form?.paid_once || false}
                onChange={() => setForm({ ...form, paid_once: !form.paid_once })} 
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.agent_id ||
            !form.premium_amount ||
            !form.product_id ||
            !form.policy_number ||
            !form.first_name ||
            !form.last_name ||
            !form.state_id ||
            !form.effective_date
          }
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => modalActions()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
