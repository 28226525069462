export default function Step2({templates,form,setForm}) {
  return (
    <>
      <div
        style={{ marginBottom: "10px" }}
        className="d-flex align-items-center justify-content-between"
      >
        <label>Select a template:</label>
        <div className="col-8">
          <select
            className="arrow-select"
            value={form.agency_id}
            onChange={(e) => {
              setForm({
                ...form,
                template_id: e.target.value,
              });
            }}
          >
            <option  value={"null"}>
                Select an option
              </option>
            {templates.map((option) => (
              <option key={option.pk} value={option.pk}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
