import React, { useState, useEffect, useRef } from "react";
import petitionPost from "../../services/petitionPost";
import petitionGet from "../../services/petitionGet";
import petitionDelete from "../../services/petitionDelete";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import Loading from "../../jsx/utils/Loading";
import ModalUtility from "./ModalUtility/ModalUtility";
import getQueryString from "../../jsx/utils/getQueryString";
import DeleteIcon from "@material-ui/icons/Delete";

const ApiKeys = () => {
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteApiKey, setDeleteApiKey] = useState(null);
  const [allResult, setAllResult] = useState(null);

  const closeModal = () => {
    setDeleteApiKey(null);
    setOpenModal(false);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    closeModal();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp);
      petition(parameter, paginationTemp);
      pagination.current = paginationTemp;
    } else {
      parameter = getQueryString(pagination.current);
      petition(parameter, pagination.current);
    }
  };

  const activeDeleteModal = (e) => {
    setDeleteApiKey(e);
    setOpenModal(true);
  };

  const activeAddModal = () => {
    setOpenModal(true);
  };

  const modalUtilityActions = () => {
    setLoadingModal(true);
    if (deleteApiKey) {
      petitionDelete("api-keys", deleteApiKey.pk)
        .then(({ data: result }) => {
          setLoadingModal(false);
          if (
            allResult.result.length === 1 &&
            parseInt(allResult.offset) !== 0
          ) {
            reloadData(true);
          } else {
            reloadData();
          }
        })
        .catch((error) => console.log(error));
    } else {
      petitionPost("api-keys", null)
        .then(({ data: result }) => {
          setLoadingModal(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, paginationTemp);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const petition = (parameter, paginationTemp) => {
    setLoading(true);
    petitionGet("api-keys", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);

        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
        });

        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "Api Key", key: "api_key" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          addButton: {
            label: "Add Api Key",
            handleClick: activeAddModal,
          },
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {openModal && (
        <ModalUtility
          openModal={openModal}
          modalActions={modalUtilityActions}
          closeModal={closeModal}
          info={deleteApiKey}
          loading={loadingModal}
        />
      )}

      {dataTable && (
        <CustomTable
          data={dataTable}
          allResult={allResult}
          pagination={itemsPerPage.current}
        />
      )}
    </>
  );
};

export default ApiKeys;
