// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.itp-container-bulk-actions {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #70707029;
  border-radius: 16px;
  opacity: 1;
  margin-left: auto;
  display: inline-block;
}

.itp-bulk-actions {
  display: flex;
  gap: 16px;
}

.itp-label-bulk-actions {
  text-align: left;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin: 4px 0 8px 10px;
}

.itp-icon-bulk-action {
  margin-top: 2px;
  margin-bottom: 4px;
}

.itp-bulk-icon-hover {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
}

.itp-icon-bulk-action:hover > .itp-bulk-icon-hover {
  visibility: visible;
}

.itp-last-icon-bulk-actions {
  margin-right: 10px;
}

.itp-bulk-icon-name {
  text-align: left;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/jsx/utils/BulkActions/BulkActions.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,iCAAiC;EACjC,mBAAmB;EACnB,UAAU;EACV,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,+CAA+C;EAC/C,6CAA6C;EAC7C,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,YAAY;AACd","sourcesContent":[".itp-container-bulk-actions {\n  background: #ffffff 0% 0% no-repeat padding-box;\n  box-shadow: 0px 0px 4px #70707029;\n  border-radius: 16px;\n  opacity: 1;\n  margin-left: auto;\n  display: inline-block;\n}\n\n.itp-bulk-actions {\n  display: flex;\n  gap: 16px;\n}\n\n.itp-label-bulk-actions {\n  text-align: left;\n  letter-spacing: 0px;\n  color: black;\n  opacity: 1;\n  margin: 4px 0 8px 10px;\n}\n\n.itp-icon-bulk-action {\n  margin-top: 2px;\n  margin-bottom: 4px;\n}\n\n.itp-bulk-icon-hover {\n  visibility: hidden;\n  background: #ffffff 0% 0% no-repeat padding-box;\n  border: 0.30000001192092896px solid #70707073;\n  border-radius: 10px;\n  opacity: 1;\n  position: absolute;\n  margin-top: 4px;\n  z-index: 2;\n}\n\n.itp-icon-bulk-action:hover > .itp-bulk-icon-hover {\n  visibility: visible;\n}\n\n.itp-last-icon-bulk-actions {\n  margin-right: 10px;\n}\n\n.itp-bulk-icon-name {\n  text-align: left;\n  letter-spacing: 0px;\n  color: black;\n  opacity: 1;\n  margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
