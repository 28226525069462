import petitionGet from "./petitionGet";

const searchCarriersService = (inputValue, callback) => {
  const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10";

  petitionGet("carriers", { parameter })
    .then(({ data: result }) => {
      result.result.forEach((element) => {
        element.value = element.pk;
        element.label = element.name;
      });

      callback(result.result);
    })
    .catch((error) => {
      console.log(error);
    });
};

export { searchCarriersService };
