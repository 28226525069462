import { useState, useEffect, useRef } from "react";
import Select from "react-select";

import "./styles.css";

export default function Step2({ templates, form, setForm, agents }) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(agents);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const inputRef = useRef(null);

  const colourStyles = {
    input: () => ({ color: "white" }),
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleSelect = (option) => {
    setForm({
      ...form,
      agent_id: option.value,
    });
    setIsOpen(false);
    setSearch(option.label);
    setSelectedOptionIndex(-1);
  };

  const handleInputChange = (event) => {
    const value = event.value;
    setSearch(value);
    const filtered = agents.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setIsOpen(filtered.length > 0);
    setSelectedOptionIndex(-1);
  };

  const handleKeyDown = (event) => {
    if (!isOpen) {
      return;
    }

    const optionsCount = filteredOptions.length;

    switch (event.key) {
      case "ArrowUp": {
        event.preventDefault();
        const prevIndex =
          selectedOptionIndex > 0 ? selectedOptionIndex - 1 : optionsCount - 1;
        setSelectedOptionIndex(prevIndex);
        break;
      }
      case "ArrowDown": {
        event.preventDefault();
        const nextIndex =
          selectedOptionIndex < optionsCount - 1 ? selectedOptionIndex + 1 : 0;
        setSelectedOptionIndex(nextIndex);
        break;
      }
      case "Enter": {
        event.preventDefault();
        const selectedOption = filteredOptions[selectedOptionIndex];
        if (selectedOption) {
          handleSelect(selectedOption);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <label>Select an agent:</label>
        {/* <div className="dropdown-autocomplete">
            <div className="input-container">
              <input
                type="text"
                value={search}
                placeholder="Search..."
                onClick={() => setIsOpen(true)}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="form-control dropdown-autocomplete__input"
                ref={inputRef}
              />
            </div>
            {isOpen && (
              <ul className="options-container dropdown-autocomplete__options">
                {filteredOptions.map((option, index) => (
                  <li
                    key={option.value}
                    className={`dropdown-autocomplete__item ${index === selectedOptionIndex ? "selected" : ""}`}
                    onClick={() => handleSelect(option)}
                    style={{ backgroundColor: index === selectedOptionIndex ? "#ccc" : "transparent" }}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div> */}
        <Select
          onChange={handleSelect}
          // className="basic-single"
          classNamePrefix="select"
          defaultValue={agents[0]}
          isSearchable={true}
          name="agent_id"
          options={agents}
          styles={colourStyles}
        />
      </div>
    </>
  );
}
