import { useEffect, useState } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import AddMAppings from "./ModalUtility/AddMAppings";

export default function Agencies() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalAddMapping, setModalAddMapping] = useState(false);
  const [loadingAddLead, setLoadingAddLead] = useState(false);
  const [loadingDeleteLead, setLoadingDeleteLead] = useState(false);
  const [fields, setFields] = useState([]);
  const [fieldsList, setFieldsList] = useState([]);

  const [form, setForm] = useState({
    pk: null,
    name: "",
    field_mappings: [],
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    petition();
  }, []);

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form) => {
    setLoadingFilter(true);
    let parameter = getQueryString(form);
    petition(parameter);
  };

  const dataFilterModal = {
    onSaveFunction: (form) => onSaveFunction(form),
    onHidde: () => onHidde(),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Agency Created On or After",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_after",
            key: "added_after",
          },
          {
            label: "Agency Created On or Before",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_before",
            key: "added_before",
          },
        ],
      },
    ],
  };

  const activeEditModal = (e) => {
    console.log(e);
    setFieldsList(e.field_mappings);
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeEditModalMapping = (e) => {
    setForm(e);
    setInfo(e);
    setModalAddMapping(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = () => {
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    resetForm();
    petition();
  };

  const petition = (parameter) => {
    setLoading(true);
    petitionGet("import-templates-fields", { id, parameter })
      .then(({ data: result }) => {
        setFields(result.result);
      })
      .catch((error) => console.log(error));

    petitionGet("import-templates", { id, parameter })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.created_at = element.created_at
            .substring(0, 15)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 15)
            .replaceAll("-", "/");
        });
        setDataTable({
          title: [
            { title: "NAME", key: "name" },
            { title: "DESCRIPTION", key: "description" },
            { title: "CREATED ON", key: "created_at" },
            { title: "UPDATED ON", key: "updated_at" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeEditModal,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          search: {
            label: "Search Template",
          },
          addButton: {
            label: "Add Template",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          showMorefilters: () => setFilterModal(true),
        });
        setLoading(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    if (info) {
      petitionPatch("import-templates", {
        template_id: info.pk,
        data: {
          name: form.name,
          description: form.description,
          field_mappings: form.field_mappings,
        },
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("import-templates", {
        name: form.name,
        description: form.description,
        field_mappings: form.field_mappings,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const deleteFunction = () => {
    petitionDelete("import-templates", info.pk)
      .then(({ data: { result } }) => {
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      created_at: "",
      updated_at: "",
      field_mappings: [],
    });
  };
  return (
    <>
      <ModalFilter
        openModal={filterModal}
        closeModal={() => {
          setFilterModal(false);
        }}
        info={dataFilterModal}
        loading={loadingFilter}
      />
      <ModalAdd
        openModal={modalAdd}
        modalActions={addData}
        closeModal={() => {
          resetForm();
          setModalAdd(false);
        }}
        form={form}
        setForm={setForm}
        loading={loadingAddLead}
        fields={fields}
        fieldsList={fieldsList}
      />
      <AddMAppings
        openModal={modalAddMapping}
        modalActions={addData}
        closeModal={() => {
          resetForm();
          setModalAddMapping(false);
        }}
        form={form}
        setForm={setForm}
        loading={loadingAddLead}
      />

      <ModalDelete
        openModal={modalDelete}
        modalActions={deleteFunction}
        closeModal={() => {
          resetForm();
          setModalDelete(false);
        }}
        loading={loadingDeleteLead}
      />
      {loading ? (
        <Loading />
      ) : (
        dataTable && <CustomTable data={dataTable} noClass={true} />
      )}
    </>
  );
}
