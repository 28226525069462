import React, { Fragment, useEffect, useState } from "react";
// import { Stepper } from "react-form-stepper";
import { TabContent, TabPane } from "reactstrap";
import ButtonStyled from "../../jsx/utils/ButtonStyled";
import petitionGet from "../../services/petitionGet";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useHistory } from 'react-router-dom';

// import "./styles.css";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import petitionPost from "../../services/petitionPost";
import Stepper from "../../components/Stepper/Stepper";
import Loading from "../../jsx/components/Loading/Loading";
import { sendToastError } from "../../jsx/utils/toastAlert";

const ErrorModal = ({ isOpen, toggle, message, failedImports }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Error</ModalHeader>
      <ModalBody>
        <p>{message}</p>
        {failedImports && failedImports.length > 0 && (
          <>
            <p>
              The Records below were unable to be imported. No records will be
              imported from this file until all errors are resolved.
            </p>
            <div style={{ maxHeight: "400px", overflow: "auto" }}>
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    {Object.keys(failedImports[0]).map((key, index) => (
                      <th key={index}>{formatColumnName(key)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {failedImports.map((failedRow, index) => (
                    <tr key={index}>
                      {Object.values(failedRow).map((value, index) => (
                        <td key={index}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

const formatColumnName = (columnName) => {
  return columnName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const ImportCommissions = () => {
  const history = useHistory();
  const [errorModal, setErrorModal] = useState(false);
  const [step, setStep] = useState(0);
  const [info, setInfo] = useState(true);
  const [form, setForm] = useState(true);
  const [file, setFile] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [failedImports, setFailedImports] = useState([]);
  const [loadingImport, setLoadingImport] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileTypes = ["csv"];
  const handleChange = (file) => {
    setFile(file);
  };
  // const steps = [
  //   { name: "Personal Info", component: <h1>Hola 1</h1> },
  //   { name: "Company Info", component: <h1>Hola 2</h1> },
  //   { name: "Preview Values", component: <h1>Hola 3</h1> },
  //   { name: "Email Setup", component: <h1>Hola 4</h1> },
  // ];

  const modalActions = () => {
    console.log("test");
  };

  useEffect(() => {
    getTemplatesItp();
  }, []);

  const getTemplatesItp = () => {
    setLoading(true)
    petitionGet("import-templates")
      .then(({ data: result }) => {
        setTemplates(result.result);
        setLoading(false)
      })
      .catch((error) => console.log(error));
  };

  const stepController = () => {
    switch (step) {
      case 0:
        if (file) {
          return false;
        } else {
          return true;
        }
        break;
      case 1:
        if (form.template_id && form.template_id != "null") {
          return false;
        } else {
          return true;
        }
        break;
      case 2:
        // add logic here
        break;
      case 3:
        // add logic here
        break;
      default:
        break;
    }
  };

  const stepActions = () => {
    switch (step) {
      case 1:
        sendReview();
        break;
      case 2:
        sendImport();
        break;
      default:
        if (step === 3) {
          modalActions(form);
          setStep(0);
        } else {
          setStep(step + 1);
        }
        break;
    }
  };

  const sendReview = () => {
    setLoadingImport(true)
    const formData = new FormData();
    let payload = {
      template_id: form.template_id,
    };
    formData.append("file", file);
    formData.append("payload", JSON.stringify(payload));
    petitionPost("import-templates-preview", formData)
      .then(({ data: result }) => {
        let titles = [];
        for (let key in result.result[0]) {
          titles.push({ title: key, key: key });
        }

        setPreviewData({
          title: titles,
          content: [...result.result],
        });
        setStep(step + 1);
        setLoadingImport(false)
      })
      .catch((error) => {
        if(error.response && error.response.status === 500){
          let errorText = "An Error in the server has occurred, please try again."
          sendToastError(errorText)
          setLoadingImport(false)
        } else {
          console.log(error)
        }
      });
  };

  const sendImport = () => {
    setLoadingImport(true);
    const formData = new FormData();
    let payload = {
      template_id: form.template_id,
    };
    formData.append("file", file);
    formData.append("payload", JSON.stringify(payload));
    petitionPost("import", formData)
      .then(({ data: result }) => {
        setStep(step + 1);
        setLoadingImport(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setFailedImports(error.response.data.result.failed);
          setErrorModal(true);
          setLoadingImport(false);
        } else {
          console.log(error);
        }
      });
  };

  const resetForm = () => {
    setLoading(true)
    setStep(0);
    setInfo(true);
    setForm(true);
    setFile(null);
    getTemplatesItp([]);
    setPreviewData([]);
    history.push("import-jobs");
  };

  return loading ? (
    <Loading />
  ) : (
    <Fragment>
      <Stepper
        steps={[
          { label: "Step 1" },
          { label: "Step 2" },
          { label: "Step 3" },
          { label: "Step 4 " },
        ]}
        activeStep={step}
      />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <TabContent activeTab={step.toString()}>
            <TabPane tabId="0" className="p-3">
              {step === 0 && <Step1 setFile={setFile} />}
            </TabPane>
            <TabPane tabId="1" className="p-3">
              <Step2 templates={templates} form={form} setForm={setForm} />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <h3>Preview Data</h3>
              <div
                className="table-responsive"
                style={{ maxHeight: "400px", overflow: "auto" }}
              >
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      {previewData.title &&
                        previewData.title.map((col, index) => (
                          <th key={index}>{col.title}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {previewData.content &&
                      previewData.content.map((row, index) => (
                        <tr key={index}>
                          {previewData.title.map((col, index) => (
                            <td key={index}>{row[col.key]}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <Step4 resetForm={resetForm} />
            </TabPane>
          </TabContent>
          <div
            style={{
              with: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {step != 0 && step != 3 && (
              <button
                className="buttonWizzard"
                onClick={() => {
                  if (step != 0) {
                    setStep(step - 1);
                  }
                }}
              >
                Back
              </button>
            )}
            {step != 3 && (
              <ButtonStyled
                text={step === 2 ? "Import" : "Next"}
                onClickFunction={() => {
                  stepActions();
                }}
                disabled={stepController()}
                loading={(step === 1 || step === 2) ? loadingImport : false}
              />
            )}
          </div>
        </div>
      </div>
      {errorModal && (
        <ErrorModal
          isOpen={errorModal}
          toggle={() => setErrorModal(false)}
          message={errorMessage}
          failedImports={failedImports}
        />
      )}
    </Fragment>
  );
};

export default ImportCommissions;
