import {
  SIP,
  SIP_SUCCESS,
  USERAGENT_START,
  USERAGENT_END,
  DISCONNECTED_PHONE,
} from "../actions/SipTypes";

const initialState = {
  userAgent: "user",
  result: false,
  active: false,
  disconnected: false,
};
const SipReducer = (state = initialState, Action) => {
  // console.log("action", Action);
  switch (Action.type) {
    case SIP:
      state = {
        ...state,
      };

      break;
    case SIP_SUCCESS:
      state = {
        ...state,
        userAgent: Action.payload.userAgent,
        result: Action.payload.result,
        disconnected: false,
        active: !state.active,
      };

      break;
    case USERAGENT_START:
      state = { ...state };

      break;
    case USERAGENT_END:
      state = { ...state };

      break;
    case DISCONNECTED_PHONE:
      console.log("Sessions Reducer");
      state = { ...state, disconnected: true };

      break;

    default:
      break;
  }
  return state;
};

export default SipReducer;
