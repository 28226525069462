import React, { useEffect, useState } from "react";
import CustomTable from "../../../../jsx/utils/CustomTable/CustomTable";

const ErrorsTable = ({ data }) => {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    let failedTitles = [
      { title: "State", key: "state" },
      { title: "New Policy", key: "new_policy" },
      { title: "Policy Age", key: "policy_age" },
      { title: "Import Name", key: "import_name" },
      { title: "Policy Year", key: "policy_year" },
      { title: "Reason Code", key: "reason_code" },
      { title: "Policy Number", key: "policy_number" },
      { title: "Effective Date", key: "effective_date" },
      { title: "Agent Id Number", key: "agent_id_number" },
      { title: "Policy Last Name", key: "policy_last_name" },
      { title: "Transaction Date", key: "transaction_date" },
      { title: "Commission Agent", key: "commission_amount" },
      { title: "Policy First Name", key: "policy_first_name" },
      {
        title: "Commission Amount Calculated",
        key: "commission_amount_calculated",
      },
      { title: "Reason", key: "error_message" },
    ];

    let acceptedTitles = failedTitles.filter(
      (element) => element.title !== "Reason"
    );

    let isFalied = data.every((obj) => obj.hasOwnProperty("error_message"));

    setDataTable({
      title: isFalied ? [...failedTitles] : [...acceptedTitles],
      content: [...data],
    });
  }, [data]);

  return (
    dataTable && (
      <CustomTable
        data={dataTable}
        styleCardTable={{ overflow: "auto" }}
        noPagBack={true}
        text="Errors"
      />
    )
  );
};

export default ErrorsTable;
