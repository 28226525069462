import petitionGet from "../../../../services/petitionGet";

const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))

export function searchSubAgencyFromSubAgencyId(value,element,forceUpdate,defaultAgencyname) {
  let subAgencyQuery = value ? `sub_agency_id=${value}` : subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : false;
  console.log(value)
  if(subAgencyQuery){
    petitionGet("sub-agencies", { parameter: `/${value}` })
    .then(({ data: result }) => {
      console.log("---------------------------")
      console.log(result)
      console.log("---------------------------")
        element.subAgencyName = result.result.name
        forceUpdate()
    })
    .catch((error) => { });
  }else{
    element.subAgencyName = defaultAgencyname
  }

}


