import { FileUploader } from "react-drag-drop-files";

export default function Step1({setFile}) {
  
  const fileTypes = ["csv"];

  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <div style={{with:"100%",display:"flex",justifyContent: "center"}}>
      {" "}
      <FileUploader
        handleChange={handleChange}
        onDrop={handleChange}
        name="file"
        types={fileTypes}
      />
    </div>
  );
}
