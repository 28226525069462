import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  frequency
}) => {
  const update = () => {
    modalActions(form);
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk?"Edit Carrier" :"Create Carrier"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {setForm({ ...form, name: e.target.value })}}
              value={form.name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Number of Months for Advance Commissions:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {setForm({ ...form, advance_pay_months: parseInt(e.target.value) })}}
              value={`${form.advance_pay_months}`}
              type="number"
            ></input>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.name || !form.advance_pay_months
          }
          text={form.pk?"Edit" :"Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
