import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalDelete = ({ modal, closeModal, modalActions, loading }) => {
  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>Undo Import</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        This option will reverse the import, deleting any payouts (if created)
        and will remove any transactions associated with this import. Agent
        balances will return to their original state before the import
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          onClickFunction={closeModal}
          disabled={loading}
        />
        <ButtonStyled
          loading={loading}
          text="Undo"
          onClickFunction={modalActions}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
