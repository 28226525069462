import React from "react";
import "./Stepper.css";

const Stepper = ({ steps, activeStep, style }) => {
  const getCircleClass = (index) => {
    if (index < activeStep) {
      return "completed";
    } else if (index === activeStep) {
      return "active";
    } else {
      return "disabled";
    }
  };

  return (
    <div style={style ? style : {}} className="stepper">
      {steps.map((step, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && (
              <div
                className={`line ${index <= activeStep ? "completed" : "disabled"} ${index === activeStep ? "active" : ""}`}
                style={{ width: `calc(100% / ${steps.length - 1})` }}
              ></div>
            )}
            <div className={`circle ${getCircleClass(index)}`}>
              <div className="circle-label">{index + 1}</div>
              <div className="step-label">{step.label}</div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
