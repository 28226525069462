import React, { useRef, useState, useEffect } from "react";
import { formatDate, formatHourof24at12 } from "../../utils/GetChatDate";
import getQueryString from "../../jsx/utils/getQueryString";
import petitionGet from "../../services/petitionGet";
import Loading from "../../jsx/utils/Loading";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalView from "./ModalUtility/ModalView";
import RefreshIcon from "@mui/icons-material/Refresh";
import UndoIcon from '@mui/icons-material/Undo';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ModalDelete from "./ModalUtility/ModalDelete";
import petitionDelete from "../../services/petitionDelete";
import ModalViewUndoLogs from "./ModalUtility/ModalViewUndoLogs";

const ImportJobs = () => {
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [loading, setLoading] = useState(true);
  const [allResult, setAllResult] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [modalDelete, setModalDelete] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [undoLogs, setUndoLogs] = useState([])
  const [modalUndoLogs, setModalUndoLogs] = useState(false)

  const capitalizeFirstLetter = (string) => {
    let capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    return capitalizedString;
  };

  const activeViewErrors = (e) => {
    setInfo(e);
    setModalView(true);
  };

  const activeModalUndoImport = (e) => {
    setInfo(e)
    setModalDelete(true)
  }

  const activeModalUndoLogs = (e) => {
    const agents = e?.undo_log?.agents.map((agent) => `Agent ${agent?.first_name} ${agent?.last_name}`)

    const payouts = e?.undo_log?.deleted_payouts.map((payout) => {
      const createdDate = formatDate(payout.created_at)
      const agent = payout?.agent?.first_name ? `${payout?.agent?.first_name} ${payout?.agent?.last_name}` : ""

      return `payout ${agent ? `with agent ${agent} ` : ""}created at ${createdDate}`
    })

    let data = []

    if (Array.isArray(agents) && agents?.length) {
      data = [...data, ...agents]
    }

    if (Array.isArray(payouts) && payouts?.length) {
      data = [...data, ...payouts]
    }

    setUndoLogs(data)
    setModalUndoLogs(true)
  }

  const closeModal = () => {
    setLoadingModal(false)
    setInfo(null);
    setModalView(false);
    setModalDelete(false)
    setModalUndoLogs(false)
    setUndoLogs([])
  };

  const refreshPage = () => {
    setLoadingIcon(true);
    let parameter = getQueryString(pagination.current);
    petition(parameter, true);
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const undoImport = () => {
    setLoadingModal(true)
    const jobId = info.id
    petitionDelete("undo-import", jobId)
      .then(() => {
        closeModal()
        const parameter = getQueryString(pagination.current);
        petition(parameter);
      })
      .catch((error) => {
        console.log(error)
        setLoadingModal(false)
      })
  }

  const petition = (parameter, noLoading) => {
    if (!noLoading) setLoading(true);
    petitionGet("import-jobs", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((job, i) => {
          job.date = job.created_at.slice(0, 10).replaceAll("-", "/");
          job.state = capitalizeFirstLetter(job.status);
        });
        setDataTable({
          title: [
            { title: "Date", key: "date" },
            { title: "Status", key: "state" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Error",
                icon: <VisibilityIcon />,
                handleClick: activeViewErrors,
              },
              {
                name: "Undo Import",
                icon: <UndoIcon />,
                handleClick: activeModalUndoImport,
              },
              {
                name: "Undo Logs",
                icon: <FolderCopyIcon />,
                handleClick: activeModalUndoLogs,
              },
            ],
          },
          noColumnNumber: true,
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => changePage(page),
          iconAction: [
            {
              icon: <RefreshIcon className="icons-contacts" />,
              handleClick: refreshPage,
            },
          ],
        });
        setLoading(false);
        setLoadingIcon(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {modalView && (
        <ModalView modal={modalView} closeModal={closeModal} info={info} />
      )}

      {modalDelete && (
        <ModalDelete 
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingModal}
          modalActions={undoImport}
        />
      )}

      {modalUndoLogs && (
        <ModalViewUndoLogs 
          modal={modalUndoLogs}
          closeModal={closeModal}
          undoLogs={undoLogs}
        />
      )}

      {dataTable && (
        <CustomTable
          data={dataTable}
          noClass={true}
          allResult={allResult}
          pagination={itemsPerPage.current}
          loading={loadingIcon}
        />
      )}
    </>
  );
};

export default ImportJobs;
