import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import Loading from "../../../jsx/utils/Loading";
import petitionGet from "../../../services/petitionGet";
import CustomVerticalTable from "../../../jsx/utils/CustomVerticalTable/CustomVerticalTable";

const ModalDetails = ({ modal, closeModal, info }) => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null)

  const getPolicyDetails = async () => {
    setLoading(true);
    let statesTemp = [];
    await petitionGet("states")
      .then(({ data: result }) => {
        statesTemp = result.result;
      })
      .catch((error) => console.log(error));

    await petitionGet("policy", { policy_id: info.policy.pk })
      .then(({ data: result }) => {
        result.result.name =
          result.result.first_name + " " + result.result.last_name;
        result.result.product_name = result.result.product.name;
        result.result.agent_name =
          result.result.agent.first_name + " " + result.result.agent.last_name;
        result.result.state_name = statesTemp.find(
          (state) => state.pk === result.result.state_id
        ).name;
        result.result.effective_date = result.result.effective_date.slice(0, 10)

        setDataTable({
          tbody: [
            { content: [{ key: "Name:" }, { value: "name" }] },
            { content: [{ key: "Product:" }, { value: "product_name" }] },
            { content: [{ key: "Policy Number:" }, { value: "policy_number" }] },
            {
              content: [{ key: "Premium Amount:" }, { value: "premium_amount" }],
            },
            { content: [{ key: "Agent:" }, { value: "agent_name" }] },
            { content: [{ key: "State:" }, { value: "state_name" }] },
            {
              content: [{ key: "Effective Date:" }, { value: "effective_date" }],
            },
          ],
          content: result.result,
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getPolicyDetails();
  }, []);

  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>Policy Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <CustomVerticalTable dataTable={dataTable} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Close"
          onClickFunction={closeModal}
          disabled={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetails;
