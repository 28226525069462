import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalViewUndoLogs = ({ modal, closeModal, undoLogs }) => {
  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>Undo Import</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {undoLogs.length ? (
          <>
            <p>List of deleted logs</p>
            <ul>
              {undoLogs.map((log, i) => (
                <li key={i}>{log}</li>
              ))}
            </ul>
          </>
        ) : (
          <>You currently have no logs</>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled text="Close" onClickFunction={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalViewUndoLogs;
