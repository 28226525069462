import { lazy, Suspense, useEffect } from "react";
import Index from "./jsx";
import IndexAgent from "./jsx/LoginAgent/indexAgent";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import "./css/style.css";
import petitionGet from "./services/petitionGet";
import { setUserData } from "./store/actions/AuthActions";
import { ToastContainer } from "react-toastify";
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const LoginAgency = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/LoginAgency")), 500);
  });
});

const LoginAgent = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/LoginAgent")), 500);
  });
});

const theme = createTheme();

function App(props) {
  const dispatch = useDispatch();
  const locStorage = JSON.parse(localStorage.getItem("userDetails"))

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const token = params.get("token");
    const redirect = params.get("redirect");
    
    if(!token && !redirect && window.location.pathname !== "/login-agency"){
      if (!token && !redirect && window.location.pathname !== "/login-agent") {
        checkAutoLogin(dispatch, props.history);
      } else if (!token && !redirect && window.location.pathname === "/login-agent"){
        checkAutoLogin(dispatch, props.history, true);
      }
    }
  }, [dispatch, props.history]);

  useEffect(() => {
    if (window.location.pathname === "/login" && props.isAuthenticated)
      props.history.push(!locStorage.loginAgent ? "/home" : "policies");
  }, [props.isAuthenticated]);

  useEffect(() => {
    const checkSession = () => {
      if (!localStorage.getItem("userDetails")) {
        checkAutoLogin(dispatch, props.history);
      }
    };

    window.addEventListener("storage", checkSession);
  }, []);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/login-agent" component={LoginAgent} />
      <Route path="/login-agency" component={LoginAgency} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );

  if (props.isAuthenticated) {
    if(!JSON.parse(localStorage.getItem("userDetails")).loginAgent){
      return (
        <>
          <ThemeProvider theme={theme}>
            <Suspense
              fallback={
                <div id="preloader">
                  <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                  </div>
                </div>
              }
            >
              <Index />
            </Suspense>
          </ThemeProvider>
        </>
      );
    } else {
      return (
        <>
          <ThemeProvider theme={theme}>
            <Suspense
              fallback={
                <div id="preloader">
                  <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                  </div>
                </div>
              }
            >
              <IndexAgent />
            </Suspense>
          </ThemeProvider>
        </>
      )
    }
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth,
    userAuth: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps)(App));
