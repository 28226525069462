import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  carriers,
  errorMessage,
}) => {
  const update = () => {
    modalActions(form);
  };

  const validate = (e) => {
    console.log(e);
    if (e > 100) {
      setForm({ ...form, commission_rate: 100.0 });
    } else if (e < 0) {
      setForm({ ...form, commission_rate: 0.0 });
    } else {
      setForm({ ...form, commission_rate: e });
    }
  };

  const [valueCarrier, setValueCarrier] = useState(
    form.carrierName && form.carrier_id
      ? { label: form.carrierName, value: form.carrier_id }
      : null
  );

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit Product" : "Create Product"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Product Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              value={form.name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Import Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, import_name: e.target.value });
              }}
              value={form.import_name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Carrier:</label>
          <div className="col-8">
            <Select
              onChange={(e) => {
                setForm({ ...form, carrier_id: e.value });
                setValueCarrier(e);
              }}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select Option"
              value={valueCarrier}
              // defaultValue={
              //   form
              //     ? {
              //         label: form.carrierName,
              //         value: form.carrier_id,
              //       }
              //     : {}
              // }
              isSearchable={true}
              name="carrier_id"
              options={carriers}
            />
          </div>
        </div>
        {errorMessage && (
          <p className="text-danger">{errorMessage}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={!form.name || !form.carrier_id}
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
