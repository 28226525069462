import { toast } from "react-toastify";
export const sendToastSuccess = () => {
  let defaultMessage = "✔️ Success !!"
  toast.success(defaultMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const sendToastError = (text) => {
  let defaultMessage = "✔️ Error !!"
  toast.error(text ? text : defaultMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
