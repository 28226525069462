// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.position-menu-fixed {
  position: fixed;
  height: 100%;
  z-index: 3;
  overflow: auto;
  bottom: 0;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 5s ease-in-out;
}

.menu-open {
  transform: translateX(0);
}
`, "",{"version":3,"sources":["webpack://./src/jsx/utils/MenuITP/MenuITP.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,cAAc;EACd,SAAS;EACT,MAAM;EACN,QAAQ;EACR,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".position-menu-fixed {\n  position: fixed;\n  height: 100%;\n  z-index: 3;\n  overflow: auto;\n  bottom: 0;\n  top: 0;\n  right: 0;\n  transform: translateX(100%);\n  transition: transform 5s ease-in-out;\n}\n\n.menu-open {\n  transform: translateX(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
