// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go2791332690{
    color: #dc3545 !important;
    font-size: 18px
}

.step-form-horizontal ol .go3842760039 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.step-form-horizontal ol .go433304200 span{
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    color: white !important;
}

.go433304200:before {
    background:#dc3545 !important ;
    width: 100% !important;
}

.buttonWizzard{
    background: #ccc;
    border-width: 0px;
    color: #fff;
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
    margin-right: 5px;
}

.buttonWizzard:active{
    background: #ababab;
}`, "",{"version":3,"sources":["webpack://./src/pages/ReportAgents/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB;AACJ;;AAEA;IACI,gCAAgC;IAChC,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;IAChC,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".go2791332690{\n    color: #dc3545 !important;\n    font-size: 18px\n}\n\n.step-form-horizontal ol .go3842760039 span{\n    border-color: #dc3545 !important;\n    background: #dc3545 !important;\n    color: white !important;\n}\n\n.step-form-horizontal ol .go433304200 span{\n    border-color: #dc3545 !important;\n    background: #dc3545 !important;\n    color: white !important;\n}\n\n.go433304200:before {\n    background:#dc3545 !important ;\n    width: 100% !important;\n}\n\n.buttonWizzard{\n    background: #ccc;\n    border-width: 0px;\n    color: #fff;\n    font-size: 0.875rem;\n    padding: 0.625rem 1rem;\n    margin-right: 5px;\n}\n\n.buttonWizzard:active{\n    background: #ababab;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
