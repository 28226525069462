import axios from "axios";
import json from "../config.json";
export default function petitionDelete(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails")).sub_agency_id;
  var url;

  if (token) {
    switch (key) {
      case "agents":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/agents/" + value : urlBase + "paylink360/" + api_id + "/agents/" + value;
        break;
      case "agencies":
        url = urlBase + "paylink360/" + api_id + "/agencies/" + value;
        break;
      case "carriers":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/carriers/" + value : urlBase + "paylink360/" + api_id + "/carriers/" + value;
        break;
      case "states":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/states/" + value : urlBase + "paylink360/" + api_id + "/states/" + value;
        break;
      case "products":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/products/" + value : urlBase + "paylink360/" + api_id + "/products/" + value;
        break;
      case "import-templates":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/import-templates/" + value : urlBase + "paylink360/" + api_id + "/import-templates/" + value;
        break;
      case "contracts":
        url =  sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/contracts/" + value:urlBase + "paylink360/" + api_id + "/contracts/" + value;
        break;
      case "rates":
        url = urlBase + "paylink360/" + api_id + "/commission-rates/" + value;
        break;
      case "rates-groups":
        url =  sub_agency_id ?  urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rate-groups/" + value : urlBase + "paylink360/" + api_id + "/commission-rate-groups/" + value;
        break;
      case "commission-rates":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rate-groups/" + value.group_id + "/commission-rates/" + value.rate_id:urlBase + "paylink360/" + api_id + "/commission-rate-groups/" + value.group_id + "/commission-rates/" + value.rate_id;;
        break;
      case "rate-rules":
        url =  sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rates/" + value.rate_group_id + "/rules/" + value.rate_rule_id :  urlBase + "paylink360/" + api_id + "/commission-rates/" + value.rate_group_id + "/rules/" + value.rate_rule_id;
        break;
      case "appointments":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/appointments/" +value.appoiment_id : urlBase + "paylink360/" + api_id + "/appointments/" +value.appoiment_id;;
        break;
      case "transactions":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/transactions/" + value : urlBase + "paylink360/" + api_id + "/transactions/" + value;
        break;
      case "policies":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/policies/${value}` : urlBase + `paylink360/${api_id}/policies/${value}`;
        break;
      case "payouts":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/payouts/${value}` : urlBase + `paylink360/${api_id}/payouts/${value}`;
        break;
      case "overrides":
        url =  sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/overrides/${value}` : urlBase + `paylink360/${api_id}/overrides/${value}`;
        break;
      case "api-keys":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/api_key/${value}` : urlBase + `paylink360/${api_id}/api_key/${value}`;
        break;
      case "sub-agencies":
        url = urlBase + `paylink360/${api_id}/sub-agencies/${value}`;
        break;
      case "undo-import":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/imported-commissions/${value}/undo` : urlBase + `paylink360/${api_id}/imported-commissions/${value}/undo`;
        break;
      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };
    return axios.delete(url, config);
  } else {
    window.location.href = "./profile";
  }
}
