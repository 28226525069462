import React from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import { FileUploader } from "react-drag-drop-files";

const ModalImport = ({ modal, closeModal, modalFunction, loading, file, setFile, errorMessage }) => {
  const fileTypes = ["json"];

  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>Import Rates Groups</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <FileUploader
        handleChange={handleChange}
        onDrop={handleChange}
        name="file"
        types={fileTypes}
      />
      {errorMessage && (
        <p className="text-danger">{errorMessage}</p>
      )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          onClickFunction={closeModal}
          disabled={loading}
        />
        <ButtonStyled
          text="Next"
          onClickFunction={modalFunction}
          loading={loading}
          disabled={!file}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalImport;
