import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  agencies,
  agents,
}) => {
  const update = () => {
    modalActions(form);
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit Contract" : "Create Contract"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Contact:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              value={form.name}
            ></input>
          </div>
        </div> */}
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Start date:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, start_date: e.target.value });
              }}
              value={form.start_date?.replaceAll("/", "-").substring(0, 10)}
              type="date"
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>End date:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, end_date: e.target.value });
              }}
              value={form.end_date?.replaceAll("/", "-").substring(0, 10)}
              type="date"
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Agency:</label>
          <div className="col-8">
            <select
              className="arrow-select"
              value={form.agency_id}
              onChange={(e) => {
                setForm({
                  ...form,
                  agency_id: e.target.value,
                });
              }}
            >
              <option value={null}>Select option</option>
              {agencies.map((option) => (
                <option key={option.pk} value={option.pk}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Agent:</label>
          <div className="col-8">
            <select
              className="arrow-select"
              value={form.agent_id}
              onChange={(e) => {
                setForm({
                  ...form,
                  agent_id: e.target.value,
                });
              }}
            >
              <option value={null}>Select option</option>
              {agents.map((option) => (
                <option key={option.pk} value={option.pk}>
                  {option.first_name + " " + option.last_name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={!form.start_date || !form.end_date || !form.agency_id || !form.agent_id}
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
