// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-tab {
  display: flex;
  flex-direction: row;
  height: 100%;
  /* width: calc(100% - 310px);
  position: absolute; */
  margin-bottom: 15px;
  /* min-width: 800px; */
}

.menu-tab-left {
  width: 25%;
  height: 70%;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  /* overflow: auto; */
}

.menu-tab-right {
  width: calc(75% - 10px);
  /* width: 100%; */
  height: 70%;
  background: white;
  margin-left: 10px;
  position: relative;
  overflow: auto;
}

.menu-tab-right-title {
  border-bottom: 1px solid #eee;
  padding: 10px;
}

/* @media screen and (min-width: 1400px) {
  .menu-tab {
    width: calc(100% - 360px) !important;
  }
}


@media screen and (min-width: 1611px) {
    .menu-tab {
      width: calc(100% - 410px) !important;
    }
  }
   */`, "",{"version":3,"sources":["webpack://./src/jsx/components/MenuTabs/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ;uBACqB;EACrB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;EACX,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,6BAA6B;EAC7B,aAAa;AACf;;AAEA;;;;;;;;;;;;IAYI","sourcesContent":[".menu-tab {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  /* width: calc(100% - 310px);\n  position: absolute; */\n  margin-bottom: 15px;\n  /* min-width: 800px; */\n}\n\n.menu-tab-left {\n  width: 25%;\n  height: 70%;\n  background: white;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 10px;\n  /* overflow: auto; */\n}\n\n.menu-tab-right {\n  width: calc(75% - 10px);\n  /* width: 100%; */\n  height: 70%;\n  background: white;\n  margin-left: 10px;\n  position: relative;\n  overflow: auto;\n}\n\n.menu-tab-right-title {\n  border-bottom: 1px solid #eee;\n  padding: 10px;\n}\n\n/* @media screen and (min-width: 1400px) {\n  .menu-tab {\n    width: calc(100% - 360px) !important;\n  }\n}\n\n\n@media screen and (min-width: 1611px) {\n    .menu-tab {\n      width: calc(100% - 410px) !important;\n    }\n  }\n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
