import React from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import { FileUploader } from "react-drag-drop-files";

const ModalImport = ({ modal, closeModal, modalFunction, loading, file, setFile, errorMessage }) => {
  const fileTypes = ["json"];

  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>Import Products</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FileUploader
          handleChange={handleChange}
          onDrop={handleChange}
          name="file"
          types={fileTypes}
        />
        {errorMessage && (
          <>
            {errorMessage?.error_message != null && errorMessage.error_message != '' ? <p className="text-danger">{errorMessage.error_message}</p> : null}

            {errorMessage?.error_detailed != null && errorMessage.error_detailed != '' ? <p className="text-danger">{errorMessage.error_detailed}</p> : null}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          onClickFunction={closeModal}
          disabled={loading}
        />
        <ButtonStyled
          text="Next"
          onClickFunction={modalFunction}
          loading={loading}
          disabled={!file}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalImport;
