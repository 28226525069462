import React, { Component, useContext, useEffect, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Metismenu from "metismenujs";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./sidebar.css";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );


  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const backToAdminPortal = () => {
    localStorage.removeItem("subAgencyDetails");
    window.location.href = window.location.origin + "/sub-agencies"
  }

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }, []);

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
         <li className={subAgencyItem ? "" : "d-none"}>
            <Link onClick={backToAdminPortal} to="#" className="ai-icon">
            <i className="fas fa-arrow-left"></i>
              <span style={{ fontSize: "12px" }} className="nav-text">Back To Admin Portal</span>
            </Link>
          </li>

          <li className={`${path === "home" ? "mm-active" : ""}`}>
            <Link to="home" className="ai-icon">
              <i
                className="fas fa-home"
                style={{ width: "1px !important" }}
              ></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${path === "sub-agencies" ? "mm-active" : ""} ${(sub_agency_id || subAgencyItem) ? "d-none" : ""}`}>
            <Link to="sub-agencies" className="ai-icon">
              <i className="fas fa-user"></i>
              <span className="nav-text">Sub Agencies</span>
            </Link>
          </li>
          <li className={`${path === "agents" ? "mm-active" : ""}`}>
            <Link to="agents" className="ai-icon">
              <i className="fas fa-user"></i>
              <span className="nav-text">Agents</span>
            </Link>
          </li>
          <li className={`${path === "Transactions" ? "mm-active" : ""}`}>
            <Link to="Transactions" className="ai-icon">
              <i className="fa fa-receipt"></i>
              <span className="nav-text">Transactions</span>
            </Link>
          </li>
          <li className={`${path === "policies" ? "mm-active" : ""}`}>
            <Link to="policies" className="ai-icon">
              <i className="fas fa-user-shield"></i>
              <span className="nav-text">Policies</span>
            </Link>
          </li>
          <li>
            <Link
              to="reports"
              className={`has-arrow${
                path === "proccess-payouts" || path === "payout-history"
                  ? " active-list"
                  : " disabled-list"
              }`}
            >
              <i
                className={`fas fa-chart-bar${
                  path === "proccess-payouts" || path === "payout-history"
                    ? " active-list"
                    : " disabled-list"
                }`}
              ></i>
              <span className="nav-text">Payouts</span>
            </Link>
            <ul>
              <li className={sub_agency_id ? "d-none" : ""}>
                <Link
                  className={`${path === "proccess-payouts" ? "mm-active" : ""}`}
                  to="/proccess-payouts"
                >
                  {" "}
                  Process Payouts
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "payout-history" ? "mm-active" : ""}`}
                  to="payout-history"
                >
                  {" "}
                  Payout History
                </Link>
              </li>
            </ul>
          </li>
          <li className={(subAgencyItem || sub_agency_id) ? "d-none" : ""}>
            <Link
              to="reports"
              className={`has-arrow${
                path === "import-commissions" || path === "import-jobs"
                  ? " active-list"
                  : " disabled-list"
              }`}
            >
              <i
                className={`fas fa-chart-bar${
                  path === "import-commissions" || path === "import-jobs"
                    ? " active-list"
                    : " disabled-list"
                }`}
              ></i>
              <span className="nav-text">Import</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${
                    path === "import-commissions" ? "mm-active" : ""
                  }`}
                  to="/import-commissions"
                >
                  {" "}
                  Commissions
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "import-jobs" ? "mm-active" : ""}`}
                  to="/import-jobs"
                >
                  {" "}
                  Jobs
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${path === "settings" ? "mm-active" : ""}`}>
            <Link to="settings" className="ai-icon">
              <i className="fas fa-hammer"></i>
              <span className="nav-text">Settings</span>
            </Link>
          </li>
        </MM>

        <div className="copyright">
          <p>
            <strong>ITP</strong> © 2022 All Rights Reserved
          </p>
          {/*  <p className="fs-12">
            Made with <span className="heart"></span> by Duwan :)
          </p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(SideBar));
