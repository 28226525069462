const agencyNameValidator = async (
  agent,
  defaultName,
  petition,
  subAgencyItem,
  element,
  forceUpdate,
  sub_agency_id,
) => {
  if (subAgencyItem) {
    element.agencyName = subAgencyItem.name;
    return true;
  } else if (sub_agency_id) {
    const subAgency = JSON.parse(localStorage.getItem("userDetails"));
    element.agencyName = subAgency.name;
    return true;
  } else {
    if (agent) {
      await petition("sub-agencies", { parameter: "/" + agent })
        .then(({ data: result }) => {
          element.agencyName = result.result.name;
          forceUpdate();
          return true;
        })
        .catch((err) => {
          element.agencyName = "error";
          forceUpdate();
          return true;
        });
    } else {
      element.agencyName = defaultName;
      return true;
    }
  }
};

export { agencyNameValidator };
