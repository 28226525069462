// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-width: 1400px) {
    .multiSelectITP{
        height:40px !important;
    }
  }

  @media (min-width: 1600px) {
    .multiSelectITP{
        height:45px !important;
    }
  }  

  .__react_component_tooltip.place-top {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  .__react_component_tooltip.place-bottom {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }`, "",{"version":3,"sources":["webpack://./src/jsx/utils/CustomTable/styles.css"],"names":[],"mappings":";AACA;IACI;QACI,sBAAsB;IAC1B;EACF;;EAEA;IACE;QACI,sBAAsB;IAC1B;EACF;;EAEA;IACE,sBAAsB;IACtB,oCAAoC;EACtC;;EAEA;IACE,sBAAsB;IACtB,oCAAoC;EACtC","sourcesContent":["\n@media (min-width: 1400px) {\n    .multiSelectITP{\n        height:40px !important;\n    }\n  }\n\n  @media (min-width: 1600px) {\n    .multiSelectITP{\n        height:45px !important;\n    }\n  }  \n\n  .__react_component_tooltip.place-top {\n    background-color: #fff;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n  }\n  \n  .__react_component_tooltip.place-bottom {\n    background-color: #fff;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
