import axios from "axios";
import json from "../config.json";
export default function petitionPatch(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBaseAgent = json.prod ? json.agent.prod : json.agent.dev;
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails")).sub_agency_id;
  var url;
  var data;
  if (token) {
    switch (key) {
      case "agencies":
        url = urlBase + "paylink360/" + api_id + "/agencies/" + value.agency_id;
        data = value.data;
        break;
      case "agents":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/agents/" + value.agent_id : urlBase + "paylink360/" + api_id + "/agents/" + value.agent_id;
        data = value.data;
        break;
      case "carriers":
        url = sub_agency_id ?  urlBase + "paylink360/" + api_id + "/sub-agencies/carriers/" + value.carrier_id : urlBase + "paylink360/" + api_id + "/carriers/" + value.carrier_id;
        data = value.data;
        break;
      case "states":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/states/" + value.state_id : urlBase + "paylink360/" + api_id + "/states/" + value.state_id;
        data = value.data;
        break;
      case "products":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/products/" + value.product_id : urlBase + "paylink360/" + api_id + "/products/" + value.product_id;
        data = value.data;
        break;
      case "import-templates":
        url = sub_agency_id ?  urlBase + "paylink360/" + api_id + "/sub-agencies/import-templates/" + value.template_id :  urlBase + "paylink360/" + api_id + "/import-templates/" + value.template_id;
        data = value.data;
        break;
      case "contracts":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/contracts/" + value.contract_id : urlBase + "paylink360/" + api_id + "/contracts/" + value.contract_id;
        data = value.data;
        break;
      case "commission-rates":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agen /" + value.group_id + "/commission-rates/" + value.rate_id:urlBase + "paylink360/" + api_id + "/commission-rate-groups/" + value.group_id + "/commission-rates/" + value.rate_id;
        data = value.data;
        break;
      case "commission-rate-groups":
        url =  sub_agency_id ?  urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rate-groups/" + value.rate_id : urlBase + "paylink360/" + api_id + "/commission-rate-groups/" + value.rate_id;
        data = value.data;
        break;
      case "rate-rules":
        url =  sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/commission-rates/" + value.rate_group_id + "/rules/" + value.rate_rule_id :  urlBase + "paylink360/" + api_id + "/commission-rates/" + value.rate_group_id + "/rules/" + value.rate_rule_id;

        data = value.data;
        break;
      case "appointments":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/appointments/" + value.appointment_id : urlBase + "paylink360/" + api_id + "/appointments/" + value.appointment_id;
        data = value.data;
        break;
      case "transactions":
        url = sub_agency_id ? urlBase + "paylink360/" + api_id + "/sub-agencies/transactions/" + value.transaction_id : urlBase + "paylink360/" + api_id + "/transactions/" + value.transaction_id;
        data = value.data;
        break;
      case "policies":
        url = sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/policies/${value.policies_id}` :  urlBase + `paylink360/${api_id}/policies/${value.policies_id}`;
        data = value.data;
        break;
      case "account-details":
        url =  sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agency` : urlBase + `paylink360/${api_id}`;
        data = value.data;
        break;
      case "overrides":
        url =  sub_agency_id ? urlBase + `paylink360/${api_id}/sub-agencies/overrides/${value.override_id}` : urlBase + `paylink360/${api_id}/overrides/${value.override_id}`;
        data = value.data;
        break;
      case "agent-details":
        url = urlBaseAgent + `paylink360/${api_id}/agent`;
        data = value.data;
        break;
      case "agent-details":
        url = urlBaseAgent + `paylink360/${api_id}/agent`;
        data = value.data;
        break;

      case "sub-agencies":
        url = urlBase + `paylink360/${api_id}/sub-agencies/${value?.subAgencyId}`;
        data = value.data;
        break;

      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.patch(url, data, config);
  } else {
    window.location.href = "./profile";
  }
}
