import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Dropdown, Spinner, Form } from "react-bootstrap";
import { Button } from "reactstrap";
import styled from "styled-components";
import Loading from "../../components/Loading/Loading";
import Switch from "react-switch";
import DropDownMultiSelect from "./DropDownMultiSelect";
import SelectCheckbox from "./SelectCheckbox";
import PaginationITP from "../../utils/Pagination";
import ReactTooltip from "react-tooltip";
import ButtonStyled from "../ButtonStyled";
import ButtonHover from "../ButtonHover";
import useScreenSize from "../../hooks/useScreenSize";
import "./CustomTable.css";
import BulkActions from "../BulkActions/BulkActions";

const CustomTable = ({
  removeSearchBar,
  data,
  text,
  size,
  bordered,
  pagination: paginationData,
  responsive,
  listKeys,
  defaultValue,
  noMargin,
  noCard,
  noClass,
  switchComponent,
  handleChange,
  loadingDownloadCall,
  dataDownload,
  showMorefilters,
  allResult,
  changePage,
  styleCardTable,
  noOverflow,
  loadingMenu,
  noPagBack,
  loading: loadingExternal,
  loadingIcon,
  clickRow,
  columnCheckboxs,
  selectedRows,
  setSelectedRows,
  selectAllRows,
  setSelectAllRows,
  module,
}) => {
  /* Example of Data  (content is array of endpoint)

  OBLIGATORIO:
   *title  (define los nombres de las columnas y a que valores entra)
   *content (lista de valores)

  OPCIONALES:

   *actions (para opciones de eliminar o editar)
   *search (funcion de busqueda en base a los campos del title)
   *addButton (para aquellas tablas que requieren un boton de agregas mas )


  data: {
          title: [
            {title: "Presence Id", key: "presence_id"},
            {title: "First Name", key: "first_name"},
            {title: "Last Name", key: "last_name"},
          ],
          content: [...result.result],
          actions: {
            title: "Devices",
            content: [
              {
                icon: <DescriptionIcon />,  //Svg icon
                handleClick: handleOpenDevicesModal,  //Function
              },
            ],
          },
          search:{
          label:"Label del input Search"
          },
          addButton: {
           label: "Add Menu",
                        handleClick: () => { setModalAdd(true) }
          }


        }


   Pagination

   pagination por default = 10
   responsive por default = true
   size por default = "md"


   loading  true para activarlo, false para desactivarlo0

    <CustomTable loading={false} responsive={true} pagination={3} bordered={true} size="md" data={data} />


 */
  const { width, height } = useScreenSize();
  const cantPagination = paginationData || 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [search, setSearch] = useState("");
  const [dataFilter, setDataFilter] = useState([]);
  const [selector, setSelector] = useState(false);
  const [paginationITP, setPaginationITP] = useState(cantPagination);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(data?.search?.defaultValue || defaultValue || "")

  const optionsPagination = [
    {
      label: "10",
      value: "10",
      valueCheck: "10",
      defaultValue: paginationData === "10" ? true : false,
    },
    {
      label: "50",
      value: "50",
      valueCheck: "50",
      defaultValue: paginationData === "50" ? true : false,
    },
    {
      label: "100",
      value: "100",
      valueCheck: "100",
      defaultValue: paginationData === "100" ? true : false,
    },
  ];

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  useEffect(() => {
    if (data) {
      setDataFilter(data.content);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    let data = {
      target: {
        value: defaultValue,
      },
    };
    if (!selector && defaultValue) onChangeSearch(data);
  }, [search]);

  useEffect(() => {
    if (!loading) {
      window.scrollTo(0, scrollPosition);
    }
  }, [loading, scrollPosition]);

  const onChangeSearch = (e) => {
    setSelector(true);
    setSearch(e.target.value);

    let dataFilterCopy = [];
    if (listKeys) {
      listKeys?.map((element) => {
        data.content.filter((elementTwo) => {
          if (
            typeof elementTwo[element] === "string" &&
            elementTwo[element]
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) &&
            !dataFilterCopy.find(
              (elementThree) => elementThree.id === elementTwo.id
            )
          ) {
            dataFilterCopy.push(elementTwo);
          }
        });
      });
    } else {
      data.title?.map((element) => {
        data.content.filter((elementTwo) => {
          if (
            typeof elementTwo[element.key] === "string" &&
            elementTwo[element.key]
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase()) &&
            !dataFilterCopy.find(
              (elementThree) => elementThree.id === elementTwo.id
            )
          ) {
            dataFilterCopy.push(elementTwo);
          }
        });
      });
    }

    if (e.target.value === "") return setDataFilter(data.content);

    setDataFilter(dataFilterCopy);
  };

  const handleDeleteSearch = () => {
    setSearch("");
    setDataFilter(data.content);
  };

  const onClickNext = () => {
    if (pagination < data.content.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };

  const validViewColumn = (arr) => {
    return arr?.filter(
      (el) =>
        el.viewColumn === undefined ||
        el.viewColumn == null ||
        el.viewColumn === true
    );
  };

  const checkedHeaderCheckbox = () => {
    if (!selectAllRows) {
      return dataFilter.every((element) => selectedRows.includes(element.pk))
    } else {
      return true
    }
  }

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      let currentRows = selectedRows;
      let newRows = dataFilter.map((element) => element.pk);
      let joinedArrays = [...currentRows, ...newRows];

      let deletedDuplicateElements = [...new Set(joinedArrays)];
      setSelectedRows(deletedDuplicateElements);
    } else {
      let newRows = selectedRows;
      let deleteRows = dataFilter;

      deleteRows.forEach((element) => {
        let index = newRows.indexOf(element.pk);
        if (index !== -1) {
          newRows.splice(index, 1);
        }
      });
      setSelectedRows([...newRows]);
    }
  }

  const checkedRowCheckbox = (i) => {
    if (!selectAllRows) {
      return selectedRows.includes(dataFilter[i].pk)
    } else {
      return true
    }
  }

  const handleCheckRow = (e, rowIndex) => {
    e.stopPropagation()
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedRows((prevSelectedRows) => [
        ...prevSelectedRows,
        dataFilter[rowIndex].pk,
      ]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== dataFilter[rowIndex].pk)
      );
    }
  }

  const toogleSelectOrNotAllContacs = (e) => {
    e.preventDefault();

    if (selectAllRows) {
      setSelectedRows([]);
    }

    setSelectAllRows(!selectAllRows);
  };

  if (data) {
    return (
      <div>
        {/* <style type="text/css">
          {`
            .table-respon {
              overflow-x: visible !important;
            }
          `}
        </style> */}
        {/* <Card body className="m-0"> */}
        {/* Tools Options */}
        {(data?.search ||
          data.addButton ||
          data.showMorefilters ||
          data.itemsPerPage) &&
          !data.onlyButtonTextBox && (
            <>
              <div
                style={{
                  minHeight: "50px",
                  background: "white",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                <div className="d-flex">
                  {data.showMorefilters && (
                    <Button color="link" onClick={() => data.showMorefilters()}>
                      Filters{" "}
                      {data.activeFilters &&
                        Object.keys(data.activeFilters).length > 0 && (
                          <>
                            <span data-tip data-for="text1">{`(${
                              Object.keys(data.activeFilters).length
                            })`}</span>
                            {data.activeFilters &&
                              Object.keys(data.activeFilters).length > 0 && (
                                <ReactTooltip id="text1">
                                  {Object.keys(data.activeFilters).map(
                                    (key) => (
                                      <div
                                        style={{
                                          gap: "10px",
                                        }}
                                        className="d-flex aliggn-items-center"
                                        key={key}
                                      >
                                        <label style={{ color: "black" }}>
                                          {key}:
                                        </label>
                                        <label style={{ color: "black" }}>
                                          {data.activeFilters[key]}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </ReactTooltip>
                              )}
                          </>
                        )}
                    </Button>
                  )}

                  {data.iconAction && Array.isArray(data.iconAction) && (
                    
                    <div className="d-flex">
                      
                      {data.iconAction.map((element, i) => (
                        <div
                          key={i}
                          className="d-flex flex-column"
                          style={element.style || {}}
                        >
                          {element.name && (
                            <label className="mb-0">{element.name}</label>
                          )}
                          {element.icon &&
                            (loadingIcon !== element.icon ? (
                              <div
                                style={
                                  !element.name
                                    ? { marginTop: "10px", marginLeft: "8px" }
                                    : {}
                                }
                                onClick={() => element.handleClick(element)}
                              >
                                {element.icon}
                              </div>
                            ) : (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ))}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* {data.iconAction && (
                    <div
                      style={data.iconAction.style || {}}
                      className="d-flex flex-column"
                    >
                      {data.iconAction.name && (
                        <label className="mb-0">{data.iconAction.name}</label>
                      )}
                      {data.iconAction.icon &&
                        (!loadingIcon ? (
                          data.iconAction.icon
                        ) : (
                          <Spinner
                            // style={{
                            //   marginLeft: "40px",
                            // }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ))}
                    </div>
                  )} */}
                </div>
                <div className="d-flex">
                  {data.viewMultiSelect === true && (
                    <div style={{ marginRight: "10px" }}>
                      <DropDownMultiSelect
                        options={data.title}
                        checkFunction={(e) => data.check(e, data)}
                      />
                    </div>
                  )}

                  {data.itemsPerPage && (
                    <div style={{ marginRight: "10px" }}>
                      <SelectCheckbox
                        options={optionsPagination}
                        checkFunction={(e) => {
                          data.itemsPerPage(e);
                        }}
                      />
                    </div>
                  )}

                  {data.addButton && (
                    <div
                      className={`${
                        data?.search && !removeSearchBar ? "6" : "12"
                      }`}
                    >
                      {!data.addButton.dropdown ? (
                        <ButtonStyled
                          onClickFunction={data.addButton.handleClick}
                          style={{ height: "50px !important" }}
                          icon={
                            <i
                              style={{ marginRight: "4px" }}
                              className="fas fa-plus"
                            ></i>
                          }
                          text={data.addButton.label}
                        />
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary btn-square"
                            className="dropdown-toggle"
                          >
                            {
                              <i
                                style={{ marginRight: "4px" }}
                                className="fas fa-plus"
                              ></i>
                            }{" "}
                            {data.addButton.dropdown.name}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {data.addButton.dropdown.options.map(
                              (element, i) => (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => element.handleClick()}
                                >
                                  {element.name}
                                </Dropdown.Item>
                              )
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div
                className="d-flex justify-content-between"
                style={{ width: "100%", marginBottom: "20px" }}
              >
                {data?.search && !removeSearchBar && (
                  <div className="col-6 col-lg-6">
                    <label
                      htmlFor="search"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "10px",
                        left: "20px",
                      }}
                    >
                      <i className="fas fa-magnifying-glass"></i>
                    </label>
                    <input
                      className="form-control"
                      id="search"
                      name="search"
                      onChange={
                        data?.search?.filterBackend
                          ? (e) => {
                            setSearchValue(e.target.value)
                            data?.search?.onChange(e)
                          }
                          : onChangeSearch
                      }
                      placeholder={data?.search?.label}
                      value={searchValue}
                      type="text"
                      style={{
                        paddingLeft: "40px",
                        borderRadius: "3rem",
                        height: "45px",
                      }}
                    />

                    <ButtonHover
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "45px",
                        position: "absolute",
                        right: "20px",
                        bottom: "0",
                      }}
                      onClick={handleDeleteSearch}
                    >
                      <i className="fas fa-xmark"></i>
                    </ButtonHover>
                  </div>
                )}
              </div>
            </>
          )}

        {data.onlyButtonTextBox && (
          <div
            style={{
              minHeight: "50px",
              background: "white",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              marginBottom: "20px",
            }}
            className="d-flex justify-content-between"
          >
            <div className="col-6 col-lg-6">
              <label
                htmlFor="search"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  left: "20px",
                }}
              >
                <i className="fas fa-magnifying-glass"></i>
              </label>
              <input
                className="form-control"
                id="search"
                name="search"
                onChange={
                  data.onlyButtonTextBox.search.filterBackend
                    ? data.onlyButtonTextBox.search.onChange
                    : onChangeSearch
                }
                placeholder={data.onlyButtonTextBox.search.label}
                defaultValue={
                  data.onlyButtonTextBox.search.defaultValue || defaultValue
                }
                type="text"
                style={{
                  paddingLeft: "40px",
                  borderRadius: "3rem",
                  height: "45px",
                }}
              />

              <ButtonHover
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "45px",
                  position: "absolute",
                  right: "20px",
                  bottom: "0",
                }}
                onClick={handleDeleteSearch}
              >
                <i className="fas fa-xmark"></i>
              </ButtonHover>
            </div>
            <div
              className={`${
                data.onlyButtonTextBox.search && !removeSearchBar ? "6" : "12"
              }`}
            >
              <ButtonStyled
                onClickFunction={data.onlyButtonTextBox.addButton.handleClick}
                style={{ height: "50px !important" }}
                icon={
                  <i style={{ marginRight: "4px" }} className="fas fa-plus"></i>
                }
                text={data.onlyButtonTextBox.addButton.label}
              />
            </div>
          </div>
        )}

        {/* Table of data */}
        {dataFilter?.length === 0 ? (
          loading ? (
            <Loading />
          ) : (
            <Card body>
              <h3 style={{ textAlign: "center", marginTop: "10px" }}>
                You currently have no {text ? text : "records"}
              </h3>
            </Card>
          )
        ) : (
          <>
            {noCard && !noPagBack && (
              <Row>
                <Col
                  className={`${
                    data.title.length === 1 ? "" : "custom-card-table"
                  }`}
                  lg={12}
                >
                  <Table
                    hover
                    style={{ marginTop: `${noMargin ? "" : "24px"}` }}
                  >
                    <thead>
                      <tr>
                        {/* {!data.noColumnNumber && <th className="width80">#</th>} */}
                        {validViewColumn(data.title)?.map((element, i) => (
                          <th key={i}>{element.title}</th>
                        ))}
                        {data.switch && <th>{data.switch.title}</th>}
                        {data.iconActions && <th>{data.iconActions.title}</th>}
                        {data.actions && <th>{data.actions.title}</th>}
                      </tr>
                    </thead>
                    <tbody
                    // className={`${
                    //   dataFilter.length === 1 && !noClass ? "h-res" : ""
                    // }`}
                    >
                      {dataFilter?.map((element, i) => {
                        if (
                          (i < pagination &&
                            i >= pagination - cantPagination) ||
                          !pagination
                        ) {
                          return (
                            <tr key={i}>
                              {/* {!data.noColumnNumber && (
                                <td style={{ padding: "0.5rem 0.5rem" }}>
                                  {i + 1}
                                </td>
                              )} */}
                              {validViewColumn(data.title)?.map(
                                (elementTwo, index) => (
                                  <td
                                    // className="text-left"
                                    style={{ padding: "0.5rem 0.5rem" }}
                                    key={index}
                                  >
                                    {elementTwo.type === "dropdown" ? (
                                      <select
                                        onChange={(e) => {
                                          elementTwo.onHandleClick(
                                            data,
                                            element["row"],
                                            e.target.value
                                          );
                                        }}
                                        className="arrow-select"
                                        value={element[elementTwo.key]}
                                      >
                                        <option value=""> Select One </option>
                                        {elementTwo.options?.map(
                                          (option, index2) => (
                                            <option
                                              key={index2}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor: elementTwo.status
                                            ? element[elementTwo.key][
                                                elementTwo.colorKey
                                              ]
                                            : "",
                                          display: elementTwo.display
                                            ? elementTwo.display
                                            : "",
                                          justifyContent:
                                            elementTwo.justifyContent
                                              ? elementTwo.justifyContent
                                              : "",
                                          color: elementTwo.color
                                            ? elementTwo.color
                                            : "",
                                        }}
                                      >
                                        {elementTwo.secondKey
                                          ? `${
                                              element[elementTwo.key][
                                                elementTwo.secondKey
                                              ]
                                            }`
                                          : `${element[elementTwo.key]}`}
                                      </div>
                                    )}
                                  </td>
                                )
                              )}
                              {data.switch && (
                                <td style={{ padding: "0.5rem 0.5rem" }}>
                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    className="mr-1"
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() => handleChange(element)}
                                    height={24}
                                    width={48}
                                    checked={switchComponent}
                                  />
                                </td>
                              )}

                              {data.switchComponent && (
                                <td>
                                  {data.switchComponent.content?.map(
                                    (elementTwo) => (
                                      <>
                                        {elementTwo.type === "component" &&
                                          elementTwo.component(element)}
                                        {elementTwo.type !== "component" &&
                                          elementTwo.switch && (
                                            <Switch
                                              uncheckedIcon={<Offsymbol />}
                                              className="mr-1"
                                              checkedIcon={<OnSymbol />}
                                              onColor="#626ed4"
                                              onChange={() => {
                                                elementTwo.handleClick(element);
                                              }}
                                              height={24}
                                              width={48}
                                              checked={
                                                element[
                                                  `${elementTwo.keySwitch}`
                                                ]
                                              }
                                            />
                                          )}
                                      </>
                                    )
                                  )}
                                </td>
                              )}

                              {data.iconActions && (
                                <td>
                                  {data.iconActions.content?.map(
                                    (elementTwo, index) => (
                                      <>
                                        {loadingDownloadCall &&
                                        dataDownload.pk === element.pk ? (
                                          <Spinner
                                            style={{
                                              marginLeft: "40px",
                                            }}
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <ButtonHover
                                            key={index}
                                            style={{
                                              margin:
                                                data.iconActions.content
                                                  .length === 1
                                                  ? "0 auto"
                                                  : "0",
                                            }}
                                            onClick={() => {
                                              elementTwo.handleClick(element);
                                            }}
                                          >
                                            {elementTwo.icon}
                                          </ButtonHover>
                                        )}
                                      </>
                                    )
                                  )}
                                </td>
                              )}

                              {data.actions && (
                                <td style={{ padding: "0.5rem 0.5rem" }}>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant=""
                                      className="btn btn-primary tp-btn-light sharp i-false"
                                    >
                                      <svg
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        version="1.1"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          ></rect>
                                          <circle
                                            fill="#000000"
                                            cx="5"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="12"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                          <circle
                                            fill="#000000"
                                            cx="19"
                                            cy="12"
                                            r="2"
                                          ></circle>
                                        </g>
                                      </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                      style={{
                                        transform: "translate(-44px, -35px)",
                                      }}
                                    >
                                      {data.actions.content?.map(
                                        (elementTwo, index) => (
                                          <Dropdown.Item
                                            key={index}
                                            onClick={() =>
                                              elementTwo.handleClick(element)
                                            }
                                          >
                                            {elementTwo.icon} {elementTwo.name}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              )}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}

            {!noCard && !noPagBack && (
              <Row>
                <Col lg={12}>
                  {columnCheckboxs?.actions && (
                    <div className="mb-4">
                      <BulkActions 
                        name={columnCheckboxs?.name}
                        actions={columnCheckboxs?.actions}
                      />
                    </div>
                  )} 

                  {selectedRows && selectedRows.length > 0 && (
                    <div className="d-flex align-items-center gap-2">
                      <label
                        style={{ marginTop: "0.7rem", marginLeft: "2rem" }}
                        className="itp-custom-table-subtitle-card"
                      >
                        You have selected {!selectAllRows ? selectedRows.length : allResult.item_count} {" "} {module}
                      </label>
                      <a className={allResult.item_count < 2 ? "d-none" : "select-all"} href="#" onClick={toogleSelectOrNotAllContacs}>
                        {selectAllRows ? "Unselect" : "Select"} all {allResult.item_count} {module}
                      </a>
                   </div>
                  )}

                  <Card
                    style={
                      (width < 500 || styleCardTable) && !noOverflow
                        ? { overflow: "auto" }
                        : {}
                    }
                  >
                    <Card.Body>
                      {loading || loadingExternal ? (
                        <Loading />
                      ) : (
                        <Table
                          hover
                          style={{ marginTop: `${noMargin ? "" : "24px"}` }}
                        >
                          <thead>
                            <tr>
                              {columnCheckboxs && (
                                <Form.Check
                                  className="mt-1" 
                                  type="checkbox"
                                  checked={checkedHeaderCheckbox()}
                                  onChange={handleCheckAll}
                                  disabled={selectAllRows}
                                />
                              )}
                              {validViewColumn(data.title)?.map(
                                (element, i) => (
                                  <th key={i}>{element.title}</th>
                                )
                              )}
                              {data.switch && <th>{data.switch.title}</th>}
                              {data.switchComponent && (
                                <th>{data.switchComponent.title}</th>
                              )}
                              {data.iconActions && (
                                <th>{data.iconActions.title}</th>
                              )}
                              {data.actions && <th>{data.actions.title}</th>}
                            </tr>
                          </thead>
                          <tbody
                            className={`${
                              dataFilter?.length === 1 && !noClass ? "" : ""
                            }`}
                          >
                            {dataFilter?.map((element, i) => (
                              <tr
                                onClick={
                                  data.clickRow
                                    ? () => data.clickRow(element)
                                    : () => {}
                                }
                                key={i}
                                style={
                                  data.clickRow ? { cursor: "pointer" } : {}
                                }
                              >
                                {/* {!data.noColumnNumber && (
                                  <td style={{ padding: "0.5rem 0.5rem" }}>
                                    {i + 1}
                                  </td>
                                )} */}

                                {columnCheckboxs && (
                                  <Form.Check 
                                    className="mt-1"
                                    type="checkbox"
                                    checked={checkedRowCheckbox(i)}
                                    onChange={(e) => handleCheckRow(e, i)}
                                    disabled={selectAllRows}
                                  />
                                )}

                                {validViewColumn(data.title)?.map(
                                  (elementTwo, index) => (
                                    <td
                                      // className="text-left"
                                      style={{ padding: "0.5rem 0.5rem" }}
                                      key={index}
                                    >
                                      {elementTwo.type === "dropdown" ? (
                                        <select
                                          onChange={(e) => {
                                            elementTwo.onHandleClick(
                                              data,
                                              element["row"],
                                              e.target.value
                                            );
                                          }}
                                          className="arrow-select"
                                          value={element[elementTwo.key]}
                                        >
                                          {console.log(
                                            "---------->",
                                            elementTwo
                                          )}

                                          <option value=""> Select One </option>
                                          {elementTwo.options?.map(
                                            (option, index2) => (
                                              <option
                                                key={index2}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      ) : elementTwo.link === true ? (
                                        <a
                                          href=""
                                          onClick={(e) => {
                                            e.preventDefault();
                                            data.clickItem(element);
                                          }}
                                        >
                                          {elementTwo.secondKey
                                            ? `${
                                                element[elementTwo.key][
                                                  elementTwo.secondKey
                                                ]
                                              }`
                                            : `${element[elementTwo.key]}`}
                                        </a>
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor: elementTwo.status
                                              ? element[elementTwo.key][
                                                  elementTwo.colorKey
                                                ]
                                              : "",
                                            display: elementTwo.display
                                              ? elementTwo.display
                                              : "",
                                            justifyContent:
                                              elementTwo.justifyContent
                                                ? elementTwo.justifyContent
                                                : "",
                                            color: elementTwo.color
                                              ? elementTwo.color
                                              : "",
                                          }}
                                        >
                                          {elementTwo.secondKey
                                            ? `${
                                                element[elementTwo.key][
                                                  elementTwo.secondKey
                                                ]
                                              }`
                                            : `${element[elementTwo.key]}`}
                                        </div>
                                      )}
                                    </td>
                                  )
                                )}
                                {data.switch && (
                                  <td style={{ padding: "0.5rem 0.5rem" }}>
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      className="mr-1"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={() => handleChange(element)}
                                      height={24}
                                      width={48}
                                      checked={switchComponent}
                                    />
                                  </td>
                                )}

                                {data.switchComponent && (
                                  <td>
                                    {data.switchComponent.content?.map(
                                      (elementTwo) => (
                                        <>
                                          {elementTwo.type === "component" &&
                                            elementTwo.component(element)}
                                          {elementTwo.type !== "component" &&
                                            elementTwo.switch && (
                                              <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                className="mr-1"
                                                checkedIcon={<OnSymbol />}
                                                onColor="#626ed4"
                                                onChange={() => {
                                                  elementTwo.handleClick(
                                                    element
                                                  );
                                                }}
                                                height={24}
                                                width={48}
                                                checked={
                                                  element[
                                                    `${elementTwo.keySwitch}`
                                                  ]
                                                }
                                              />
                                            )}
                                        </>
                                      )
                                    )}
                                  </td>
                                )}

                                {data.iconActions && (
                                  <td>
                                    {data.iconActions.content?.map(
                                      (elementTwo, index) => (
                                        <>
                                          {loadingDownloadCall &&
                                          dataDownload.pk === element.pk ? (
                                            <Spinner
                                              style={{
                                                marginLeft: "40px",
                                              }}
                                              as="span"
                                              animation="grow"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <ButtonHover
                                              key={index}
                                              style={{
                                                margin:
                                                  data.iconActions.content
                                                    .length === 1
                                                    ? "0 auto"
                                                    : "0",
                                              }}
                                              onClick={() => {
                                                elementTwo.handleClick(element);
                                              }}
                                            >
                                              {elementTwo.icon}
                                            </ButtonHover>
                                          )}
                                        </>
                                      )
                                    )}
                                  </td>
                                )}

                                {data.actions && (
                                  <>
                                    {loadingMenu === element ? (
                                      <td>
                                        <Spinner
                                          style={{
                                            marginLeft: "10px",
                                            width: "24px",
                                            height: "24px",
                                          }}
                                          animation="grow"
                                        />
                                      </td>
                                    ) : (
                                      <td style={{ padding: "0.5rem 0.5rem" }}>
                                        <Dropdown onClick={(event) => event.stopPropagation()}>
                                          <Dropdown.Toggle
                                            variant=""
                                            className="btn btn-primary tp-btn-light sharp i-false"
                                          >
                                            <svg
                                              width="24px"
                                              height="24px"
                                              viewBox="0 0 24 24"
                                              version="1.1"
                                            >
                                              <g
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <rect
                                                  x="0"
                                                  y="0"
                                                  width="24"
                                                  height="24"
                                                ></rect>
                                                <circle
                                                  fill="#000000"
                                                  cx="5"
                                                  cy="12"
                                                  r="2"
                                                ></circle>
                                                <circle
                                                  fill="#000000"
                                                  cx="12"
                                                  cy="12"
                                                  r="2"
                                                ></circle>
                                                <circle
                                                  fill="#000000"
                                                  cx="19"
                                                  cy="12"
                                                  r="2"
                                                ></circle>
                                              </g>
                                            </svg>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu
                                            style={{
                                              transform:
                                                "translate(-44px, -35px)",
                                            }}
                                          >
                                            {data.actions.content?.map(
                                              (elementTwo, index) => (
                                                <Dropdown.Item
                                                  key={index}
                                                  onClick={(event) => {
                                                    event.stopPropagation()
                                                    elementTwo.handleClick(element)
                                                    }
                                                  }
                                                >
                                                  {elementTwo.icon}{" "}
                                                  {elementTwo.name}
                                                </Dropdown.Item>
                                              )
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    )}
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}

            {noPagBack && (
              <Row>
                <Col lg={12}>
                  <Card
                    style={
                      (width < 500 || styleCardTable) && !noOverflow
                        ? { overflow: "auto" }
                        : {}
                    }
                  >
                    <Card.Body>
                      {loading ? (
                        <Loading />
                      ) : (
                        <Table
                          hover
                          style={{ marginTop: `${noMargin ? "" : "24px"}` }}
                        >
                          <thead>
                            <tr>
                              {/* {!data.noColumnNumber && <th>#</th>} */}
                              {validViewColumn(data.title)?.map(
                                (element, i) => (
                                  <th key={i}>{element.title}</th>
                                )
                              )}
                              {data.switch && <th>{data.switch.title}</th>}
                              {data.switchComponent && (
                                <th>{data.switchComponent.title}</th>
                              )}
                              {data.iconActions && (
                                <th>{data.iconActions.title}</th>
                              )}
                              {data.actions && <th>{data.actions.title}</th>}
                            </tr>
                          </thead>
                          <tbody
                            className={`${
                              dataFilter?.length === 1 && !noClass ? "" : ""
                            }`}
                          >
                            {dataFilter.map((element, i) => {
                              if (
                                (i < pagination &&
                                  i >= pagination - cantPagination) ||
                                !pagination
                              ) {
                                return (
                                  <tr key={i}>
                                    {/* {!data.noColumnNumber && (
                                      <td style={{ padding: "0.5rem 0.5rem" }}>
                                        {i + 1}
                                      </td>
                                    )} */}

                                    {validViewColumn(data.title)?.map(
                                      (elementTwo, index) => (
                                        <td
                                          // className="text-left"
                                          style={{ padding: "0.5rem 0.5rem" }}
                                          key={index}
                                        >
                                          {elementTwo.type === "dropdown" ? (
                                            <select
                                              onChange={(e) => {
                                                elementTwo.onHandleClick(
                                                  data,
                                                  element["row"],
                                                  e.target.value
                                                );
                                              }}
                                              className="arrow-select"
                                              value={element[elementTwo.key]}
                                            >
                                              {console.log(
                                                "---------->",
                                                elementTwo
                                              )}

                                              <option value="">
                                                {" "}
                                                Select One{" "}
                                              </option>
                                              {elementTwo.options?.map(
                                                (option, index2) => (
                                                  <option
                                                    key={index2}
                                                    value={option.value}
                                                  >
                                                    {option.label}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          ) : elementTwo.link === true ? (
                                            <a
                                              href=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                data.clickItem(element);
                                              }}
                                            >
                                              {elementTwo.secondKey
                                                ? `${
                                                    element[elementTwo.key][
                                                      elementTwo.secondKey
                                                    ]
                                                  }`
                                                : `${element[elementTwo.key]}`}
                                            </a>
                                          ) : (
                                            <div
                                              style={{
                                                backgroundColor:
                                                  elementTwo.status
                                                    ? element[elementTwo.key][
                                                        elementTwo.colorKey
                                                      ]
                                                    : "",
                                                display: elementTwo.display
                                                  ? elementTwo.display
                                                  : "",
                                                justifyContent:
                                                  elementTwo.justifyContent
                                                    ? elementTwo.justifyContent
                                                    : "",
                                                color: elementTwo.color
                                                  ? elementTwo.color
                                                  : "",
                                              }}
                                            >
                                              {elementTwo.secondKey
                                                ? `${
                                                    element[elementTwo.key][
                                                      elementTwo.secondKey
                                                    ]
                                                  }`
                                                : `${element[elementTwo.key]}`}
                                            </div>
                                          )}
                                        </td>
                                      )
                                    )}
                                    {data.switch && (
                                      <td style={{ padding: "0.5rem 0.5rem" }}>
                                        <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          className="mr-1"
                                          checkedIcon={<OnSymbol />}
                                          onColor="#626ed4"
                                          onChange={() => handleChange(element)}
                                          height={24}
                                          width={48}
                                          checked={switchComponent}
                                        />
                                      </td>
                                    )}

                                    {data.switchComponent && (
                                      <td>
                                        {data.switchComponent.content?.map(
                                          (elementTwo) => (
                                            <>
                                              {elementTwo.type ===
                                                "component" &&
                                                elementTwo.component(element)}
                                              {elementTwo.type !==
                                                "component" &&
                                                elementTwo.switch && (
                                                  <Switch
                                                    uncheckedIcon={
                                                      <Offsymbol />
                                                    }
                                                    className="mr-1"
                                                    checkedIcon={<OnSymbol />}
                                                    onColor="#626ed4"
                                                    onChange={() => {
                                                      elementTwo.handleClick(
                                                        element
                                                      );
                                                    }}
                                                    height={24}
                                                    width={48}
                                                    checked={
                                                      element[
                                                        `${elementTwo.keySwitch}`
                                                      ]
                                                    }
                                                  />
                                                )}
                                            </>
                                          )
                                        )}
                                      </td>
                                    )}

                                    {data.iconActions && (
                                      <td>
                                        {data.iconActions.content?.map(
                                          (elementTwo, index) => (
                                            <>
                                              {loadingDownloadCall &&
                                              dataDownload.pk === element.pk ? (
                                                <Spinner
                                                  style={{
                                                    marginLeft: "40px",
                                                  }}
                                                  as="span"
                                                  animation="grow"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                              ) : (
                                                <ButtonHover
                                                  key={index}
                                                  style={{
                                                    margin:
                                                      data.iconActions.content
                                                        .length === 1
                                                        ? "0 auto"
                                                        : "0",
                                                  }}
                                                  onClick={() => {
                                                    elementTwo.handleClick(
                                                      element
                                                    );
                                                  }}
                                                >
                                                  {elementTwo.icon}
                                                </ButtonHover>
                                              )}
                                            </>
                                          )
                                        )}
                                      </td>
                                    )}

                                    {data.actions && (
                                      <>
                                        {loadingMenu === element ? (
                                          <td>
                                            <Spinner
                                              style={{
                                                marginLeft: "10px",
                                                width: "24px",
                                                height: "24px",
                                              }}
                                              animation="grow"
                                            />
                                          </td>
                                        ) : (
                                          <td
                                            style={{ padding: "0.5rem 0.5rem" }}
                                          >
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                variant=""
                                                className="btn btn-primary tp-btn-light sharp i-false"
                                              >
                                                <svg
                                                  width="24px"
                                                  height="24px"
                                                  viewBox="0 0 24 24"
                                                  version="1.1"
                                                >
                                                  <g
                                                    stroke="none"
                                                    strokeWidth="1"
                                                    fill="none"
                                                    fillRule="evenodd"
                                                  >
                                                    <rect
                                                      x="0"
                                                      y="0"
                                                      width="24"
                                                      height="24"
                                                    ></rect>
                                                    <circle
                                                      fill="#000000"
                                                      cx="5"
                                                      cy="12"
                                                      r="2"
                                                    ></circle>
                                                    <circle
                                                      fill="#000000"
                                                      cx="12"
                                                      cy="12"
                                                      r="2"
                                                    ></circle>
                                                    <circle
                                                      fill="#000000"
                                                      cx="19"
                                                      cy="12"
                                                      r="2"
                                                    ></circle>
                                                  </g>
                                                </svg>
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu
                                                style={{
                                                  transform:
                                                    "translate(-44px, -35px)",
                                                }}
                                              >
                                                {data.actions.content?.map(
                                                  (elementTwo, index) => (
                                                    <Dropdown.Item
                                                      key={index}
                                                      onClick={() =>
                                                        elementTwo.handleClick(
                                                          element
                                                        )
                                                      }
                                                    >
                                                      {elementTwo.icon}{" "}
                                                      {elementTwo.name}
                                                    </Dropdown.Item>
                                                  )
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </td>
                                        )}
                                      </>
                                    )}
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
        {!loading && !loadingExternal && (
          <>
            {allResult ? (
              <>
                {parseInt(allResult.total_pages) !== 0 && (
                  <PaginationITP
                    pages={parseInt(allResult.total_pages) + 1}
                    active={
                      allResult.offset === null
                        ? 1
                        : parseInt(allResult.offset) === 0
                        ? parseInt(allResult.items_per_page) /
                          parseInt(allResult.items_per_page)
                        : Math.floor(
                            parseInt(allResult.offset) /
                              parseInt(allResult.items_per_page)
                          ) + 1
                    }
                    setActive={(page) => {
                      setScrollPosition(window.scrollY);
                      setLoading(true);
                      data.changePage(page);
                    }}
                  ></PaginationITP>
                )}
              </>
            ) : (
              <>
                {dataFilter && dataFilter.length > cantPagination && (
                  <PaginationITP
                    pages={
                      (dataFilter.length / cantPagination) % 1 === 0
                        ? Math.floor(dataFilter.length / cantPagination)
                        : Math.floor(dataFilter.length / cantPagination + 1)
                    }
                    active={pagination / cantPagination}
                    setActive={setPaginationITP}
                  ></PaginationITP>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
  return <Loading />;
};

export default CustomTable;
