import React from "react";
import { Modal, Button } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalDelete = ({
  openModal,
  modalActions,
  closeModal,
  info,
  loading,
}) => {
  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure ?</Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          loading={loading}
          text={"Delete"}
          onClickFunction={() => modalActions()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
