import React from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalError = ({ modal, closeModal, errorMessage }) => {
  return (
    <Modal show={modal} className="fade">
      <Modal.Header>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>{errorMessage}</Modal.Body>
      <Modal.Footer>
        <ButtonStyled text="Close" onClickFunction={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
