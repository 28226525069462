import React, { useEffect, useState, useRef,useReducer } from "react";
import { sendToastSuccess, sendToastError } from "../../jsx/utils/toastAlert";
import { searchSubAgencyFromSubAgencyId } from "../../services/searchSubAgencyFromSubAgencyId";
import { formatHourof24at12 } from "../../utils/GetChatDate";
import { searchAgentsService } from "../../services/searchAgentsService";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LockResetIcon from "@mui/icons-material/LockReset";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionPatch from "../../services/petitionPatch";
import getQueryString from "../../jsx/utils/getQueryString";
import Loading from "../../jsx/utils/Loading";
import MenuITP from "../../jsx/utils/MenuITP/MenuITP";
import AgentsDetails from "./AgentsDetails";
import getValuesInObject from "../../jsx/utils/getValuesInObject";

export default function Agents() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const myRef = useRef(null);
  const filterRef = useRef();
  const filterText = useRef();
  const objectFilter = useRef();
  const itemsPerPage = useRef("10");
  const accounFilters = useRef({});
  const pagination = useRef({ offset: 0, limit: 10 });
  const firstLoad = useRef(true)

  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [ratesGroups, setRatesGroups] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [allResult, setAllResult] = useState(null);
  const [dataFilterModal, setDataFilterModal] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [allAgents, setAllAgents] = useState([]);
  const [reset, setReset] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const defaultAgencyname = useRef("");

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const [form, setForm] = useState({
    pk: null,
    agency_id: null,
    first_name: "",
    last_name: "",
    email: "",
    created_at: "",
    updated_at: "",
    receive_upfront_commission: false,
  });

  const onSaveFunction = async (form, formLabels, noUpdateFilters) => {
    setLoadingFilter(true);
    let filtersActive = getValuesInObject(formLabels);

    const paginationTemp = {
      offset: 0,
      limit: pagination.current.limit,
    };

    const filterModal = getQueryString(form).replaceAll("?", "&");
    const parameter = getQueryString(paginationTemp) + (filterText.current || "") + filterModal;

    setDataFilterModal({
      onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
      onHidde: () => {},
      parameters: [
        {
          title: "Date",
          inputs: [
            {
              label: "Agent Created On or After",
              input: "date",
              inputValue: form.added_after || "",
              checkbox: true,
              checkboxValue: form.added_after ? true : false,
              checkboxName: "checkbox_after",
              key: "added_after",
            },
            {
              label: "Agent Created On or Before",
              input: "date",
              inputValue: form.added_before || "",
              checkbox: true,
              checkboxValue: form.added_before ? true : false,
              checkboxName: "checkbox_before",
              key: "added_before",
            },
          ],
        },
        {
          title: "Field",
          inputs: [
            {
              label: "Agent",
              input: "asyncSelect",
              inputValue: form.agent_id || null,
              checkbox: true,
              checkboxValue: form.agent_id ? true : false,
              loadOptions: searchAgentsService,
              checkboxName: "checkbox_agent_id",
              key: "agent_id",
            },
          ],
        },
      ],
    });

    if (!noUpdateFilters) {
      const data = {
        paylink_filters: {
          ...accounFilters.current,
          agent_filters: {
            ...form,
            formLabels,
          },
        },
      };

      await petitionPatch("account-details", { data })
        .then(({ data: result }) => { })
        .catch((error) => console.log(error));
    }

    if (!filterText.current) {
      await petition(parameter, false, false, false, filtersActive, defaultAgencyname.current);
    } else {
      let filter = filterText.current;
      let params = new URLSearchParams(filter);
      let value = params.get("firstname");
      await petition(parameter, false, true, value, filtersActive, defaultAgencyname.current);
    }

    pagination.current = paginationTemp;
    objectFilter.current = filtersActive;
    filterRef.current = filterModal;
    setFilterModal(false);
  };

  const activeAgentDetails = (e) => {
    setInfo(e)
    setAgentDetails(e);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeResetModal = (e) => {
    setReset(true);
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    setErrorMessage("");
    setReset(false);
    resetForm();

    if (deleted) {
      const paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };

      parameter =  getQueryString(paginationTemp) + (filterText.current || "") + (filterModal.current || "");

      petition(parameter, false, filterText.current ? true : false, filterText.current ? filterText.current.split("=")[1] : false, objectFilter.current, defaultAgencyname.current);
      pagination.current = paginationTemp;
    } else {
      parameter = getQueryString(pagination.current) + (filterText.current || "") +  (filterModal.current || "");

      petition(parameter, false, filterText.current ? true : false, filterText.current ? filterText.current.split("=")[1] : false, objectFilter.current, defaultAgencyname.current);
    }
  };

  const formatData = (element) => {
    let date = element.slice(0, 10).replaceAll("-", "/");
    let month = date.split("/")[1];
    let day = date.split("/")[2];
    let year = date.split("/")[0];
    let hour = formatHourof24at12(element);

    return month + "/" + day + "/" + year + " " + hour;
  };

  const petition = async (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    filtersToolip,
    defaultAgencyname,
  ) => {
    if (!noLoading) setLoading(true);

    const completeParameter = `${parameter || ""}${subAgencyQuery ? parameter ? `&${subAgencyQuery}` : `?${subAgencyQuery}` : ""}`;
    
    if (firstLoad.current) {
      await petitionGet("rates-group", { parameter: `?${subAgencyQuery}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        setRatesGroups(result.result);
      })
      .catch((error) => console.log(error));
    }

    await petitionGet("agents", { id, parameter: completeParameter })
      .then(({ data: result }) => {

        setAllResult(result);
        result.result.forEach((element, i) => {
        searchSubAgencyFromSubAgencyId(element.sub_agency_id, element, forceUpdate, defaultAgencyname)
          element.created_at = formatData(element.created_at);
          element.updated_at = formatData(element.updated_at);
          element.name = element.first_name + " " + element.last_name;
          element.label = element.first_name + " " + element.last_name;
          element.value = element.pk;
          element.rateGroupName = element?.contract?.rate_group?.name || ""
        });

        setDataTable({
          title: [
            { title: "Agent Name", key: "name" },
            { title: "Agency", key: "subAgencyName" },
            { title: "Contract", key: "rateGroupName" },
            { title: "Balance", key: "balance" },
          ],
          content: [...result.result],
          actions: sub_agency_id ? null : {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeAgentDetails,
              },
              {
                name: "Reset Password",
                icon: <LockResetIcon />,
                handleClick: activeResetModal,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          activeFilters: filtersToolip,
          search: {
            label: "Search by Agents Name",
            filterBackend: true,
            onChange: (e) => filterTextBox(e.target.value),
            defaultValue: valueFilter || null,
          },
          addButton: sub_agency_id ? null : {
            label: "Add Agent",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          clickRow: activeAgentDetails,
          showMorefilters: () => setFilterModal(true),
          itemsPerPage: (e) => {
            if (activeFilterTexBox) {
              activeItemsPerPage(e, true, valueFilter);
            } else {
              activeItemsPerPage(e);
            }
          },
          changePage: (page) => {
            if (activeFilterTexBox) {
              changePage(page, true, valueFilter);
            } else {
              changePage(page, false, false);
            }
          },
        });

        firstLoad.current = false
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
      })
      .catch((error) => console.log(error));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const addData = () => {
    if (!validateEmail(form.email) && form.email) {
      setIsValidEmail(false);
    } else if (validateEmail(form.email) || !form.email) {
      setLoadingModal(true);
      let dataSend = {
        first_name: form.first_name,
        last_name: form.last_name,
        receive_upfront_commission: form.receive_upfront_commission,
      };

      if (subAgencyItem) {
        dataSend.sub_agency_id = subAgencyItem.pk
      }

      if (form.email.length != 0) {
        dataSend["email"] = form.email;
      }

      if (info) {
        petitionPatch("agents", {
          agent_id: info.pk,
          data: dataSend,
        })
          .then(({ data: result }) => {
            reloadData();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              setErrorMessage(error.response.data.error);
              setLoadingModal(false);
            } else {
              console.log(error);
            }
          });
      } else {
        petitionPost("agents", dataSend)
          .then(({ data: result }) => {
            reloadData();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              setErrorMessage(error.response.data.error);
              setLoadingModal(false);
            } else {
              console.log(error);
            }
          });
      }
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("agents", info.pk)
      .then(({ data: { result } }) => {
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetPassword = () => {
    setLoadingModal(true);
    petitionGet("reset-password", { agent_id: info.pk })
      .then(({ data: result }) => {
        reloadData();
        sendToastSuccess();
      })
      .catch((error) => {
        console.log(error);
        setLoadingModal(false);
        setModalDelete(false);
        setInfo(null);
        setReset(false);
        sendToastError();
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      agency_id: null,
      first_name: "",
      last_name: "",
      email: "",
      created_at: "",
      updated_at: "",
      receive_upfront_commission: true,
    });
  };

  const closeMenu = () => {
    let parameter =
      getQueryString(pagination.current) +
      (filterText.current || "") +
      (filterModal.current || "");
    petition(
      parameter,
      true,
      false,
      false,
      objectFilter.current,
      defaultAgencyname.current,
    );
    setInfo(null)
    setAgentDetails(null);
  };

  const changePage = (page, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&firstname=${valueFilter}&last_name=${valueFilter}${filterRef.current}`;

      petition(
        parameter,
        true,
        true,
        valueFilter,
        objectFilter.current,
        defaultAgencyname.current,
      );
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        true,
        false,
        false,
        objectFilter.current,
        defaultAgencyname.current,
      );
      pagination.current = paginationTemp;
    }
  };

  const filterTextBox = (value) => {
    let paginationTemp = {
      limit: parseInt(itemsPerPage.current),
      offset: 0,
    };

    if (value !== "") {
      let parameter = `${getQueryString(
        paginationTemp
      )}&query=${value}${filterRef.current || ""}`;

      petition(
        parameter,
        true,
        true,
        value,
        objectFilter.current,
        defaultAgencyname.current,
      );
      pagination.current = paginationTemp;

      if (value) {
        filterText.current = `&query=${value}`;
      } else {
        filterText.current = "";
      }
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        true,
        false,
        false,
        objectFilter.current,
        defaultAgencyname.current,
      );
      filterText.current = "";
      pagination.current = paginationTemp;
    }
  };

  const activeItemsPerPage = (data, activeFilterTexBox, valueFilter) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    if (activeFilterTexBox) {
      let parameter =
        getQueryString(paginationTemp) +
        `&firstname=${valueFilter}&last_name=${valueFilter}${filterRef.current}`;

      petition(
        parameter,
        false,
        true,
        valueFilter,
        objectFilter.current,
        defaultAgencyname.current,
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } else {
      let parameter =
        getQueryString(paginationTemp) + (filterRef.current || "");
      petition(
        parameter,
        false,
        false,
        false,
        objectFilter.current,
        defaultAgencyname.current,
      );
      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    }
  };

  const accountDetails = () => {
    petitionGet("account-details")
      .then(({ data: result }) => {
        const filters = result?.result?.paylink_filters?.agent_filters;
        accounFilters.current = result?.result?.paylink_filters;
        defaultAgencyname.current = result?.result?.agency_name

        if (filters) {
          const form = filters;
          const formLabels = form.formLabels;
          delete form.formLabels;

          onSaveFunction(form, formLabels, true);
        } else {
          petition(getQueryString(pagination.current), false, false, false, false, result?.result?.agency_name);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    accountDetails();
  }, []);

  return (
    <>
      {filterModal && (
        <ModalFilter
          openModal={filterModal}
          closeModal={() => {
            setFilterModal(false);
          }}
          info={dataFilterModal}
          loading={loadingFilter}
        />
      )}

      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
            setErrorMessage("");
          }}
          form={form}
          setForm={setForm}
          loading={loadingModal}
          isValidEmail={isValidEmail}
          setIsValidEmail={setIsValidEmail}
          validateEmail={validateEmail}
          errorMessage={errorMessage}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={!reset ? deleteFunction : resetPassword}
          closeModal={() => {
            resetForm();
            setModalDelete(false);
          }}
          loading={loadingModal}
          reset={reset}
          info={info}
        />
      )}

      {agentDetails && !modalDelete && (
        <MenuITP
          show={agentDetails}
          myRef={myRef}
          style={{
            width: "45%",
            backgroundColor: "#fff",
            border: "1px solid rgba(0, 0, 0, 0.07)",
          }}
        >
          <AgentsDetails
            agentDetails={agentDetails}
            setAgentDetails={setAgentDetails}
            info={info}
            closeMenu={closeMenu}
            ratesGroups={ratesGroups}
            formatData={formatData}
          />
        </MenuITP>
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              pagination={itemsPerPage.current}
            />
          )}
        </>
      )}
    </>
  );
}
