import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import ITPVoice from "./reducers/ITPVoiceReducers";
import SipReducer from "./reducers/SipReducer";
import Layout from "./reducers/LayoutReducer";
import { reducer as reduxFormReducer } from "redux-form";
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  ITPVoice,
  form: reduxFormReducer,
  sip: SipReducer,
  layout: Layout,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
