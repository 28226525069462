import React, { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "react-bootstrap";
import ButtonStyled from "../../jsx/utils/ButtonStyled";
import petitionGet from "../../services/petitionGet";
import petitionPatch from "../../services/petitionPatch";
import Loading from "../../jsx/utils/Loading";
import { sendToastSuccess } from "../../jsx/utils/toastAlert";
import Select from "react-select";

const GeneralSettings = () => {
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const [imgMessages, setImgMessages] = useState([]);
  const [agencyName, setAgencyName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [defaultImage, setDefaultImage] = useState("");
  const [defaultName, setDefaultName] = useState("")
  const [valueRate, setValueRate] = useState(null);
  const [rates, setRates] = useState([]);

  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

  const onChangeOneImage = (e) => {
    setImgMessages(e);
  };

  const onchangeAgency = (e) => {
    setAgencyName(e.target.value);
  };

  const updateAccountDetails = (data) => {
    petitionPatch("account-details", { data })
    .then(({ data: result }) => {
      if (
        (!imgMessages.length && defaultImage) ||
        (imgMessages.length > 0 && defaultImage !== imgMessages[0].data_url)
      ) {
        window.location.href = window.location.href;
      } else {
        if (data.logo && data.logo_mime) {
          setDefaultImage(data.logo_mime + "," + data.logo);
        }

        if (data?.agency_name) {
          setDefaultName(data?.agency_name)
        }
        setLoadingSave(false);
        sendToastSuccess();
      }
    })
    .catch((error) => console.log(error));
  }

  const updateSubAgency = (data) => {
    petitionPatch("sub-agencies", { data, subAgencyId: subAgencyItem.pk })
      .then(({ data: result }) => {
        if (
          (!imgMessages.length && defaultImage) ||
          (imgMessages.length > 0 && defaultImage !== imgMessages[0].data_url) || 
          (defaultName !== data.name && subAgencyItem)
        ) {
          localStorage.setItem("subAgencyDetails", JSON.stringify({ ...subAgencyItem, name: data.name }));
          window.location.href = window.location.href;
        } else {
          if (data.logo && data.logo_mime) {
            setDefaultImage(data.logo_mime + "," + data.logo);
          }

          if (data.name) {
            setDefaultName(data.name)
          }
          setLoadingSave(false);
          sendToastSuccess();
        }
      })
      .catch((error) => console.log(error));
  }

  const save = () => {
    setLoadingSave(true);

    let data = {}

    if (subAgencyItem) {
      data = {
        name: agencyName,
      };
    } else {
      data = {
        agency_name: agencyName,
      };
    }

    if (imgMessages.length > 0) {
      data.logo = imgMessages[0].data_url.split(",")[1];
      data.logo_mime = imgMessages[0].data_url.split(",")[0];
    } else {
      data.logo = "";
      data.logo_mime = "";
    }

    if (valueRate) {
      data.rate_group_id = valueRate.value;
    }

    if (subAgencyItem) {
      updateSubAgency(data)
    } else {
      updateAccountDetails(data)
    }
  };

  const base64ToBlob = (base64) => {
    const parts = base64.split(';base64,');
    const type = parts[0].split(':')[1];
    const data = window.atob(parts[1]);
    const array = new Uint8Array(data.length);
    
    for (let i = 0; i < data.length; i++) {
      array[i] = data.charCodeAt(i);
    }
    
    return new Blob([array], { type: type });
  };

  const downloadImage = (data) => {
    const blob = base64ToBlob(data.data_url);
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = url;
    link.download = 'imagen.png';
    link.click();
    
    URL.revokeObjectURL(url);
  }

  const subAgencyGeneralSetings = async (ratesTemp) => {
    await petitionGet("sub-agencies", { parameter: `/${subAgencyItem.pk}` })
      .then(({ data: result }) => {
        let dataFind = ratesTemp.find(
          (rate) => rate.pk === result?.result?.rate_group_id
        );

        if (result?.result?.logo && result?.result?.logo_mime) {
          setImgMessages([
            { data_url: result.result.logo_mime + "," + result.result.logo },
          ]);
          setDefaultImage(result.result.logo_mime + "," + result.result.logo);
        }

        if (result?.result?.name) {
          setAgencyName(result?.result?.name);
          setDefaultName(result?.result?.name)
        }

        if (dataFind) setValueRate(dataFind);

        setLoading(false);
      })
      .catch((error) => console.log(error))
  }

  const accountDetails = async (ratesTemp) => {
    await petitionGet("account-details")
    .then(({ data: result }) => {
      let dataFind = ratesTemp.find(
        (rate) => rate.pk === result?.result?.rate_group_id
      );

      if (result?.result?.logo && result?.result?.logo_mime) {
        setImgMessages([
          { data_url: result.result.logo_mime + "," + result.result.logo },
        ]);
        setDefaultImage(result.result.logo_mime + "," + result.result.logo);
      }

      if (result?.result?.agency_name) {
        setAgencyName(result?.result?.agency_name);
        setDefaultName(result?.result?.agency_name)
      }

      if (sub_agency_id && result?.result?.name) {
        setAgencyName(result.result.name);
      }

      if (dataFind) setValueRate(dataFind);

      setLoading(false);
    })
    .catch((error) => console.log(error));
  }

  const petition = async () => {
    setLoading(true);
    let ratesTemp = [];
    await petitionGet("rates-group", { parameter: `?${subAgencyQuery}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name;
        });
        ratesTemp = result.result;
        setRates(result.result);
      })
      .catch((error) => console.log(error));

    if (subAgencyItem) {
      await subAgencyGeneralSetings(ratesTemp)
    } else {
      await accountDetails(ratesTemp)
    }
  };

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div style={{ padding: "28px" }}>
      <div
        style={{ marginBottom: "10px", gap: "20px" }}
        className="d-flex align-items-center"
      >
        <label style={{ marginRight: "3rem" }}>Agency Name:</label>
        <input
          className="form-control"
          style={{ width: "30%" }}
          onChange={onchangeAgency}
          value={agencyName}
          disabled={sub_agency_id || false}
        />
      </div>
      <div
        style={{ marginBottom: "10px", gap: "20px" }}
        className="d-flex align-items-center"
      >
        <label style={{ marginRight: "4.3rem" }}>Rate Group:</label>
        <div style={{ flex: "0 0 auto", width: "30.2%" }}>
          {sub_agency_id ? (
            <select defaultValue={valueRate.value} disabled className="arrow-select">
              <option value={valueRate.value}>{valueRate.label}</option>
            </select>
          ) : (
            <Select
            onChange={(e) => {
              setValueRate(e)
            }}
            className="basic-single"
            placeholder="Select Option"
            classNamePrefix="select"
            value={valueRate}
            isSearchable={true}
            name="rate_group_id"
            options={rates}
          />
          )}
        </div>
      </div>
      <div
        style={{ marginBottom: "10px", gap: "20px" }}
        className="d-flex align-items-center"
      >
        <label style={{ marginRight: "7rem", marginTop: "10px" }}>Logo:</label>
        {!sub_agency_id && (
        <ImageUploading
        multiple
        value={imgMessages}
        onChange={(e) => {
          onChangeOneImage(e);
        }}
        maxNumber={1}
        dataURLKey="data_url"
        maxFileSize={3145728}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div>
            <Button
              className={`primary btn-square ${imageList.length ? "d-none" : ""}`}
              onClick={onImageUpload}
              style={{ marginTop: "1rem" }}
            >
              <div className="perspective3D">
                <AddIcon />
                <span>Add Image</span>
              </div>
            </Button>
            {imageList.map((image, index) => (
              <div
                style={{ marginTop: "24px" }}
                key={index}
                className={`upload__image-wrapper col-4`}
              >
                <div key={index} className="image-item">
                  <img src={image["data_url"]} alt="" width="100" />
                  <div className="image-item__btn-remove">
                    <button onClick={() => onImageRemove(index)}>
                      {" "}
                      <HighlightOffIcon />
                    </button>
                  </div>
                  <div className="image-item__btn-edit">
                    <button onClick={() => onImageUpdate(index)}>
                      <EditIcon />
                    </button>
                  </div>
                  <div className="image-item__btn-download">
                    <button onClick={() => downloadImage(imageList[index])}>
                      <DownloadIcon />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
        )}
      </div>

      {!sub_agency_id && (
        <ButtonStyled
          onClickFunction={save}
          text="Save"
          disabled={!agencyName || !valueRate}
          loading={loadingSave}
        />
      )}
    </div>
  );
};

export default GeneralSettings;
