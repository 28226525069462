import React from "react";
import NavTabCustom from "../../jsx/utils/NavTabCustom";
import ProfileSettings from "./Tabs/ProfileSettings";
import ChangePassword from "./Tabs/ChangePassword";

const Profile = () => {
  const options = [
    {
      title: "Profile Settings",
      component: <ProfileSettings />,
    },
    {
      title: "Change Password",
      component: <ChangePassword />,
    },
  ];

  return <NavTabCustom options={options} />;
};

export default Profile;
