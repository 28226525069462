import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@material-ui/icons/Edit";
import petitionPatch from "../../services/petitionPatch";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import Loading from "../../jsx/utils/Loading";
import getDate from "../../jsx/utils/getDate";
import ButtonStyled from "../../jsx/utils/ButtonStyled";
import ModalDelete from "./ModalUtility/ModalDelete";
import { toast } from "react-toastify";
import Select from "react-select";
import ModalError from "./ModalUtility/ModalError";
import "./Agency.css";

const AgentsDetails = ({
  agentDetails,
  setAgentDetails,
  closeMenu,
  ratesGroups,
  formatData,
}) => {

  const [loadingContract, setLoadingContract] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [showAddContract, setShowAddContract] = useState(false);
  const [showDeleteContract, setShowDeleteContract] = useState(null);
  const [showEditContract, setShowEditContract] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [form, setForm] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [showAddAppointment, setShowAddAppointment] = useState(false);
  const [loadingAppointment, setloadingAppointment] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState(false);
  const [edited, setEdited] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const sendToastSuccess = () => {
    toast.success("✔️ Success !!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const sendToastError = () => {
    toast.error("Error", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const activeDeleteAppointment = (e) => {
    petitionDelete("appointments", { appoiment_id: e.pk })
      .then(() => {
        getAppointments();
        sendToastSuccess();
      })
      .catch((err) => {
        sendToastError();
        console.log(err);
      });
  };


  const closeModal = () => {
    setModalError(false);
    setErrorMessage(null);
  };



  const getAppointments = () => {
    petitionGet("appointments", { parameter: `?agent_id=${agentDetails.pk}` })
      .then(({ data: result }) => {
        setAppointments(result.result);
        setloadingAppointment(false);
      })
      .catch((error) => console.log(error));
  };


  useEffect(() => {
    // setAgentDetails({
    //   ...agentDetails,
    //   state: agentDetails?.state || "",
    //   address2: agentDetails?.address2 || "",
    //   address: agentDetails?.address || "",
    //   zip: agentDetails?.zip || "",
    //   city: agentDetails?.city || "",
    // })
    // petitions();
  }, []);

  const deleteFunction = () => {
    activeDeleteAppointment(infoDelete);
    setInfoDelete(false);
    setModalDelete(false);
  };

  return (
    <Fragment>
      <ModalDelete
        openModal={modalDelete}
        modalActions={deleteFunction}
        closeModal={() => {
          setInfoDelete(false);
          setModalDelete(false);
        }}
      />

      {modalError && (
        <ModalError
          modal={modalError}
          closeModal={closeModal}
          errorMessage={errorMessage}
        />
      )}

      {loading ? (
        <div
          style={{ width: "100%", height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Loading />
        </div>
      ) : (
        <>
           <div
            style={{
              marginTop: "20px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.07)",
              paddingBottom: "2.5rem",
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <h4 style={{ marginLeft: "20px", marginTop: "10px" }}>
              Agent Details
            </h4>
            <CloseIcon
              style={{ color: "red", marginRight: "1.5rem" }}
              className="icons-hover"
              onClick={() => closeMenu()}
            />
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AgentsDetails;
