import React, { useState, useEffect } from "react";
import UserAvatar from "../../../components/Avatar/UserAvatar";
import { Link } from "react-router-dom";
/// Scroll

/// Image
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Header = ({ onNote, profile }) => {
  let { firstname, email } = profile.auth.auth;
  const [searchBut, setSearchBut] = useState(false);
  const [token, setToken] = useState(null);
  var path = window.location.pathname.split("/");
  const [activePhone, setActivePhone] = useState(false);
  const json = require("../../../config.json");
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
  const sub_agency_id = JSON.parse(localStorage.getItem("userDetails"))?.sub_agency_id;

  const host = json.prod
    ? json.paylink.prod.split("//")[1]
    : json.paylink.dev.split("//")[1];

  const linkPortal =
    window.location.host !== host
      ? "http://localhost:3000/profile"
      : json.prod
      ? json.portal.prod
      : json.portal.dev;

  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("userDetails")).access_token);
  }, []);

  const onProfile = () => {
    const dataLocalStorage = JSON.parse(localStorage.getItem("userDetails"))
    const access_token = dataLocalStorage.access_token

    const linkPortal = json.prod ? json.portal.prod : json.portal.dev
    window.location.href = linkPortal + `?access_token=${access_token}`
  }

  return (
    <div className="header border-bottom">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"  
                  ? "Dashboard"
                  : finalName.join(" ")}{subAgencyItem ? ` - Sub Agency (${subAgencyItem?.name})` : ""} 
              </div>
            </div>
            <ul className="navbar-nav header-right">
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              ></Dropdown>
              <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link bell bell-link i-false c-pointer border-custom"
                  onClick={() => onNote()}
                  style={{ width: "60px" }}
                >
                  <svg
                    className="gb_Ue"
                    focusable="false"
                    fill="gray"
                    viewBox="0 0 24 24"
                  >
                    <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
                  </svg>
                </Dropdown.Toggle>
              </Dropdown>

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="image-box">
                    <UserAvatar
                      firstname={
                        JSON.parse(localStorage.getItem("userDetails"))?.firstname || JSON.parse(localStorage.getItem("userDetails"))?.name
                      }
                      lastname={
                        JSON.parse(localStorage.getItem("userDetails"))?.lastname || ""
                      }
                    />
                  </div>
                  <div className="header-info ms-3">
                    <span className="fs-18 font-w500 mb-2">
                      {JSON.parse(localStorage.getItem("userDetails"))?.firstname || JSON.parse(localStorage.getItem("userDetails"))?.name}
                    </span>
                    <small className="fs-12 font-w400">{email}</small>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3  mt-lg-0 dropdown-menu dropdown-menu-end"
                >
                  <Link
                    to="#"
                    onClick={onProfile}
                    className={`dropdown-item ai-icon ${sub_agency_id ? "d-none" : ""}`}
                  >
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary me-1"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>

                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(Header));
