import { Button, Spinner } from "react-bootstrap";

const ButtonStyled = ({
  clasName,
  text,
  onClickFunction,
  loading,
  type,
  disableLoading,
  style,
  icon,
  disabled,
  noSquare,
}) => {
  return (
    <Button
      onClick={() => onClickFunction()}
      className={`${clasName}`}
      variant={`${noSquare ? "" : "primary btn-square"}`}
      disabled={loading || disabled}
      type={type ? type : ""}
      style={style}
    >
      {loading && !disableLoading && (
        <Spinner
          style={{ marginRight: "0.5em" }}
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {icon}
      {text}
    </Button>
  );
};

export default ButtonStyled;
