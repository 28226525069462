import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalAddRateRule = ({
  modal,
  closeModal,
  addFunction,
  loading,
  form,
  setForm,
}) => {
  const handleChange = (e) => {
    if (e.target.name === "rate") {
      const { value } = e.target;
      value.replaceAll(",", ".");
      const regex = /^\d*\.?\d{0,2}$/;
      if (regex.test(value)) {
        setForm({ ...form, rate: value });
      }
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  return (
    <Modal className="fade" show={modal}>
      <Modal.Header>
        <Modal.Title>
          {form?.pk ? "Edit Rate Rule" : "Add Rate Rule"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Year:</label>
          <div className="col-8">
            <input
              className="form-control"
              name="year"
              onChange={handleChange}
              type="number"
              value={form?.year}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Rate %:</label>
          <div className="col-8">
            <input
              className="form-control"
              name="rate"
              onChange={handleChange}
              type="number"
              value={form?.rate}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Age Start:</label>
          <div className="col-8">
            <input
              className="form-control"
              name="age_start"
              onChange={handleChange}
              type="number"
              value={form?.age_start}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Age End:</label>
          <div className="col-8">
            <input
              className="form-control"
              name="age_end"
              onChange={handleChange}
              type="number"
              value={form?.age_end}
            ></input>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text="Cancel"
          disabled={loading}
          onClickFunction={closeModal}
        />
        <ButtonStyled
          text={form?.pk ? "Edit" : "Add"}
          loading={loading}
          onClickFunction={() => addFunction(form)}
          disabled={
            !form.year || !form.rate || !form.age_start || !form.age_end
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddRateRule;
