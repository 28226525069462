import { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import "./menuTabItemstyles.css";
export default function MenuTabItem({ data, onClick }) {
  return (
    <div className="menu-tab-item" onClick={onClick}>
      <div className="menu-tab-item-icon">{data.icon}</div>
      <div className="menu-tab-item-text">
        <div
          className={`menu-tab-item-text-title ${data.active ? "active" : ""}`}
        >
          {data.title}
        </div>
        <div className="menu-tab-item-text-subtitle">{data.subtitle}</div>
      </div>
    </div>
  );
}
