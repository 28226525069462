import React from "react";
import { Modal } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
import "../styles.css"

const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  form,
  setForm,
  loading,
}) => {
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"));
  
  const update = () => {
    modalActions(form);
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form?.pk ? "Edit Rate Group" : "Create Rate Group"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              value={form.name}
            ></input>
          </div>
        </div>

        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Description:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, description: e.target.value });
              }}
              value={form?.description}
            ></input>
          </div>
        </div>
        
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Pay Once</label>
          <div className="col-8">
            <input
              className="form-check-input pay_once"
              type="checkbox"
              onChange={(e) => {
                setForm({ ...form, pay_once: !form.pay_once });
              }}
              checked={form?.pay_once}
            ></input>
          </div>
        </div>

        {subAgencyItem && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Sub Agency</label>
            <div className="col-8">
              <select
                defaultValue={subAgencyItem.name}
                className="arrow-select"
                name="sub_agency_id"
              >
                <option disabled value={subAgencyItem.name}>
                  {subAgencyItem.name}
                </option>
              </select>
            </div>
          </div>
        )}

        {form.pay_once && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Pay One Month Advanced Commissions only</label>
            <div className="col-8">
              <input
                className="form-check-input pay_once"
                type="checkbox"
                onChange={(e) => {
                  setForm({ ...form, pay_one_month_advanced: !form.pay_one_month_advanced });
                }}
                checked={form?.pay_one_month_advanced}
              ></input>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form?.name ||
            !form?.description 
          }
          text={form?.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
