import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import PaginationITP from "../Pagination";

const CustomVerticalTable = ({ dataTable, paginationData }) => {
  const cantPagination = paginationData || 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [paginationITP, setPaginationITP] = useState(cantPagination);

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  return (
    <>
      <Table hover>
        {dataTable.thead && (
          <thead>
            <tr>
              {dataTable.thead.map((element, i) => (
                <th key={i}>{element.title}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {dataTable.onlyKey
            ? dataTable.content.map((element, i) => {
                if (
                  // (i < pagination && i >= pagination - cantPagination) ||
                  // !pagination
                  dataTable.content
                ) {
                  return (
                    <tr key={i}>
                      <td style={{ padding: "0" , border: "0" }}><strong>{element}</strong></td>
                    </tr>
                  );
                }
              })
            : dataTable.tbody.map((element, i) => (
                <tr key={i}>
                  {element.content.map((elementTwo, index) => (
                    <td key={index}>
                      {elementTwo.key ? (
                        <strong>{elementTwo.key}</strong>
                      ) : (
                        dataTable.content[elementTwo.value]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
        </tbody>
      </Table>
      {/* {dataTable.onlyKey &&
        dataTable.content &&
        dataTable.content.length > cantPagination && (
          <PaginationITP
            pages={
              (dataTable.content.length / cantPagination) % 1 === 0
                ? Math.floor(dataTable.content.length / cantPagination)
                : Math.floor(dataTable.content.length / cantPagination + 1)
            }
            active={pagination / cantPagination}
            setActive={setPaginationITP}
          ></PaginationITP>
        )} */}
    </>
  );
};

export default CustomVerticalTable;
