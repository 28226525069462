// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input#search {
  margin-left: 25px;
}

.form-check-input.pay_once {
  border: 0.0625rem solid #d7dae3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Rates/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["input#search {\n  margin-left: 25px;\n}\n\n.form-check-input.pay_once {\n  border: 0.0625rem solid #d7dae3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
