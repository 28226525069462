import React from "react";
import { Modal, Button } from "react-bootstrap";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";

const ModalDelete = ({
  openModal,
  modalActions,
  closeModal,
  info,
  loading,
  errorMessage,
}) => {
  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage ? (
          <>
            {/* {errorMessage.error_detailed?  <p>{errorMessage}</p>:null} */}

            {errorMessage?.error_message != null && errorMessage.error_message != '' ? <p>{errorMessage.error_message}</p> : null}

            {errorMessage?.error_detailed != null && errorMessage.error_detailed != '' ? <p>{errorMessage.error_detailed}</p> : null}
          </>
        ) : (
          <>
            {info.created_policy ? (
              <>
                <p>
                  Warning! This transaction created a policy, by deleting this
                  transaction it will also delete all other transactions related
                  to this policy
                </p>
                <p>Are you sure ?</p>
              </>
            ) : (
              <>
                <p>
                  Deleting this transaction will update the Agent's
                  balance and remove any commissions.
                </p>
                <p>Are you sure ?</p>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        {!errorMessage && (
          <ButtonStyled
            loading={loading}
            text={"Delete"}
            onClickFunction={() => modalActions()}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
