// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.menu-tab-item{
    width: calc(100% - 20px);
    height: 50px;
    margin:10px 10px 0px 10px;
    display:flex;
    border: 1px solid #EEE;
    cursor: pointer;
}
.menu-tab-item:hover {
    border: 1px solid rgba(0, 0, 0, 0.37);
}
.menu-tab-item:hover .menu-tab-item-text{
    /* color:rgba(220, 53, 69, 1) */
}

.menu-tab-item:hover .menu-tab-item-icon{
    /* color:rgba(220, 53, 69, 1); */
    /* background: rgba(220, 53, 69, 1); */
    /* color: white; */
}
.menu-tab-item-icon{
    height: 48px;
    width: 50px;
    background: #EEEEEE;
    color:#808080;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #EEE;
}

.menu-tab-item-text{
    color:#808080;
    margin-left: 10px;
}

.menu-tab-item-text-title{
    margin-top: 7px;
}

.menu-tab-item-text-title.active{
    font-weight: bold;
}


.menu-tab-item-text-subtitle{
    font-size: 9px;
}`, "",{"version":3,"sources":["webpack://./src/jsx/components/MenuTabs/MenuTabItem/menuTabItemstyles.css"],"names":[],"mappings":";AACA;IACI,wBAAwB;IACxB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,qCAAqC;AACzC;AACA;IACI,+BAA+B;AACnC;;AAEA;IACI,gCAAgC;IAChC,sCAAsC;IACtC,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,cAAc;AAClB","sourcesContent":["\n.menu-tab-item{\n    width: calc(100% - 20px);\n    height: 50px;\n    margin:10px 10px 0px 10px;\n    display:flex;\n    border: 1px solid #EEE;\n    cursor: pointer;\n}\n.menu-tab-item:hover {\n    border: 1px solid rgba(0, 0, 0, 0.37);\n}\n.menu-tab-item:hover .menu-tab-item-text{\n    /* color:rgba(220, 53, 69, 1) */\n}\n\n.menu-tab-item:hover .menu-tab-item-icon{\n    /* color:rgba(220, 53, 69, 1); */\n    /* background: rgba(220, 53, 69, 1); */\n    /* color: white; */\n}\n.menu-tab-item-icon{\n    height: 48px;\n    width: 50px;\n    background: #EEEEEE;\n    color:#808080;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-right: 1px solid #EEE;\n}\n\n.menu-tab-item-text{\n    color:#808080;\n    margin-left: 10px;\n}\n\n.menu-tab-item-text-title{\n    margin-top: 7px;\n}\n\n.menu-tab-item-text-title.active{\n    font-weight: bold;\n}\n\n\n.menu-tab-item-text-subtitle{\n    font-size: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
