import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import ButtonStyled from "../../../jsx/utils/ButtonStyled";
const ModalAdd = ({
  openModal,
  modalActions,
  closeModal,
  contacts,
  sources,
  form,
  setForm,
  stages,
  loading,
  states,
  products,
}) => {
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"));

  const update = () => {
    modalActions(form);
  };

  const validate = (e) => {
    console.log(e);
    if (e > 100) {
      setForm({ ...form, commission_rate: 100.0 });
    } else if (e < 0) {
      setForm({ ...form, commission_rate: 0.0 });
    } else {
      setForm({ ...form, commission_rate: e });
    }
  };

  return (
    <Modal className="fade" show={openModal}>
      <Modal.Header>
        <Modal.Title>{form.pk ? "Edit Rate" : "Create Rate"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Name:</label>
          <div className="col-8">
            <input
              className="form-control"
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              value={form.name}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Commission Rate %:</label>
          <div className="col-8">
            <input
              className="form-control"
              type="number"
              onChange={(e) => {
                validate(e.target.value);
              }}
              value={form.commission_rate}
            ></input>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>Product:</label>
          <div className="col-8">
            <select
              className="arrow-select"
              value={form.product_id}
              onChange={(e) => {
                setForm({
                  ...form,
                  product_id: e.target.value,
                });
              }}
            >
              <option value={null}>Select an option</option>
              {products.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{ marginBottom: "10px" }}
          className="d-flex align-items-center justify-content-between"
        >
          <label>State:</label>
          <div className="col-8">
            <select
              className="arrow-select"
              value={form.state_id}
              onChange={(e) => {
                console.log(form);
                setForm({
                  ...form,
                  state_id: e.target.value,
                });
              }}
            >
              <option value={null}>Select an option</option>
              {states.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {subAgencyItem && (
          <div
            style={{ marginBottom: "10px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <label>Sub Agency</label>
            <div className="col-8">
              <select
                defaultValue={subAgencyItem.name}
                className="arrow-select"
                name="sub_agency_id"
              >
                <option disabled value={subAgencyItem.name}>
                  {subAgencyItem.name}
                </option>
              </select>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonStyled
          text={"Cancel"}
          onClickFunction={() => closeModal("update")}
          disabled={loading}
        />
        <ButtonStyled
          disabled={
            !form.name ||
            !form.commission_rate ||
            !form.product_id ||
            !form.state_id
          }
          text={form.pk ? "Edit" : "Create"}
          onClickFunction={() => update()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAdd;
