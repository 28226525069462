import petitionGet from "./petitionGet";

const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

export function searchRatesGroupService(defaultOptions,setOptions,value){
    if (value !== "") {
        petitionGet("sub-agencies",{parameter:`?firstname=${value}&last_name=${value}${subAgencyItem ? `&${subAgencyQuery}` : ""}`})
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = element.name
        });
        setOptions(result.result)
      })
      .catch((error) => {});
    }else{
        setOptions(defaultOptions)
    }
}

