import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import logo from '../../images/logo-full.png';
import ForgetPasswordPetition from './ForgetPasswordPetition';
import ModalUtility from "./ModalUtility";
import Loading from '../components/Loading/Loading';

const ForgotPassword = () => {
  const history = useHistory();

  const [email, setEmail] = useState("")
  const [errorEmail, setErrorEmail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);

  const validateEmail = (email) => {
    // Expresión regular para validar un email
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

  const onChangeEmail = (e) => {
    if (e.target.value === "") setErrorEmail(false)

    if (e.target.value !== "" && !validateEmail(e.target.value)) {
      setErrorEmail(true)
    } else if (e.target.value !== "" && validateEmail(e.target.value)) {
      setErrorEmail(false)
    }

    setEmail(e.target.value)
  }

  const successFunction = () => {
    history.push("/login-agent");;
  };

  const onSubmit = (e) => {
    e.preventDefault()
    if (email && validateEmail(email)) {
      setLoading(true)
      ForgetPasswordPetition(email)
      .then(({ data: result }) => {
        setModal(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <div className="authincation h-100 p-meddle">

      {modal && (
        <ModalUtility modal={modal} successFunction={successFunction} />
      )}

      <div className="container h-100">
        {' '}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="#">
                        <img
                          src={logo}
                          alt=""
                        />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group">
                        <label className="">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder='hello@example.com'
                          value={email}
                          onChange={onChangeEmail}
                        />
                        {errorEmail && (
                          <p className='text-danger'>Invalid email address</p>
                        )}
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {loading && <Loading />}
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
