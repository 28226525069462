import petitionGet from "./petitionGet";

const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))
const subAgencyQuery = subAgencyItem ? `sub_agency_id=${subAgencyItem.pk}` : ""

export function searchAgentsService(inputValue, callback){
  const querySubAgency = subAgencyItem ? `&${subAgencyQuery}` : ""
  const inputQuery = inputValue ? `?query=${inputValue}` : "?offset=0&limit=10"
  const completeParameter = inputQuery + querySubAgency
  
  petitionGet("agents", { parameter: completeParameter })
    .then(({ data: result }) => {
      result.result.forEach((element) => {
        element.label = element.first_name + " " + element.last_name;
        element.value = element.pk;
      });

      callback(result.result);
    })
    .catch((error) => {
      console.log(error);
    });
}

