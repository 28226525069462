import { useEffect, useState, useRef } from "react";
import petitionGet from "../../services/petitionGet";
import petitionPost from "../../services/petitionPost";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CustomTable from "../../jsx/utils/CustomTable/CustomTable";
import ModalAdd from "./ModalUtility/ModalAdd";
import ModalDelete from "./ModalUtility/ModalDelete";
import ModalFilter from "../../jsx/utils/ModalFilter";
import petitionDelete from "../../services/petitionDelete";
import petitionPatch from "../../services/petitionPatch";
import Loading from "../../jsx/utils/Loading";
import getQueryString from "../../jsx/utils/getQueryString";

export default function Carriers() {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const filterText = useRef();
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });
  const [filterModal, setFilterModal] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setDataTable] = useState(null);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [allResult, setAllResult] = useState(null);

  const frequency = [
    { label: "", value: "" },
    { label: "weekly", value: "weekly" },
    { label: "biweekly", value: "biweekly" },
    { label: "monthly", value: "monthly" },
  ];

  const [form, setForm] = useState({
    pk: null,
    name: "",
    payment_frequency: "",
    created_at: "",
    updated_at: "",
  });

  const onHidde = () => {
    console.log("hidde");
  };

  const onSaveFunction = (form) => {
    setLoadingFilter(true);
    let parameter = getQueryString(form);
    petition(parameter);
  };

  const dataFilterModal = {
    onSaveFunction: (form) => onSaveFunction(form),
    onHidde: () => onHidde(),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Carrier Created On or After",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_after",
            key: "added_after",
          },
          {
            label: "Carrier Created On or Before",
            input: "date",
            inputValue: "",
            checkbox: true,
            checkboxValue: false,
            checkboxName: "checkbox_before",
            key: "added_before",
          },
        ],
      },
    ],
  };

  const activeEditModal = (e) => {
    setForm(e);
    setInfo(e);
    setModalAdd(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const reloadData = (deleted) => {
    let parameter = "";
    setModalAdd(false);
    setModalDelete(false);
    setInfo(null);
    resetForm();

    if (deleted) {
      let paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      parameter = getQueryString(paginationTemp) + (filterText.current || "");
      petition(parameter, false, false, false, paginationTemp);
      pagination.current = paginationTemp;
    } else {
      parameter =
        getQueryString(pagination.current) + (filterText.current || "");
      petition(parameter, false, false, false, pagination.current);
    }
  };

  const petition = (
    parameter,
    noLoading,
    activeFilterTexBox,
    valueFilter,
    paginationTemp
  ) => {
    if (!noLoading) setLoading(true);
    petitionGet("carriers", { id, parameter })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element, i) => {
          element.positionTable = (paginationTemp?.offset || 0) + i + 1;
          element.created_at = element.created_at
            .substring(0, 10)
            .replaceAll("-", "/");
          element.updated_at = element.updated_at
            .substring(0, 10)
            .replaceAll("-", "/");
        });
        setDataTable({
          title: [
            // { title: "#", key: "positionTable" },
            { title: "CARRIER NAME", key: "name" },
            { title: "CREATED ON", key: "created_at" },
            { title: "UPDATED ON", key: "updated_at" },
            // {
            //   title: "NUMBER OF MONTHS FOR ADVANCE COMMISSIONS",
            //   key: "advance_pay_months",
            // },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <EditIcon />,
                handleClick: activeEditModal,
              },
              {
                name: "Delete",
                icon: <DeleteIcon />,
                handleClick: activeDeleteModal,
              },
            ],
          },
          noColumnNumber: true,
          addButton: {
            label: "Add Carrier",
            handleClick: () => {
              setModalAdd(true);
            },
          },
          itemsPerPage: (e) => activeItemsPerPage(e),
          changePage: (page) => {
            if (activeFilterTexBox) {
              changePage(page, true, valueFilter);
            } else {
              changePage(page);
            }
          },
          // showMorefilters: () => setFilterModal(true),
        });
        setLoading(false);
        setLoadingModal(false);
        setLoadingFilter(false);
        setFilterModal(false);
      })
      .catch((error) => console.log(error));
  };

  const addData = () => {
    setLoadingModal(true);
    if (info) {
      petitionPatch("carriers", {
        carrier_id: info.pk,
        data: {
          name: form.name,
          advance_pay_months: form.advance_pay_months,
        },
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      petitionPost("carriers", {
        name: form.name,
        advance_pay_months: form.advance_pay_months,
      })
        .then(({ data: result }) => {
          reloadData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFunction = () => {
    setLoadingModal(true);
    petitionDelete("carriers", info.pk)
      .then(({ data: { result } }) => {
        setModalDelete(false);
        if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
          reloadData(true);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetForm = () => {
    setForm({
      pk: null,
      name: "",
      payment_frequency: "",
      created_at: "",
      updated_at: "",
    });
  };

  const changePage = (page) => {
    let paginationTemp = {
      limit: pagination.current.limit,
      offset: page === 1 ? 0 : (page - 1) * pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp);
    petition(parameter, true, false, false, paginationTemp);
    pagination.current = paginationTemp;
  };

  const filterTextBox = (value) => {
    let paginationTemp = {
      limit: 10,
      offset: 0,
    };

    if (value !== "") {
      let parameter = `${getQueryString(paginationTemp)}&name=${value}`;

      petition(parameter, true, true, value, paginationTemp.offset);
      pagination.current = paginationTemp;

      if (value) {
        filterText.current = `&name=${value}`;
      } else {
        filterText.current = "";
      }
    } else {
      let parameter = getQueryString(paginationTemp);
      petition(parameter, true, false, false, paginationTemp.offset);
      filterText.current = "";
      pagination.current = paginationTemp;
    }
  };

  const activeItemsPerPage = (data) => {
    let paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    let parameter = getQueryString(paginationTemp) + (filterText.current || "");
    petition(parameter, false, true, false, paginationTemp);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  return (
    <>
      {/* <ModalFilter
        openModal={filterModal}
        closeModal={() => {
          setFilterModal(false);
        }}
        info={dataFilterModal}
        loading={loadingFilter}
      /> */}
      {modalAdd && (
        <ModalAdd
          openModal={modalAdd}
          modalActions={addData}
          closeModal={() => {
            resetForm();
            setModalAdd(false);
          }}
          form={form}
          setForm={setForm}
          loading={loadingModal}
          frequency={frequency}
        />
      )}

      {modalDelete && (
        <ModalDelete
          openModal={modalDelete}
          modalActions={deleteFunction}
          closeModal={() => {
            setModalDelete(false);
            resetForm();
          }}
          loading={loadingModal}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <>
          {dataTable && (
            <CustomTable
              data={dataTable}
              noClass={true}
              allResult={allResult}
              noOverflow={true}
              pagination={itemsPerPage.current}
            />
          )}
        </>
      )}
    </>
  );
}
