import React, { Fragment, useContext, useEffect, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logoBlack from "../../../images/logoBlack.png";
import petitionGet from "../../../services/petitionGet";

const NavHader = () => {
  const locStorage = JSON.parse(localStorage.getItem("userDetails"))
  const subAgencyItem = JSON.parse(localStorage.getItem("subAgencyDetails"))

  const [toggle, setToggle] = useState(false);
  const [logo, setLogo] = useState("");
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);

  const accountDetails = () => {
    petitionGet(locStorage.loginAgent ? "account-details-agent" : "account-details")
      .then(({ data: result }) => {
        if (result.result.logo && result.result.logo_mime) {
          setLogo(result.result.logo_mime + "," + result.result.logo);
        }
      })
      .catch((error) => console.log(error));
  };

  const subAgencyGeneralSetings = () => {
    petitionGet("sub-agencies", { parameter: `/${subAgencyItem.pk}` })
      .then(({ data: result }) => {
        if (result.result.logo && result.result.logo_mime) {
          setLogo(result.result.logo_mime + "," + result.result.logo);
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (subAgencyItem) {
      subAgencyGeneralSetings()
    } else {
      accountDetails()
    }
  }, []);

  return (
    <div className="nav-header">
      <Link
        to="#"
        className="brand-logo d-flex justify-content-center align-items-center"
        style={!logo ? { width: "100%", height: "100%", paddingLeft: "0.5rem" } : {}}
      >
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img
              src={logo ? logo : "https://itp360.com/img/core-img/logo.png"}
              alt=""
              srcSet=""
              style={{ width: "70px" }}
            />
            {!logo && (
              <div className="brand-title">
                <h2 style={{ marginLeft: "-16px" }} className="">
                  PayLink360
                </h2>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <img
              src={logo ? logo : logoBlack}
              alt=""
              srcSet=""
              style={{ width: "70px" }}
            />
            {!logo && (
              <div className="brand-title">
                <h2 style={{ marginLeft: "-16px" }} className="">
                  PayLink360
                </h2>
              </div>
            )}
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
